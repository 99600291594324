import React, { useEffect, useState, Suspense, useRef } from "react";
import FurnitureView from "../components/three/FurnitureView";
import HeaderComponent from "../components/HeaderComponent";
import axios from "axios";
import "../styles/OrderDetailView.sass";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux"; 


const OrderDetailView = () => {
  const { orderSeq } = useParams(); 
  const [orderDetail, setOrderDetail] = useState(null);
  const [preload, setPreload] = useState(null);
  const navigate = useNavigate();
  const area3dRef = useRef();
  const accessToken = useSelector((state) => state.userInfo.accessToken);
  const loginInfo = useSelector((state) => state.userInfo.userInfo);
  const [loginUser, setLoginUser] = useState(loginInfo);

  useEffect(() => {
    if (!loginInfo) {
      alert("로그인한 유저만 접근 가능합니다.");
      navigate("/");
    } else {
      loadOrderDetail();
    }
  }, [navigate, orderSeq]); 

  const loadOrderDetail = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/order/detail/${orderSeq}`,{
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      })
      .then((res) => {
        setOrderDetail(res.data);
        if(res.data.productType === "C"){
          setPreload(setPreloadData(res.data.moduleList));
        }
      })
      .catch((err) => {
        console.error('Error fetching order history:', err);
      });
  };

  const setPreloadData = (moduleList) => {
    if (moduleList) {
      return {
        models: moduleList.map((module) => ({
          count: 1,
          furnitureForm: 1,
          furnitureType: 1,
          image: `${process.env.REACT_APP_API_URL}/image/module/module-sample.png`,
          itemSeq: module.moduleSeq,
          itemType: 'C',
          keywordList: [2],
          modelImage: `${process.env.REACT_APP_API_URL}/image/3dmodel/${module.module.moduleModelFile}`,
          name: '샘플 모듈 2',
          position: [module.offsetX, module.offsetY, module.offsetZ],
          price: 50000,
          rotation: [module.rotateX, module.rotateY, module.rotateZ],
          selected: true,
          totalPrice: 100000,
        })),
      };
    }
  };

  const handleRequestCancelOnDelivery = () => {
    if (orderDetail.orderStatus === "배송중") {
        requestCancelOnDelivery(orderDetail.orderSeq)      
    } 
  };

  const requestCancelOnDelivery = async (orderSeq) => {
    if (window.confirm("환불 요청하시겠습니까?")) {
      try {
        const result = await axios.patch(`${process.env.REACT_APP_API_URL}/order/request-cancel-on-delivery/${orderSeq}`,
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          },
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          }
        );
        console.log("배송중환불요청 응답 => ", result);
        if (result.data.code === 0) {
          alert("환불요청되었습니다.");
          navigate("/mypage/orders");
        } else {
          console.error("환불요청 실패 =>", result);
          alert("환불요청이 실패했습니다.");
        }
      } catch (error) {
        console.error("환불요청 실패 중 에러 발생 =>", error);
        alert("환불요청 실패 중 에러가 발생했습니다.");
      }
    }
  };


    const handleButtonClick = () => {
    if (orderDetail.orderStatus === "결제완료") {
      if (window.confirm("결제를 취소하시겠습니까?")) {
      paymentCancle();
      }
    } else if (orderDetail.orderStatus === "배송중") {
      window.open('https://search.naver.com/search.naver?where=nexearch&sm=top_hty&fbm=0&ie=utf8&query=%EB%B0%B0%EC%86%A1%EC%A1%B0%ED%9A%8C', '_blank');

    } else if (orderDetail.orderStatus === "배송완료") {
      requestRefund(orderDetail.orderSeq);      
    }else if (orderDetail.orderStatus === "환불요청") {
      cancelRequestRefund(orderDetail.orderSeq);      
    }else if (orderDetail.orderStatus === "제작중"){
      requestCancelOnMake(orderDetail.orderSeq);
    }else if(orderDetail.orderStatus === "제작중환불요청") {
      cancelRequestOnMake(orderDetail.orderSeq);
    }else if(orderDetail.orderStatus === "배송중환불요청") {
      cancelRequestOnDelivery(orderDetail.orderSeq);
    }
  };

        const cancelRequestOnDelivery = async (orderSeq) => {
    if (window.confirm("환불 요청을 취소하시겠습니까?")) {
      try {
        const result = await axios.patch(`${process.env.REACT_APP_API_URL}/order/cancel-request-on-delivery/${orderSeq}`,
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          },
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          }
        );
        if (result.data.code === 0) {
          alert("환불요청 취소되었습니다.");
          navigate("/mypage/orders");
        } else {
          console.error("환불요청 취소 실패 =>", result);
          alert("환불요청 취소가 실패했습니다.");
        }
      } catch (error) {
        console.error("환불요청 취소 중 에러 발생 =>", error);
        alert("환불요청 취소 중 에러가 발생했습니다.");
      }
    }
  };

    const requestCancelOnMake = async (orderSeq) => {
    if (window.confirm("환불 요청하시겠습니까?")) {
      try {
        const result = await axios.patch(`${process.env.REACT_APP_API_URL}/order/request-cancel-on-make/${orderSeq}`,
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          },
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          }
        );
        if (result.data.code === 0) {
          alert("환불요청되었습니다.");
          navigate("/mypage/orders");
        } else {
          console.error("환불요청 실패 =>", result);
          alert("환불요청이 실패했습니다.");
        }
      } catch (error) {
        console.error("환불요청 실패 중 에러 발생 =>", error);
        alert("환불요청 실패 중 에러가 발생했습니다.");
      }
    }
  };

      const cancelRequestOnMake = async (orderSeq) => {
    if (window.confirm("환불 요청을 취소하시겠습니까?")) {
      try {
        const result = await axios.patch(`${process.env.REACT_APP_API_URL}/order/cancel-request-on-make/${orderSeq}`,
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          },
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          }
        );
        if (result.data.code === 0) {
          alert("환불요청 취소되었습니다.");
          navigate("/mypage/orders");
        } else {
          console.error("환불요청 취소 실패 =>", result);
          alert("환불요청 취소가 실패했습니다.");
        }
      } catch (error) {
        console.error("환불요청 취소 중 에러 발생 =>", error);
        alert("환불요청 취소 중 에러가 발생했습니다.");
      }
    }
  };

    const cancelRequestRefund = async (orderSeq) => {
    if (window.confirm("환불 요청을 취소하시겠습니까?")) {
      try {
        const result = await axios.patch(`${process.env.REACT_APP_API_URL}/order/request_refund_cancel/${orderSeq}`,
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          },
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          }
        );
        if (result.data.code === 0) {
          alert("환불요청 취소되었습니다.");
          navigate("/mypage/orders");
        } else {
          console.error("환불요청 취소 실패 =>", result);
          alert("환불요청 취소가 실패했습니다.");
        }
      } catch (error) {
        console.error("환불요청 취소 중 에러 발생 =>", error);
        alert("환불요청 취소 중 에러가 발생했습니다.");
      }
    }
  };

  const requestRefund = async (orderSeq) => {
    if (window.confirm("환불을 요청하시겠습니까?")) {
      try {
        const result = await axios.patch(`${process.env.REACT_APP_API_URL}/order/request_refund/${orderSeq}`,
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          },
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          }
        );

        if (result.data.code === 0) {
          alert("환불요청 되었습니다.");
          navigate("/mypage/orders");
        } else {
          console.error("환불 요청 실패 =>", result);
          alert("환불 요청이 실패했습니다.");
        }
      } catch (error) {
        console.error("환불 중 에러 발생 =>", error);
        alert("환불 처리 중 에러가 발생했습니다.");
      }
    }
  };

  const paymentCancle = async() => {
    if (orderDetail.orderStatus === "결제완료") {
      const paymentKey = orderDetail.paymentKey; 
      const encryptedSecretKey = "Basic " + btoa("test_gsk_docs_OaPz8L5KdmQXkzRz3y47BMw6:");
      const options = {
        method: 'POST',
        url: `https://api.tosspayments.com/v1/payments/${paymentKey}/cancel`,
        headers: {
          Authorization: encryptedSecretKey,
          'Content-Type': 'application/json'
        },
        data: { cancelReason: '고객 변심' }
      };

      try {
        const response = await axios.request(options);
        if (response.status === 200) {
          await axios.patch(`${process.env.REACT_APP_API_URL}/order/cancel/${orderSeq}`, 
            { 
              cancelReason: '고객변심'            
          },{
              headers: {
                'Authorization': `Bearer ${accessToken}`
              }
          }
        );
          alert("결제가 취소되었습니다.");
          navigate(`/mypage/orders`);
        }
      } catch (error) {
        console.error("결제 취소 중 에러 발생 =>", error);
        alert("결제 취소 중 에러가 발생했습니다. 구입처에 연락하세요.");
      }
    }
  }

  if (!orderDetail || !loginUser) {
    return <div>로딩 중...</div>;
  }
  return (
    <div className="order-detail-view-wrap">
      <HeaderComponent />
      {orderDetail.productType === "C" && (
        <div className="module-assemble-3d-area">
          <Suspense fallback={<div>로딩 중. . .</div>}>
            <FurnitureView ref={area3dRef} preload={preload} enableClick={false} />
          </Suspense>
        </div>
      )}
      <div className="order-item-info">
        <h1>주문 상세</h1>
        <div className="order-item-list">
          {orderDetail.productType === "C" &&
            orderDetail.moduleList.map((item, index) => (
              <div key={index} className="order-item">
                <div className="order-item-body">
                  <div
                    className="order-item-img"
                    style={{
                      backgroundImage: `url('${process.env.REACT_APP_API_URL}/image/module/${item.module.imageFile}')`,
                    }}
                  ></div>
                  <div className="order-item-detail">
                    <h2 className="order-item-title">{item.module.title}</h2>
                    <p className="order-item-price">
                      {item.module.price.toLocaleString()}원
                    </p>
                    <p className="order-item-count">수량 {item.count}개</p>
                    <div className="order-item-price-sum">
                      합계 : {(item.module.price * item.count).toLocaleString()}원
                    </div>
                  </div>
                </div>
              </div>
            ))}
          {orderDetail.productType === "P" &&
            orderDetail.productList.map((item, index) => (
              <div key={index} className="order-item">
                <div className="order-item-body">
                  <div
                    className="order-item-img"
                    style={{
                      backgroundImage: `url('${process.env.REACT_APP_API_URL}/image/product/${item.thumbnail}')`,
                    }}
                  ></div>
                  <div className="order-item-detail">
                    <h2 className="order-item-title">{item.name}</h2>
                    <p className="order-item-price">
                      개당 : {item.price.toLocaleString()}원
                    </p>
                    <p className="order-item-price">수량 : {item.count}개</p>
                    <div className="order-item-price-sum">
                      합계 : {(item.price * item.count).toLocaleString()}원
                    {orderDetail.orderStatus === "배송완료" && (
                      item.status === "N" ? (
                        <button className="review-button completed" disabled>
                          리뷰 완료
                        </button>
                      ) : (
                        <button
                          className="review-button"
                          onClick={() => navigate(`/review/${item.productSeq}/${orderDetail.orderSeq}`)}
                        >
                          리뷰 작성
                        </button>
                      )
                    )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div className="order-total-price">
          총 결제액 : {orderDetail.amountPrice?.toLocaleString()}원
          <span className="order-status" style={{ color: "red", marginLeft: "20px" }}>
            {orderDetail.orderStatus}
          </span>
        </div>
        <div className="order-info-summary">
          <div>
            <strong>주문명</strong> {orderDetail.orderName}
          </div>
          <div>
            <strong>주문번호</strong> {orderDetail.orderSeq}
          </div>
          <div>
            <strong>주문일자</strong> {orderDetail.orderDate.split("T")[0]}
          </div>
          <div>
            <strong>이름</strong> {loginUser?.name}
          </div>
          <div className="contact-info">
            <strong>연락처</strong> {loginUser?.phone}
          </div>
        </div>
        <hr />
        <div className="shipping-info">
          <div className="address-content">
            <strong>배송지 정보</strong> {`[${orderDetail.zipcode}] ${orderDetail.address} ${orderDetail.detailAddress}`}
          </div>
          <div className="contact-info">
            <strong>연락처</strong> {loginUser?.phone}
          </div>
          <div className="contact-info">
            {orderDetail.deliveryCompany && orderDetail.deliveryNumber && (
              <>
                <strong>운송장</strong> [{orderDetail.deliveryCompany}] {orderDetail.deliveryNumber}
              </>
            )}
           </div>
        </div>
        <div className="order-view-btn-area">
          { orderDetail.orderStatus !== "결제취소" && orderDetail.orderStatus !== "환불완료" && orderDetail.orderStatus !== "수동환불" && orderDetail.orderStatus !== "수동환불완료" &&orderDetail.orderStatus !== "제작중환불완료" &&orderDetail.orderStatus !== "배송중환불완료" &&orderDetail.orderStatus !== "환불불가" &&(
            <button className="order-action-btn" onClick={handleButtonClick}>
              {orderDetail.orderStatus === "결제완료" && "결제취소"}
              {orderDetail.orderStatus === "배송완료" && "환불요청"}
              {orderDetail.orderStatus === "배송중" && "배송조회"}
              {orderDetail.orderStatus === "환불요청" && "환불요청취소"}
              {orderDetail.orderStatus === "제작중" && "환불요청"}
              {orderDetail.orderStatus === "제작중환불요청" && "환불요청취소"}
              {orderDetail.orderStatus === "배송중환불요청" && "환불요청취소"}
            </button>
          )}
        </div>
        <div className="order-view-btn-area">
          { orderDetail.orderStatus === "배송중" &&(
            <button className="order-action-btn" onClick={handleRequestCancelOnDelivery}>
              {orderDetail.orderStatus === "배송중" && "환불요청"}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};


export default OrderDetailView;
