import "../styles/HeaderComponent.sass";
import {Link} from "react-router-dom";
import sait_logo from "../images/sait_logo.png";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {mainMenuSetVisible} from "../redux/actions/mainMenu.js";

const HeaderComponent = ({isMainView, theme}) => {
  const dispatch = useDispatch();
  const menuVisible = useSelector(state => state.mainMenu.visible);
  window.addEventListener("scroll", (e) => {
    if(!isMainView) return;
    if(window.scrollY >= 338 && !floatingMenu) {
      setFloatingMenu(true);
    }
    if(window.scrollY < 338 && floatingMenu) {
      setFloatingMenu(false);
    }
  })
  const [floatingMenu, setFloatingMenu] = useState(false)

  const onClickMenuBtn = () => {
    dispatch(mainMenuSetVisible(!menuVisible));
  }

  return (
    <div className="main-view-top-wrap">
      <div className={"main-view-top " + (floatingMenu ? "floating " : "") + (!isMainView?"white-theme ":"")+theme}>
        <Link className="logo" to="/">
          <img src={sait_logo} alt="sait_logo"/>
        </Link>
        <button className={"menu-btn "+ (menuVisible?"close-btn":"")} onClick={onClickMenuBtn}>
          <span className="menu-btn-line"></span>
          <span className="menu-btn-line"></span>
          <span className="menu-btn-line"></span>
        </button>
      </div>
    </div>
  );
}

export default HeaderComponent;