import "../styles/MainView.sass";

import { Link, useNavigate } from "react-router-dom";
import banner_btn_arrow from "../images/banner-btn-arrow.png";
import category_img from "../images/category-img.png";
import axios from 'axios'

import Flicking from "@egjs/react-flicking";
import "@egjs/react-flicking/dist/flicking.css";
import { useEffect, useState } from "react";
import HeaderComponent from "../components/HeaderComponent";

import { productListSetTitle, productListSetListDivision, productListSetCategorySeq } from "../redux/actions/productList.js"
import MainBannerView from "./MainBannerView.js";
import { useDispatch } from "react-redux";
import TermsModal from "./TermsModal.js"; 

const MainView = () => {
  const [popularProductList, setPopularProductList] = useState([]);
  const [bannerContent, setBannerContent] = useState(null);
  const [businessInfo, setBusinessInfo] = useState("");
  const [modalContent, setModalContent] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchBusinessInfo = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/terms/business-info`);
      setBusinessInfo(response.data);
    } catch (error) {
      console.error("Error fetching business info:", error);
    }
  };

  const fetchPrivacyPolicy = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/terms/privacy-policy`);
      setModalContent(response.data);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching privacy policy:", error);
    }
  };

  const fetchTermsOfService = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/terms/terms-of-service`);
      setModalContent(response.data);
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching terms of service:", error);
    }
  };

  const getBannerContent = async () => {
    const response = await axios.get(process.env.REACT_APP_API_URL + "/mainpage-banner");
    setBannerContent(response.data);
  }

  const getPopularProductList = async () => {
    const response = await axios.get(process.env.REACT_APP_API_URL + "/recommend/product");
    console.log("popular product list => ", response);
    setPopularProductList(response.data.list);
  }

  useEffect(() => {
    fetchBusinessInfo();
  }, []);
  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL + "/category/list/0").then(
      (res) => {
        setCategoryList(res.data);
      }
    ).catch((err) => {
      console.log(err)
    })
    document.body.style.overflow = 'unset';
    getPopularProductList();
    getBannerContent();
  }, []);
  const [categoryList, setCategoryList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onClickPopularMoreBtn = () => {
    dispatch(productListSetCategorySeq(0));
    dispatch(productListSetTitle("인기 제품"));
    dispatch(productListSetListDivision("popular"));
    navigate("/product")
  }

  const onClickCategoryBtn = (categorySeq, categoryName) => {
    dispatch(productListSetCategorySeq(categorySeq));
    dispatch(productListSetTitle(categoryName));
    dispatch(productListSetListDivision("category"));
    navigate("/categories")
  }

  const handleKakaoChatClick = () => {
    window.location.href = "http://pf.kakao.com/_BMxaAn/chat";
  };

  const PopularProductList = ({ list }) => {
    try {
      return (
        <Flicking moveType="snap"
          circular={false}
          bound={true}
          align="next">
          {
            list.map((product) => {
              return (
                <div className="item" key={product.productSeq} onClick={() => navigate(`/product/detail/${product.productSeq}`)}>
                  <div className="item-thumbnail" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}/image/product/${product.thumbnail})` }}></div>
                  <div className="item-info">
                    <h3 className="item-title">{product.name}</h3>
                    <p className="item-size">{product.width}x{product.height}x{product.depth}</p>
                    <div className="item-sep-line"></div>
                    <p className="item-price">{product.price.toLocaleString()}</p>
                    <div className="item-review-like">
                      <span className="item-reviews">
                        <span>리뷰</span>
                        <span>{product.reviewCnt}</span>
                      </span>
                      <span className="item-likes">
                        <span>{product.wishlistCnt}</span>
                        <span>★</span>
                      </span>
                    </div>
                    <div className="item-badge">
                      {product.sizeChangeable ? <span className="size-changeable">사이즈</span> : ""}
                      {product.colorChangeable ? <span className="color-changeable">컬러</span> : ""}
                      {product.textureChangeable ? <span className="texture-changeable">재질</span> : ""}
                    </div>
                  </div>
                </div>
              )
            })
          }
        </Flicking>
      )
    }
    catch (error) {
      console.log(error)
      return null;
    }
  }

  return (
    <div className="main-view-wrap">
      <HeaderComponent isMainView={true} />
      <div className="main-view-banner-area">
        {
          bannerContent && bannerContent.mainpageBannerInfo.mainpageBanner.bgType === 'video' ? <div className="video-banner">
            <video autoPlay={true} loop={true} muted={true}>
              <source src={`${process.env.REACT_APP_API_URL}/image/mainpagebanner/${bannerContent.mainpageBannerInfo.mainpageBanner.bgFile}`} type="video/mp4" />
            </video>
            <div className="main-view-video-cover">
              <div className="main-view-video-cover-inner">
                <h1>{bannerContent.mainpageBannerInfo.mainpageBanner.title}</h1>
                <p>{bannerContent.mainpageBannerInfo.mainpageBanner.subTitle}</p>
                {/*<p>내가 원하는 가구를 만들어보세요</p>*/}
                {
                  bannerContent.mainpageBannerInfo.mainpageBanner.linkType === 'link' ?
                    <div className="link-area">
                      {
                        bannerContent.mainpageBannerInfo.links.map((link) => {
                          return (
                            <Link to={link.linkUrl} className="main-view-btn" key={link.seq}>
                              <span>{link.linkTitle}</span>
                              <img src={banner_btn_arrow} alt="arrow" />
                            </Link>
                          )
                        })
                      }
                    </div> : ""
                }
              </div>
            </div>
          </div> : ""
        }
        {
          bannerContent && bannerContent.mainpageBannerInfo.mainpageBanner.bgType === 'img' ? <div className="video-banner">
            {bannerContent.mainpageBannerInfo.mainpageBanner.linkType === 'direct' ?
              <div><Link to={bannerContent.mainpageBannerInfo.mainpageBanner.directLink} className="main-view-banner-link">
                <img
                  src={`${process.env.REACT_APP_API_URL}/image/mainpagebanner/${bannerContent.mainpageBannerInfo.mainpageBanner.bgFile}`}
                  alt="mainbanner-img"
                  className="banner-img" />
              </Link></div> : <div>
                <img
                  src={`${process.env.REACT_APP_API_URL}/image/mainpagebanner/${bannerContent.mainpageBannerInfo.mainpageBanner.bgFile}`}
                  alt="mainbanner-img" className="banner-img" />
              </div>}
            <div className="main-view-video-cover-inner">
              <h1>{bannerContent.mainpageBannerInfo.mainpageBanner.title}</h1>
              <p>{bannerContent.mainpageBannerInfo.mainpageBanner.subTitle}</p>
              {
                bannerContent.mainpageBannerInfo.mainpageBanner.linkType === 'link' ?
                  <div className="link-area">
                    {
                      bannerContent.mainpageBannerInfo.links.map((link) => {
                        return (
                          <Link to={link.linkUrl} className="main-view-btn" key={link.seq}>
                            <span>{link.linkTitle}</span>
                            <img src={banner_btn_arrow} alt="arrow" />
                          </Link>
                        )
                      })
                    }
                  </div> : ""
              }
            </div>
          </div> : ""
        }
      </div>
      <div className="popular-product-area">
        <div className="popular-product-header">
          <h2>인기 제품</h2>
          <button className="more-btn" onClick={onClickPopularMoreBtn}>
            <span>더보기</span>
          </button>
        </div>
        <div className="popular-product-list">
          <div className="slider-container">
            <PopularProductList list={popularProductList} />
          </div>
        </div>
      </div>
      <div className="category-list-area">
        <h2>가구 카테고리</h2>
        <div className="category-list">
          {
            categoryList.map((category) => {
              return (
                <div className="category-btn" key={category.categorySeq} onClick={() => onClickCategoryBtn(category.categorySeq, category.categoryNameKor)}>
                  <img src={category_img} alt="category_img" />
                  <p className="category-title">{category.categoryNameKor}</p>
                </div>
              )
            })
          }
          <div className="category-btn">
            <img src={category_img} alt="category_img" />
            <p className="category-title">타임세일</p>
          </div>
        </div>
      </div>
      <div className="advertise-area">
        <MainBannerView />
      </div>
      <div class="chat-button">
        <button onClick={handleKakaoChatClick}>
          <img src="kakao1.png" alt="카카오톡 채널 채팅하기 버튼" />
          <span>카카오톡 문의하기</span>
        </button>
      </div>

      <div class="footer">
        <div class="footer__info">
          <span onClick={fetchPrivacyPolicy} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
            개인정보처리방침
          </span>
          {" | "}
          <span onClick={fetchTermsOfService} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
            이용약관
          </span>
        </div>
        <div class="footer__details">
          <pre>{businessInfo}</pre>
        </div>
      </div>
      {isModalOpen && <TermsModal content={modalContent} onClose={() => setIsModalOpen(false)} />}

    </div>
  )
}
export default MainView;