import {useEffect, useState} from "react";
import axios from "axios";
import "../../styles/module/FurnitureFormSelectComponent.sass"
const FurnitureFormSelectComponent = ({furnitureTypeSeq, onSelectFurnitureForm}) => {
  const [formList, setFormList] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/custom/forms?customFurnitureTypeSeq=${furnitureTypeSeq}`).then(
      (res) => {
        setFormList(res.data)
      }
    ).catch((err) => {
      console.log(err);
    });
  }, [furnitureTypeSeq]);
  return (
    <div className="furniture-form-select-comp-wrap">
      <h1>선반 맞춤작업을 시작합니다.<br/><b>선반의 기본형태</b>를 골라주세요.</h1>
      <p>선택한 기본형태는 첫 세팅을 위해 사용되며<br/>이후 자유롭게 수정이 가능합니다.</p>
      <div className="furniture-form-select-list">
        {
          formList.map((form) => {
            return (
              <div className={"furniture-form"} onClick={() => onSelectFurnitureForm(form)}>
                <div className="form-thumbnail" style={{backgroundImage:`url('${process.env.REACT_APP_API_URL}/image/module/${form.imageFile}')`}}></div>
                <div className="text">
                  <h2>{form.title}</h2>
                  <p>{form.description}</p>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}
export default FurnitureFormSelectComponent;