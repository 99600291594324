import "../styles/MemberJoinView.sass";
import HeaderComponent from "../components/HeaderComponent";
import check from "../images/check.png";
import sait_main_logo from "../images/sait-main-logo.png";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import ToastMessageComponent from "../components/ToastMessageComponent";
import axios from "axios";
import ModalPopupComponent from "../components/ModalPopupComponent";
const MemberJoinView = () => {
  const [step, setStep] = useState(1);
  const [memberName, setMemberName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationNumber, setVerificationNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState("");
  const [toastMsg, setToastMsg] = useState(null);
  const [toastType, setToastType] = useState("error");

  const [allAgree, setAllAgree] = useState(false);
  const [agree1, setAgree1] = useState(false);
  const [agree2, setAgree2] = useState(false);
  const [agree3, setAgree3] = useState(false);
  const [agree4, setAgree4] = useState(false);

  const [verificationTime, setVerificationTime] = useState(180);

  const [nameError, setNameError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [verificationNumberError, setVerificationNumberError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailDuplicateError, setEmailDuplicateError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [passwordCheckError, setPasswordCheckError] = useState(false);

  const [isSendVerificationNumber, setIsSendVerificationNumber] = useState(false);
  const [timeIntervalNo, setTimeIntervalNo] = useState(0);
  const [isVerificationTimeOver, setIsVerificationTimeOver] = useState(false);
  const [isVerificated, setIsVerificated] = useState(false);

  const [modalPopup, setModalPopup] = useState(false);
  const onBlurPhoneNumber = () => {
    if (!phoneNumber.match(/^01[0-9]{8,9}$/)) {
      setPhoneNumberError(true);
    }
    else {
      setPhoneNumberError(false);
    }
  }

  const timeFormat = (time) => {
    const minute = Math.floor(time / 60);
    const second = time % 60;
    return `${minute}:${second < 10 ? "0"+second : second}`;
  }

  const onClickSendVerificationNumber = async () => {
    if(memberName === "" || memberName.trim() === "") {
      setNameError(true);
      return;
    }
    else {
      setNameError(false);
    }
    if (!phoneNumber.match(/^01[0-9]{8,9}$/)) {
      setPhoneNumberError(true);
      return;
    }
    if(isSendVerificationNumber && !isVerificationTimeOver) return;

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/member/sendPhoneVerification?phoneNumber=${phoneNumber}`)
    setVerificationTime(180);
    setIsVerificationTimeOver(false);
    setIsSendVerificationNumber(true);
    let timeinterval = setInterval(() => {
      setVerificationTime(verificationTime => verificationTime - 1)
    }, 1000)
    setTimeIntervalNo(timeinterval);
    setToastMsg("인증번호가 전송되었습니다.");
    setToastType("notice")
  }
  useEffect(() => {
    if(verificationTime <= 0) {
      clearInterval(timeIntervalNo);
      setIsVerificationTimeOver(true);
      setVerificationNumberError(false);
    }
  }, [verificationTime]);
  const onCloseToast = () => {
    setToastMsg(null);
  }

  const onVerifyPhoneNumber = async () => {
    if(verificationNumber === "") {
      setVerificationNumberError(true);
      return;
    }
    else {
      setVerificationNumberError(false);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/member/verifyPhoneVerification?phoneNumber=${phoneNumber}&code=${verificationNumber}`);
      if(response.data.result === "notVerified") {
        setVerificationNumberError(true);
      }
      else {
        setIsVerificated(true);
        setIsSendVerificationNumber(false);
        setToastMsg("휴대폰 인증이 완료되었습니다.");
        setToastType("notice");
        clearInterval(timeIntervalNo);
        setTimeIntervalNo(0);
      }
    }
  }

  const onClickNextStepBtn = async () => {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/member/phone/exist?phone=${phoneNumber}`);
    if(response.data.memberSeq === undefined) {
      setModalPopup(true);
    }
    else {
      setStep(2);
    }
  }

  const onBlurEmail = async () => {
    const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const hasWhiteSpace = /\s/;
    if(hasWhiteSpace.test(email)) {
      setEmailError(true);
    }
    else if(!emailRegExp.test(email)) {
      setEmailError(true);
    }
    else if(email === "" || email.trim() === "") {
      setEmailError(true);
    }
    else {
      setEmailError(false);
    }
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/member/email/exist?email=${email}`);
    if(response.data.memberSeq === undefined) {
      setEmailDuplicateError(true);
    }
    else {
      setEmailDuplicateError(false);
    }
  }

  const onBlurPassword = () => {
    const passwordRegExp = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/;
    if(!passwordRegExp.test(password)) {
      setPasswordError(true);
      return;
    }
    else {
      setPasswordError(false);
    }
    if (password !== passwordCheck) {
      setPasswordCheckError(true);
      return;
    }
    else {
      setPasswordCheckError(false);
    }
  }

  const onBlurPasswordCheck = () => {
    if(passwordError) return;
    if(password !== passwordCheck) {
      setPasswordCheckError(true);
    }
    else {
      setPasswordCheckError(false);
    }
  }
  const onChangePasswordCheck = (e) => {
    setPasswordCheck(e.target.value)
    if(passwordError) return;
    if(password !== e.target.value) {
      setPasswordCheckError(true);
    }
    else {
      setPasswordCheckError(false);
    }
  }

  const onCheckAllAgree = (e) => {
    setAllAgree(e.target.checked);
    setAgree1(e.target.checked);
    setAgree2(e.target.checked);
    setAgree3(e.target.checked);
    setAgree4(e.target.checked);
  }
  const onChangeAgree = (e, index) => {
    if(index === 1) setAgree1(e.target.checked);
    if(index === 2) setAgree2(e.target.checked);
    if(index === 3) setAgree3(e.target.checked);
    if(index === 4) setAgree4(e.target.checked);
  }
  useEffect(() => {
    setAllAgree(agree1 && agree2 && agree3 && agree4);
  }, [agree1, agree2, agree3, agree4]);

  const onClickJoinBtn = async () => {
    if(!allAgree) {
      setToastMsg("(필수) 약관에 모두 동의해주세요.");
      setToastType("error");
      return;
    }
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/member/saitMemberJoin`, {
      email: email,
      password: password,
      name: memberName,
      phone: phoneNumber,
      accountType: "sait"
    });
    if(response.data.memberSeq === undefined) {
      setToastMsg("회원가입에 실패했습니다.");
      setToastType("error");
      return;
    }
    else {
      setStep(3);
    }
  }

  return (
    <div className="member-join-view-wrap">
      <HeaderComponent />
      {step === 1 || step === 2?<div className="step-indicator">{step}/2</div>:""}
      {step === 1?<div className="step-identitiy-verification">
        <h1>본인확인을 위해<br/>인증을 진행해 주세요</h1>
        <div className="name-input-area">
          <p>가입자명</p>
          {nameError ? <p className="name-error">가입자 명을 입력해주세요.</p> : ""}
          <input type="text" placeholder="이름 입력" disabled={isVerificated} className={nameError ? "error" : ""}
                 value={memberName} onChange={(e) => setMemberName(e.target.value)}/>
        </div>
        <div className="phone-verification-area">
          <p>휴대폰번호 입력</p>
          <div className="row">
            <div
              className={"phone-number-input " + (isVerificated ? "disabled " : " ") + (phoneNumberError ? "error " : " ")}>
              <input type="text" placeholder="숫자만 입력" disabled={isVerificated} value={phoneNumber}
                     onChange={(e) => setPhoneNumber(e.target.value)} onBlur={onBlurPhoneNumber}/>
            </div>
            <button disabled={phoneNumberError}
                    disabled={(isSendVerificationNumber && !isVerificationTimeOver) || isVerificated}
                    onClick={onClickSendVerificationNumber}>{isVerificationTimeOver ? "재전송" : "인증번호전송"}</button>
          </div>
          <div className="row">
            <div
              className={"verification-number-input " + (isVerificated ? "disabled " : " ") + (verificationNumberError || isVerificationTimeOver ? "error " : " ")}>
              <input type="text" placeholder="인증번호 입력" disabled={isVerificated} value={verificationNumber}
                     onChange={(e) => setVerificationNumber(e.target.value)}/>
              {isSendVerificationNumber ?
                <span className="verification-time">{timeFormat(verificationTime)}</span> : ""}
            </div>
            <button disabled={!isSendVerificationNumber || isVerificationTimeOver}
                    onClick={onVerifyPhoneNumber}>인증번호확인
            </button>
          </div>
          {verificationNumberError ? <p className="error-msg">인증번호를 확인해주세요</p> : ""}
          {phoneNumberError ? <p className="error-msg">휴대폰 번호를 올바르게 입력해주세요.</p> : ""}
          {isVerificationTimeOver ? <p className="error-msg">인증번호 유효시간이 지났습니다.</p> : ""}
        </div>
        <div className="next-btn-area">
          <button disabled={!isVerificated} onClick={onClickNextStepBtn}>다음으로</button>
        </div>
      </div>:""}
      {step === 2?<div className="step-account-info">
        <h1>계정정보를<br/>입력해주세요</h1>
        <div className="email-input-area">
          <p>이메일</p>
          <input type="text" placeholder="이메일을 입력해주세요" className={emailError||emailDuplicateError?"error":""} onBlur={onBlurEmail} value={email} onChange={(e) => setEmail(e.target.value)}/>
          {emailError?<p className="email-error">이메일을 올바르게 입력해주세요</p>:""}
          {emailDuplicateError?<p className="email-error duplicate">이미 등록된 이메일 입니다.</p>:""}
        </div>
        <div className="password-input-area">
          <p>비밀번호 입력</p>
          <input type="password" placeholder="영문, 숫자 조합 8자리 이상" className={passwordError||passwordCheckError?"error":""} value={password} onChange={(e) => setPassword(e.target.value)} onBlur={onBlurPassword}/>
          <input type="password" placeholder="비밀번호를 다시 입력해주세요" className={passwordCheckError?"error":""} value={passwordCheck} onChange={onChangePasswordCheck} onBlur={onBlurPasswordCheck}/>
          {passwordError?<p className="password-verification-error">영문, 숫자 조합 8자리 이상</p>:""}
          {passwordCheckError?<p className="password-verification-error not-match">비밀번호가 일치하지 않습니다.</p>:""}
        </div>
        <div className="terms-area">
          <div className="row all-agree">
            <input type="checkbox" id="all-agree" className="agree-check-box" onChange={onCheckAllAgree} checked={allAgree}/>
            <label htmlFor="all-agree" className="check-box-btn-label"
                   style={{backgroundImage: `url(${check})`}}></label>
            <label htmlFor="all-agree" className="check-box-content-label">본인 인증 서비스 약관 전체동의</label>
          </div>
          <div className="row">
            <input type="checkbox" id="agree-1" className="agree-check-box" checked={agree1} onChange={(e) => onChangeAgree(e, 1)}/>
            <label htmlFor="agree-1" className="check-box-btn-label" style={{backgroundImage: `url(${check})`}}></label>
            <label htmlFor="agree-1" className="check-box-content-label">휴대폰 본인 인출 서비스 이용약관 동의(필수)</label>
            <a href="#">전문</a>
          </div>
          <div className="row">
            <input type="checkbox" id="agree-2" className="agree-check-box" checked={agree2} onChange={(e) => onChangeAgree(e, 2)}/>
            <label htmlFor="agree-2" className="check-box-btn-label" style={{backgroundImage: `url(${check})`}}></label>
            <label htmlFor="agree-2" className="check-box-content-label">휴대폰 통신사 이용약관 동의(필수)</label>
            <a href="#">전문</a>
          </div>
          <div className="row">
            <input type="checkbox" id="agree-3" className="agree-check-box" checked={agree3} onChange={(e) => onChangeAgree(e, 3)}/>
            <label htmlFor="agree-3" className="check-box-btn-label" style={{backgroundImage: `url(${check})`}}></label>
            <label htmlFor="agree-3" className="check-box-content-label">개인정보 제공 및 이용동의(필수)</label>
            <a href="#">전문</a>
          </div>
          <div className="row">
            <input type="checkbox" id="agree-4" className="agree-check-box" checked={agree4} onChange={(e) => onChangeAgree(e, 4)}/>
            <label htmlFor="agree-4" className="check-box-btn-label" style={{backgroundImage: `url(${check})`}}></label>
            <label htmlFor="agree-4" className="check-box-content-label">고유식별정보 처리(필수)</label>
            <a href="#">전문</a>
          </div>
          <div className="join-btn-area">
            <button onClick={onClickJoinBtn}>회원가입 완료</button>
          </div>
        </div>
      </div>:""}

      {step===3?<div className="step-complete">
        <img src={sait_main_logo} alt="sait_main_logo"/>
        <h1>회원가입이 완료되었습니다.</h1>
        <p>SAIT 회원가입이 완료되었습니다</p>
        <p>로그인 후 서비스를 이용해주세요</p>
        <div className="link-area">
          <Link to="/login" className="step-complete-link">로그인하기</Link>
          <Link to="/find/email" className="step-complete-link">이메일 찾기</Link>
        </div>
      </div>:""}
      {toastMsg !== null?<ToastMessageComponent closeToast={onCloseToast} message={toastMsg} time={3000} type={toastType}/>:""}
      {modalPopup?<ModalPopupComponent
        title="이미 가입한 내역이 있습니다"
        contents={["이미 가입한 내역이 있습니다.", "로그인 후 사용해주세요."]}
        links={[{title: "로그인하기", link: "/login"}]}
        onClickClose={() => {
          setModalPopup(false)
        }}
      />:""
      }
    </div>
  );
}

export default MemberJoinView;
