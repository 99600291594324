import close_btn from "../images/close-btn.png";
import "../styles/ModalPopupComponent.sass"
import {Link} from "react-router-dom";
const ModalPopupComponent = ({title, contents, links, onClickClose}) => {
  return (
    <div className="modal-popup-wrap">
      <div className="modal-popup">
        <button className="modal-close" onClick={onClickClose}>
          <img src={close_btn} alt="close_btn"/>
        </button>
        <div className="modal-content">
          <h1 className="modal-title">{title}</h1>
          {
            contents.map((content, idx) => {
              return <p key={idx} className="modal-text">{content}</p>
            })
          }
        </div>
        {
          links.map((link, idx) => {
            return <Link to={link.link} key={idx} className={"modal-btn "+link.className}>{link.title}</Link>
          })
        }
      </div>
    </div>
  );
}
export default ModalPopupComponent;