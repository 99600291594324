import "../../styles/module/ModuleGuideComponent.sass"
import module_guide_1 from "../../images/module-guide-1.png";
import module_guide_2 from "../../images/module-guide-2.png";
import module_guide_o from "../../images/module-guide-o.png";
import module_guide_x from "../../images/module-guide-x.png";

import { useState} from "react";
const ModuleGuideComponent = ({onModuleGuideCompleted}) => {
  const [guideStep] = useState(0);
  const onNextStep = () => {
    // if(guideStep === 1) {
      onModuleGuideCompleted();
      // return;
    // }
    // setGuideStep(guideStep + 1);
  }
  return (
    <div className="module-guide-comp-wrap">
      {guideStep === 0?<div className="module-guide module-guide-step-1">
        <h1>안정적인 구조로 배치해주세요</h1>
        <p>자유로운 배치는 가능하지만<br/>
          불안정한 구조는 설치 후 사용시 위험할 수 있으므로<br/>
          안정적인 구조의 설치를 권장합니다.
        </p>
        <div className="guide-area step-1">
          <div className="guide">
            <img src={module_guide_x} alt="x"/>
            <h2>불안정한 구조</h2>
            <img src={module_guide_1} alt="module_guide_1"/>
          </div>
          <div className="guide">
            <img src={module_guide_o} alt="o"/>
            <h2>안정적인 구조</h2>
            <img src={module_guide_2} alt="module_guide_2"/>
          </div>
        </div>
        <button className="next-btn" onClick={onNextStep}>다음으로</button>
      </div>:""}
    </div>
  );
}

export default ModuleGuideComponent;