import axios from 'axios';
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ModalPopupComponent from "../components/ModalPopupComponent";
import { useDispatch} from 'react-redux';
import { userAccessTokenSet, userInfoSet } from "../redux/actions/userInfo";

const GoogleAuthView = () => {
  const navigate = useNavigate();
  const [modalPopup, setModalPopup] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if(code !== null) {
      googleLogin(code);
    }
  }, []);

  const googleLogin = async (code) => {
    try {
      const response = await axios.post('https://oauth2.googleapis.com/token', {
        code: code,
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        client_secret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
        redirect_uri: process.env.REACT_APP_GOOGLE_REDIRECT_URI,
        grant_type: 'authorization_code'
      })
      const {id_token} = response.data;
      const authResponse = await axios.post(process.env.REACT_APP_API_URL+"/member/google-auth?idToken="+id_token);
      if(authResponse.data.result === "success") {
        try{
          sessionStorage.removeItem("socialInfo");
          dispatch(userAccessTokenSet(authResponse.data.accessToken));
          dispatch(userInfoSet(authResponse.data.loginMember))
          const wishlistResponse = await axios.get(`${process.env.REACT_APP_API_URL}/wishlist/product/list/seq`, {
          headers : {
            'Authorization': `Bearer ${authResponse.data.accessToken}`
          }
        });
      const moduleWishlistSeqs = await axios.get(`${process.env.REACT_APP_API_URL}/wishlist/module/list/seq`, {
        headers : {
          'Authorization' : `Bearer ${authResponse.data.accessToken}`
        }
        });
          localStorage.setItem('product_wishlist', JSON.stringify([...new Set(wishlistResponse.data)]));
          localStorage.setItem('module_wishlist', JSON.stringify(moduleWishlistSeqs.data));
          navigate('/');
        }
        catch (err) {
          alert('위시리스트 데이터를 불러오는 도중 에러가 발생했습니다.')
          console.error(err)
        }
      }
      else {
        const loginData = {
          socialToken: authResponse.data.accessToken,
          socialType: "google"
        }
        sessionStorage.setItem("socialInfo", JSON.stringify(loginData));
        setModalPopup(true);
      }
    }
    catch(e) {
      alert("구글 로그인 중 오류가 발생했습니다.");
      console.error(e);
    }
  }

  return (
    <div>
      <div>
        {modalPopup ? <ModalPopupComponent
          title="가입하지 않은 사용자입니다"
          contents={["가입하지 않은 사용자입니다", "회원가입 후 이용해주세요"]}
          links={[{title: "회원가입하기", link: "/sns-join"}]}
          onClickClose={() => {
            setModalPopup(false);
            navigate('/login');
          }}
        /> : ""
        }
      </div>
    </div>
  );
}
export default GoogleAuthView;