import HeaderComponent from "../components/HeaderComponent";
import "../styles/MemberInfoView.sass";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import ToastMessageComponent from "../components/ToastMessageComponent";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import ModalPopupComponent from "../components/ModalPopupComponent";
import PasswordChangeComponent from "../components/PasswordChangeComponent";
import {userInfoSet } from "../redux/actions/userInfo";
import { store } from "../store";



const MemberInfoView = () => {
  const navigate = useNavigate();
  const [loginUser, setLoginUser] = useState({});
  const [loginUserError, setLoginUserError] = useState(false);

  const [emailError, setEmailError] = useState(false);
  const [emailExists, setEmailExists] = useState(false);
  const [nameError, setNameError] = useState(false);

  const [toastMsg, setToastMsg] = useState(null);
  const [toastType, setToastType] = useState("info");

  const [openPasswordChange, setOpenPasswordChange] = useState(false);

  const accessToken = useSelector((state) => state.userInfo.accessToken);
  const loginInfo = useSelector((state) => state.userInfo.userInfo);

  const dispatch = useDispatch();
  useEffect(() => {
    const user = loginInfo;
    if(user !== null && user !== undefined && user !== '') {
      setLoginUser(user);
    }
    else {
      setLoginUserError(true);
    }
  }, []);
  const phoneFormatter = (phone) => {
    if(phone === undefined || phone === null || phone === '') return
    if(phone.length === 11)
      return phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    else
      return phone;
  }

  const onChangeUserInfo = (e, field) => {
    const newUser = {...loginUser};
    newUser[field] = e.target.value;
    setLoginUser(newUser);
  }

  useEffect(() => {
    setEmailExists(false);
    if(loginUser.name === undefined || loginUser.name === null || loginUser.name === '' || loginUser.name.trim() === '') {
      setNameError(true);
    }
    else {
      setNameError(false);
    }

    const emailReg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if(loginUser.email === undefined || loginUser.email === null || !emailReg.test(loginUser.email) || loginUser.email.trim() === ''){
      setEmailError(true);
    }
    else {
      setEmailError(false);
    }
  }, [loginUser]);

  const onClickChangeAccept = async () => {
    const emailCheckResponse = await axios.get(`${process.env.REACT_APP_API_URL}/member/email/exist/notMe?email=${loginUser.email}&memberSeq=${loginUser.memberSeq}`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });
    if(emailCheckResponse.data.memberSeq === undefined) {
      setEmailExists(true);
      return;
    }
    else {
      setEmailExists(false);
    }
    const memberUpdateResponse = await axios.patch(`${process.env.REACT_APP_API_URL}/member/update`, loginUser, 
      {
      headers : {
        'Authorization': `Bearer ${accessToken}`
      }
    });
    if(memberUpdateResponse.data.memberSeq !== undefined) {
      setToastMsg("회원 정보가 수정되었습니다");
      setToastType("success");
      dispatch(userInfoSet(memberUpdateResponse.data.member))
    }
    else {
      setToastMsg("회원 정보 수정에 실패했습니다");
      setToastType("error");
    }
  }

  return (
    <div className="member-info-view-wrap">
      <HeaderComponent />
      {!loginUserError?<div className="member-info-view">
        <h1>회원 정보</h1>
        <div className="member-info-row">
          <p>이름</p>
          <input type="text" className={(nameError ? "error" : "")} value={loginUser.name}
                 onChange={(e) => onChangeUserInfo(e, 'name')}/>
          {nameError ? <p className="name-error">이름을 입력해주세요</p> : ""}
        </div>
        <div className="member-info-row">
          <p>휴대폰번호</p>
          <input type="text" disabled={true} value={phoneFormatter(loginUser.phone)}/>
        </div>
        <div className="member-info-row">
          <p>이메일</p>
          <input type="text" value={loginUser.email} className={(emailError || emailExists ? "error" : "")}
                 onChange={(e) => onChangeUserInfo(e, 'email')}/>
          {emailError ? <p className="email-error">이메일을 올바르게 입력해주세요</p> : ""}
          {emailExists ? <p className="email-error">이미 등록된 이메일입니다</p> : ""}
        </div>
        {loginUser.accountType === 'sait' ?
          <button className="change-password-btn" onClick={() => setOpenPasswordChange(true)}>비밀번호 변경</button> : ""}
        <button className="member-info-change-accept" onClick={onClickChangeAccept}>내용 수정</button>
      </div>:""}
      {loginUserError?<ModalPopupComponent title="로그인이 필요합니다." contents={["로그인이 필요한 서비스입니다."]} links={[{title: "로그인하기", link: "/login"}]} onClickClose={() => {navigate("/")}}/>:""}
      {toastMsg?<ToastMessageComponent message={toastMsg} closeToast={() => setToastMsg(null)} time={3000} type={toastType}/>:""}
      <PasswordChangeComponent open={openPasswordChange} onBack={() => setOpenPasswordChange(false)}/>
    </div>
  );
}
export default MemberInfoView;