import React from 'react';

const TermsModal = ({ content, onClose }) => {
    return (
        <div className="modal">
            <div className="modal-content">
                <button className="close" onClick={onClose}>X</button> {/* 닫기 버튼 */}
                <div className="modal-body"> {/* 스크롤 가능한 영역 */}
                    <pre>{content}</pre>
                </div>
            </div>
        </div>
    );
};

export default TermsModal;
