import * as THREE from "three";
import { useFBX, useTexture } from "@react-three/drei";
import { proxy, useSnapshot } from "valtio";
import { useLoader } from "@react-three/fiber";
import { v4 as uuidv4 } from "uuid";

export class Gear {
  id;
  moduleId;
  name;
  path;
  unitPosition;
  position;
  rotation;
  unit;
  centerProps;
  direction;
  initParams;
  Unselect;
  SetColor;
  changeTexture;
  price;
  image;
  furnitureType;
  furnitureForm;
  keywordList;
  
  constructor(
    id,
    moduleId,
    name,
    path,
    unitPosition,
    position,
    rotation,
    unit,
    centerProps,
    direction,
    initParams,
    price,
    image,
    furnitureType,
    furnitureForm,
    keywordList
  ) {
    this.id = id;
    this.moduleId = moduleId;
    this.name = name;
    this.path = path;
    this.unitPosition = unitPosition;
    this.position = position;
    this.rotation = rotation;
    this.unit = unit;
    this.centerProps = centerProps;
    this.direction = direction;
    this.initParams = initParams;
    this.Unselect = null;
    this.SetColor = null;
    this.changeTexture = null;
    this.price = price;
    this.image = image;
    this.furnitureType = furnitureType;
    this.furnitureForm = furnitureForm;
    this.keywordList = keywordList
  }
  updatePositionByUnit() {
    this.position = getPositionFromUnit(this);
  }
}

const furniture_default = [
  {
    name: "300450",
    path: "/three/Box_300450_Max.glb",
    unitPosition: new THREE.Vector3(-1, 0, 0),
  },
  {
    name: "300",
    path: "/three/Box_300_Max06.glb",
    unitPosition: new THREE.Vector3(-1, 2, 0),
  },
  {
    name: "150300",
    path: "/three/Box_150300_Max.glb",
    unitPosition: new THREE.Vector3(-1, 4, 0),
  },
  {
    name: "150",
    path: "/three/Box_150_Max.glb",
    unitPosition: new THREE.Vector3(-1, 5, 0),
  },
];
export const texture_default = [
  "/three/plywood_diff_2k.jpg",
  "/three/0.jpg",
  "/three/1.jpg",
  "/three/2.jpg",
  "/three/3.jpg",
  "/three/6.jpg",
  "/three/7.jpg",
];
furniture_default.forEach(({ path }) => {
  useFBX.preload(path);
});
texture_default.forEach((path) => {
  useTexture.preload(path);
});

const colorOfMaterials = proxy({});
export const store = proxy({
  places: new Set(),
  unitScale: new THREE.Vector3(1.5, 1.5, 1.5),
  positionUpdated: null,
  current: null,
  colorOfMaterials: colorOfMaterials,
});

function setPreload(preload) {
  if (preload.models && preload.models.length > 0) {
    store.places = new Set();
    preload.models.forEach((data, i) => {
      const position = new THREE.Vector3().fromArray(data.position);
      const rotation = new THREE.Euler().fromArray(data.rotation);
      add(uuidv4(), data.itemSeq, data.name, data.modelImage, {unitPosition: position,rotation: rotation,}, data.price, data.image, data.furnitureType, data.furnitureForm, data.keywordList);
    });
  }
}

let lastTimeStamp = 0;

function add(id, moduleId, name, path, initParams, price, image, furnitureType, furnitureForm, keywordList) {
  const gear = new Gear(
    id,
    moduleId,
    name,
    path,
    proxy(new THREE.Vector3()),
    new THREE.Vector3(),
    new THREE.Euler(),
    new THREE.Vector3(2, 2, 2),
    null,
    new THREE.Vector3(0, 0, 1),
    initParams,
    price,
    image,    
    furnitureType,
    furnitureForm,
    keywordList
  );
  store.places = new Set([...store.places, gear]);
}

function setGearCB(gear, setColor, unselect, changeTexture) {
  gear.SetColor = setColor;
  gear.Unselect = unselect;
  gear.changeTexture = changeTexture;
}

export const actions = {
  positionUpdated(cb) {
    store.positionUpdated = cb;
  },
  addPlace(id, moduleId, name, file, initParams, price, image, furnitureType, furnitureForm, keywordList) {
    add(id, moduleId, name, file, initParams, price, image, furnitureType, furnitureForm, keywordList);
  },
  remove(file) {},
  setRotation(gear, rotation) {
    gear.rotation = rotation;
  },
  setCenterProps(gear, props) {
    gear.centerProps = props;
  },
  setColor(matUUID, color) {
    store.places.forEach((gear) => {
      gear.SetColor(matUUID, color);
    });
  },
  deletePlace(gear) {
    store.current = null;
    store.places.delete(gear);
  },
  resetPlace(){
    store.current = null;
    store.places.clear();
  },
  changeTexture(path) {
    store.places.forEach((gear) => {
      if (gear.id === store.current.gearId) {
        const index = texture_default.indexOf(path);
        gear.changeTexture(index);
      }
    });
  },
  clearSelect(timeStamp, gearId) {
    if (timeStamp) {
      if (lastTimeStamp + 80 > timeStamp) return;
      lastTimeStamp = timeStamp;
    }
    if (!gearId) store.current = null;
    store.places.forEach((gear) => {
      if (gear.id !== gearId) gear.Unselect?.();
    });
  },
  setCurrent(gearId, uuid, name) {
    if (gearId) store.current = { uuid, name, gearId };
    else store.current = null;
  },
  setColorItem(uuid, color) {
    store.colorOfMaterials[uuid] = color;
  },
};

function isCollision(gear, unitPosition) {
  for (const place of store.places) {
    if (place.id !== gear.id) {
      if (
        Math.abs(
          place.unitPosition.x +
            place.unit.x / 2 -
            (unitPosition.x + gear.unit.x / 2)
        ) <
          place.unit.x / 2 + gear.unit.x / 2 &&
        Math.abs(
          place.unitPosition.y +
            place.unit.y / 2 -
            (unitPosition.y + gear.unit.y / 2)
        ) <
          place.unit.y / 2 + gear.unit.y / 2
      )
        return true;
    }
  }
}
function getPivotPosition(gear, position) {
  const cX = (store.unitScale.x * gear.unit.x) / 2;
  const cY = (store.unitScale.y * gear.unit.y) / 2;
  const cZ = 0;
  const x = position.x + cX;
  const y = position.y + cY;
  const z = position.z + cZ;
  return new THREE.Vector3(x, y, z);
}
function getCenterPosition(gear, position) {
  const cX = -(store.unitScale.x * gear.unit.x) / 2;
  const cY = -(store.unitScale.y * gear.unit.y) / 2;
  const cZ = 0;
  const x = position.x + cX;
  const y = position.y + cY;
  const z = position.z + cZ;
  return new THREE.Vector3(x, y, z);
}
function getUnitPosition(gear, position) {
  const cX = 0;
  const cY = 0;
  const cZ = 0;
  const x = Math.round((position.x + cX) / store.unitScale.x);
  const y = Math.round((position.y + cY) / store.unitScale.y);
  const z = Math.round((position.z + cZ) / store.unitScale.z);
  return new THREE.Vector3(x, y, z);
}
function getPositionFromUnit(gear) {
  const cX = 0;
  const cY = 0;
  const cZ = 0;
  const x = gear.unitPosition.x * store.unitScale.x + cX;
  const y = gear.unitPosition.y * store.unitScale.y + cY;
  const z = gear.unitPosition.z * store.unitScale.z + cZ;
  return new THREE.Vector3(x, y, z);
}
function getGridPosition(gear, position) {
  const [left, right, top, bottom] = [-6, 6, 10, 0];
  const x = Math.max(left  , Math.min(right - gear.unit.x, Math.round(position.x / store.unitScale.x))) * store.unitScale.x; 
  const y = Math.max(bottom, Math.min(top   - gear.unit.y, Math.round(position.y / store.unitScale.y))) * store.unitScale.y; 
  const z = (position.z / store.unitScale.z) * store.unitScale.z;
  return new THREE.Vector3(x, y, z);
}
function GetHeightOfGears() {
  let y = 0;
  store.places.forEach((gear) => {
    const tmpY = gear.unitPosition.y + gear.unit.y;
    if (y < tmpY) y = tmpY;
  });
  if (y > 12) y = 12;
  return y;
}
function furnitureDataToArray(arr) {
  store.places.forEach((gear) => {
    const data = {
      itemSeq: gear.moduleId,
      itemType : "C",
      count : 1,
      image : gear.image,      
      modelImage: gear.path,
      name: gear.name,
      price : gear.price,
      furnitureType : gear.furnitureType,
      furnitureForm : gear.furnitureForm,
      keywordList : gear.keywordList,
      position: [gear.unitPosition.x, gear.unitPosition.y, gear.unitPosition.z],
      rotation: [gear.rotation.x, gear.rotation.y, gear.rotation.z],
    };
    arr.push(data);
  });
}

export function usePlace() {
  const snap = useSnapshot(store);
  const textures = useLoader(THREE.TextureLoader, texture_default);

  return {
    places: Array.from(snap.places),
    gearCount: snap.places.size,
    setPreload: setPreload,
    addPlace: actions.addPlace,
    setGearCB: setGearCB,
    clearSelect: actions.clearSelect,
    unitScale: snap.unitScale,
    isCollision: isCollision,
    getPivotPosition: getPivotPosition,
    getCenterPosition: getCenterPosition,
    setGearCenterProps: actions.setCenterProps,
    setGearColor: actions.setColor,
    deletePlace: actions.deletePlace,
    setCurrent: actions.setCurrent,
    setColorItem: actions.setColorItem,
    current: snap.current,
    colorItems: snap.colorOfMaterials,
    getUnitPosition: getUnitPosition,
    getGridPosition: getGridPosition,
    changeTexture: actions.changeTexture,
    texture_list: texture_default,
    textures: textures,
    GetHeightOfGears: GetHeightOfGears,
    furnitureDataToArray: furnitureDataToArray,
    resetPlace : actions.resetPlace
  };
}
