import "../styles/ProductOptionPopup.sass";
import chevron from "../images/chevron-right.svg";
import minus_btn from "../images/minus-btn.png";
import plus_btn from "../images/plus-btn.png";
import close_btn from "../images/close-btn.png";

import axios from "axios";

import { useEffect, useState } from "react";
import ToastMessageComponent from "./ToastMessageComponent";
import { useNavigate } from "react-router-dom";
//구매/장바구니 구분 플래그
const ProductOptionPopup = ({
  target,
  image,
  productName,
  productPrice,
  productSeq,
  sizeChangeable,
  colorChangeable,
  textureChangeable,
  open,
  onClosePopup,
  onClickAddCart,
}) => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  const [isOpenSizeChange, setIsOpenSizeChange] = useState(false);
  const [isOpenColorChange, setIsOpenColorChange] = useState(false);
  const [isOpenTextureChange, setIsOpenTextureChange] = useState(false);

  const [selectedSize, setSelectedSize] = useState(null);
  const [detailSize, setDetailSize] = useState(0);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedTexture, setSelectedTexture] = useState(null);

  const [sizeList, setSizeList] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [textureList, setTextureList] = useState([]);

  const [totalPrice, setTotalPrice] = useState(0);

  const [sizeSelectTitle, setSizeSelectTitle] = useState("사이즈 선택");
  const [colorSelectTitle, setColorSelectTitle] = useState("색상 선택");
  const [textureSelectTitle, setTextureSelectTitle] = useState("재질 선택");

  const [quantity, setQuantity] = useState(1);

  const [toastMsg, setToastMsg] = useState(null);
  const [toastType, setToastType] = useState("error");

  const navigate = useNavigate();
  useEffect(() => {
    //해당 상품의 옵션정보들을 조회.
    axios
      .get(process.env.REACT_APP_API_URL + "/product/options/" + productSeq)
      .then((response) => {
        setSizeList(response.data.productSizeList);
        setColorList(response.data.productColorList);
        setTextureList(response.data.productTextureList);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [productSeq]);

  const resetContent = () => {
    setIsOpenSizeChange(false);
    setIsOpenColorChange(false);
    setIsOpenTextureChange(false);
    setDetailSize(0);
    setSelectedSize(null);
    setSelectedColor(null);
    setSelectedTexture(null);
  };
  const onClickCloseBtn = () => {
    resetContent();
    onClosePopup();
  };

  const onClickSizeSelectBtn = () => {
    setIsOpenColorChange(false);
    setIsOpenTextureChange(false);
    setIsOpenSizeChange(!isOpenSizeChange);
  };
  const onClickColorSelectBtn = () => {
    setIsOpenSizeChange(false);
    setIsOpenColorChange(!isOpenColorChange);
    setIsOpenTextureChange(false);
  };
  const onClickTextureSelectBtn = () => {
    setIsOpenSizeChange(false);
    setIsOpenColorChange(false);
    setIsOpenTextureChange(!isOpenTextureChange);
  };

  const onClickSizeOption = (size) => {
    setSelectedSize(size);
    setSizeSelectTitle(size.sizeMin + "~" + size.sizeMax + "cm");
    setIsOpenSizeChange(false);
  };
  const onClickColorOption = (color) => {
    setSelectedColor(color);
    setColorSelectTitle(color.color);
    setIsOpenColorChange(false);
  };
  const onClickTextureOption = (texture) => {
    setSelectedTexture(texture);
    setTextureSelectTitle(texture.texture);
    setIsOpenTextureChange(false);
  };

  const onClickQuantityMinus = () => {
    if (quantity <= 1) return;
    setQuantity(quantity - 1);
  };
  const onClickQuantityPlus = () => {
    setQuantity(quantity + 1);
  };
  const onChangeQuantity = (e) => {
    if (quantity <= 1) {
      setQuantity(1);
    } else {
      setQuantity(Number(e.target.value));
    }
  };
  const onChangeDetailSize = (e) => {
    if (selectedSize.sizeMin > Number(e.target.value)) {
      setDetailSize(selectedSize.sizeMin);
    } else if (Number(e.target.value) > selectedSize.sizeMax) {
      setDetailSize(selectedSize.sizeMax);
    } else {
      setDetailSize(Number(e.target.value));
    }
  };
  useEffect(() => {
    let price = 0;
    if (productPrice !== undefined && productPrice !== null)
      price += productPrice;
    if (selectedSize !== null) price += selectedSize.extraCost;
    if (selectedColor !== null) price += selectedColor.extraCost;
    if (selectedTexture !== null) price += selectedTexture.extraCost;
    setTotalPrice(price * quantity);
  }, [selectedSize, selectedColor, selectedTexture, quantity]);

  const onCloseToast = () => {
    setToastMsg(null);
  };

  const inputValidation = () => {
    if (sizeChangeable && selectedSize === null) {
      setToastMsg("사이즈를 선택해주세요.");
      setToastType("error");
      return false;
    } else if (
      detailSize < selectedSize.sizeMin ||
      detailSize > selectedSize.sizeMax
    ) {
      setToastMsg("세부 사이즈를 확인해주세요.");
      setToastType("error");
      return false;
    }
    if (colorChangeable && selectedColor === null) {
      setToastMsg("색상을 선택해주세요.");
      setToastType("error");
      return false;
    }
    if (textureChangeable && selectedTexture === null) {
      setToastMsg("재질을 선택해주세요.");
      setToastType("error");
      return false;
    }
    return true;
  };

  //구매 화면으로 전환. 구매에서는 product, module을 아우르는 item을 쓴다.
  const onClickPurchaseBtn = () => {
    document.body.style.overflow = 'unset';
    if (!inputValidation()) return;
    const purchaseItem = {};
    purchaseItem.itemSeq = productSeq;
    purchaseItem.itemType = "P";
    purchaseItem.count = quantity;
    purchaseItem.image = image;
    purchaseItem.name = productName;

    if (sizeChangeable) {
      purchaseItem.changedSize = detailSize;
    }
    if (colorChangeable) purchaseItem.color = selectedColor.color;
    if (textureChangeable) purchaseItem.texture = selectedTexture.texture;
    purchaseItem.price = totalPrice / quantity;
    purchaseItem.totalPrice = totalPrice;

    /**
     * 1. 구매할 완제품, 모듈 정보를 담는다.
     * purchageItem은 완제품 구매인 경우 완제품. 맞춤가구 구매인 경우 모듈.
     * purchaseItem = {
     * itemSeq = 완제품/모듈 인덱스
     * itemType = "P" / "C"  주문 목록에 디스플레이 할때 필요.
     * count = 수량
     * image = image 주소. 커스텀 가구일떈 3D 모델 파일명 넣으면 되겠지? -> 썸넬도 필요하다
     * name = 제품명/모듈명
     * changedSize, color, texture = 제품 구매에만 있는것.
     * price = 제품/모듈 단일 가격
     * totalPrice = 제품/모듈의 단일 가격 * 수량
     * }
     * 이를 배열에 담아 넘긴다.
     */
 
    const purchaseItemList = [purchaseItem];

    resetContent();
    navigate("/order", { state: { type : "P", purchaseItemList : purchaseItemList } });
  };
  //카트 데이터로 저장.
  const onClickCartBtn = () => {
    document.body.style.overflow = 'unset';
    if (!inputValidation()) return;

    const cartItem = {};
    cartItem.productNo = productSeq;
    cartItem.productType = "P";
    cartItem.count = quantity;

    if (sizeChangeable) {
      cartItem.changedSize = detailSize;
    }
    if (colorChangeable) cartItem.color = selectedColor.color;
    if (textureChangeable) cartItem.texture = selectedTexture.texture;
    cartItem.price = totalPrice / quantity;

    onClickAddCart(cartItem);
    resetContent();
  };

  return (
    <div className={"product-option-popup-wrap " + (open ? "open" : "")}>
      <div className="product-option-popup">
        <button
          className="product-option-popup-close-btn"
          onClick={onClickCloseBtn}
        >
          <img src={close_btn} alt="close" />
        </button>
        <div className="option-select-area">
          {sizeChangeable ? (
            <div className="product-size-change">
              <h2>원하는 사이즈를 선택해주세요</h2>
              <div
                className={
                  "size-change-btn " + (isOpenSizeChange ? "open" : "")
                }
                onClick={onClickSizeSelectBtn}
              >
                <span>{sizeSelectTitle}</span>
                <img src={chevron} alt="chevron" />
              </div>
              <div
                className={
                  "size-option-list " + (isOpenSizeChange ? "open" : "")
                }
              >
                {sizeList.map((size, index) => {
                  return (
                    <div key={index}
                      className="size-option"
                      onClick={() => {
                        onClickSizeOption(size);
                      }}
                    >
                      <span>
                        {size.sizeMin}~{size.sizeMax}cm
                      </span>
                      <span>
                        {size.extraCost >= 0 ? "+" : ""}
                        {size.extraCost.toLocaleString()}원
                      </span>
                    </div>
                  );
                })}
              </div>
              {selectedSize !== null && sizeChangeable ? (
                <div className="detail-size">
                  <h3>세부사이즈</h3>
                  <div className="detail-size-input">
                    <input
                      type="number"
                      placeholder="사이즈 입력"
                      value={detailSize}
                      onBlur={onChangeDetailSize}
                      onChange={(e) => setDetailSize(e.target.value)}
                    />
                    <span>cm</span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          {colorChangeable ? (
            <div className="product-color-change">
              <h2>원하는 색상을 선택해주세요</h2>
              <div
                className={
                  "color-change-btn " + (isOpenColorChange ? "open" : "")
                }
                onClick={onClickColorSelectBtn}
              >
                <span>{colorSelectTitle}</span>
                <img src={chevron} alt="chevron" />
              </div>
              <div
                className={
                  "color-option-list " + (isOpenColorChange ? "open" : "")
                }
              >
                {colorList.map((color, index) => {
                  return (
                    <div
                      className="color-option"
                      onClick={() => {
                        onClickColorOption(color);
                      }}
                    >
                      <span>{color.color}</span>
                      <span>
                        {color.extraCost >= 0 ? "+" : ""}
                        {color.extraCost.toLocaleString()}원
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            ""
          )}
          {textureChangeable ? (
            <div className="product-texture-change">
              <h2>원하는 재질을 선택해주세요</h2>
              <div
                className={
                  "texture-change-btn " + (isOpenTextureChange ? "open" : "")
                }
                onClick={onClickTextureSelectBtn}
              >
                <span>{textureSelectTitle}</span>
                <img src={chevron} alt="chevron" />
              </div>
              <div
                className={
                  "texture-option-list " + (isOpenTextureChange ? "open" : "")
                }
              >
                {textureList.map((texture, index) => {
                  return (
                    <div
                      className="texture-option"
                      onClick={() => {
                        onClickTextureOption(texture);
                      }}
                    >
                      <span>{texture.texture}</span>
                      <span>
                        {texture.extraCost >= 0 ? "+" : ""}
                        {texture.extraCost.toLocaleString()}원
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="quantity-select">
            <span className="quantity-title">수량</span>
            <span className="quantity-input-area">
              <button
                className="quantity-minus"
                style={{ backgroundImage: `url(${minus_btn})` }}
                onClick={onClickQuantityMinus}
              ></button>
              <input
                className="quantity"
                type="number"
                value={quantity}
                onChange={onChangeQuantity}
              />
              <button
                className="quantity-plus"
                style={{ backgroundImage: `url(${plus_btn})` }}
                onClick={onClickQuantityPlus}
              ></button>
            </span>
          </div>
        </div>
        <div className="total-price">
          <span>결제금액</span>
          <span>{totalPrice.toLocaleString()}원</span>
        </div>
        {target === "purchase" ? (
          <button className="purchase-btn" onClick={onClickPurchaseBtn}>
            구매하기
          </button>
        ) : (
          <button className="cart-btn" onClick={onClickCartBtn}>
            장바구니
          </button>
        )}
      </div>
      {toastMsg ? (
        <ToastMessageComponent
          message={toastMsg}
          type={toastType}
          closeToast={onCloseToast}
          time={3000}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default ProductOptionPopup;
