import "../styles/EmailFindComponent.sass";
import mail_icon from "../images/mail-icon.png";
import copy_to_clipboard from "../images/copy-to-clipboard.png";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import ToastMessageComponent from "./ToastMessageComponent";
import axios from "axios";
const EmailFindComponent = ({phoneNo}) => {
  const [toastMsg, setToastMsg] = useState(null);
  const [email, setEmail] = useState("");

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/member/email/find?phone=${phoneNo}`).then((res) => {
      if(res.data.email !== undefined) {
        setEmail(res.data.email);
      }
    }).catch((err) => {
      console.log(err);
    });
  }, [phoneNo]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(email);
    setToastMsg("이메일이 복사되었습니다");
  }

  return (
    <div className="email-find-component-wrap">
      <img className="mail-icon" src={mail_icon} alt="mail-icon"/>
      <h1>고객님의 이메일이<br/>확인되었습니다.</h1>
      <div className="finded-email">
        <span>{email}</span>
        <button onClick={copyToClipboard}>
          <img src={copy_to_clipboard} alt="copy_to_clipboard"/>
        </button>
      </div>
      <div className="email-find-comp-btn-area">
        <Link className="email-find-comp-link" to="/login">로그인하기</Link>
        <Link className="email-find-comp-link" to="/find-pw">비밀번호 찾기</Link>
      </div>
      {toastMsg?<ToastMessageComponent message={toastMsg} closeToast={() => setToastMsg(null)} time={3000} type="info"/>:""}
    </div>
  );
}
export default EmailFindComponent;