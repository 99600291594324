import "../styles/ProductListComponent.sass";
import no_product from "../images/no-product.svg";
import { useEffect, useState } from "react";
import axios from 'axios';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import wishlist_deactive from "../images/wishlist-deactive.png";
import wishlist_active from "../images/wishlist-active.png";

const ProductListComponent = ({ listDivision, categorySeq, keywordSeq, spaceSeq, sortType, sortDirection }) => {
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.userInfo.accessToken);
  const loginInfo = useSelector((state) => state.userInfo.userInfo);

  const [productList, setProductList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const fetchProducts = (page) => {
    const params = { pageNo: page };
    if (listDivision === "similar") {
      if (keywordSeq) params.keywordSeq = keywordSeq;
      if (spaceSeq) params.spaceSeq = spaceSeq;
      if (sortType) params.sortType = sortType;
      if (sortDirection) params.sortDirection = sortDirection;
      axios.get(process.env.REACT_APP_API_URL + '/product/similar/list', {
        params: params
      }).then((response) => {
        if (response.data === null || response.data === undefined || response.data === '') return;
        const product_wishlist = localStorage.getItem('product_wishlist');
        if (product_wishlist !== null && product_wishlist !== undefined && product_wishlist !== '') {
          const wishlist = JSON.parse(product_wishlist);
          response.data.productList.forEach((product) => {
            wishlist.forEach((wishlistItem) => {
              if (product.productSeq === wishlistItem) {
                product['wishlist'] = true;
              }
            });
          });
        }
        setCurrentPage(response.data.currentPage);
        setTotalPage(response.data.totalPage);
        setProductList(prevProductList => [...prevProductList, ...response.data.productList]);
      });
    } else {
      if (categorySeq) params.categorySeq = categorySeq;
      if (keywordSeq) params.keywordSeq = keywordSeq;
      if (spaceSeq) params.spaceSeq = spaceSeq;
      if (sortType) params.sortType = sortType;
      if (sortDirection) params.sortDirection = sortDirection;
      axios.get(process.env.REACT_APP_API_URL + '/product/list/' + listDivision, {
        params: params
      }).then((response) => {
        if (response.data === null || response.data === undefined || response.data === '') return;
        const product_wishlist = localStorage.getItem('product_wishlist');
        if (product_wishlist !== null && product_wishlist !== undefined && product_wishlist !== '') {
          const wishlist = JSON.parse(product_wishlist);
          response.data.productList.forEach((product) => {
            wishlist.forEach((wishlistItem) => {
              if (product.productSeq === wishlistItem) {
                product['wishlist'] = true;
              }
            });
          });
        }
        setCurrentPage(response.data.currentPage);
        setTotalPage(response.data.totalPage);
        setProductList(prevProductList => [...prevProductList, ...response.data.productList]);
      });
    }
  };

  useEffect(() => {
    if (!listDivision) return;
    setProductList([]);
    fetchProducts(1);
  }, [listDivision, categorySeq, keywordSeq, spaceSeq, sortType, sortDirection]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY + window.innerHeight >= document.documentElement.scrollHeight) {
        if (currentPage < totalPage) {
          fetchProducts(currentPage + 1);
          setCurrentPage(currentPage + 1);
        }
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [currentPage, totalPage]);

  const onClickProductItem = (productSeq) => {
    // dispatch(productListSetSelectedProductSeq(productSeq));
    navigate(`/product/detail/${productSeq}`);
  }

  const onClickwishlist = (productSeq) => {
    //로그인 상태에서만 가능.
    const loginUser = loginInfo;
    if (loginUser === null || loginUser === undefined || loginUser === '') return;
    const memberSeq = loginInfo.memberSeq;

    const product_wishlist = localStorage.getItem('product_wishlist');
    //(로그인 후) 위시가 처음이라면
    if (product_wishlist === null || product_wishlist === undefined || product_wishlist === '') {
      //제품인덱스 저장
      localStorage.setItem('product_wishlist', JSON.stringify([productSeq]));
      //해당 제품 객체을 찾아 위시 데이터 업데이트하고 업데이트 한 값을 db에도 업데이트.
      setProductList(prevProductList => {
        return prevProductList.map((product) => {
          if (product.productSeq === productSeq) {
            product.wishlist = true;
            product.wishlistCnt += 1;
            axios.post(process.env.REACT_APP_API_URL + '/wishlist/product', {
              memberSeq: memberSeq,
              productSeq: productSeq
            },
              {
                headers: {
                  'Authorization': `Bearer ${accessToken}`
                }
              }).then((response) => {
                console.log(response);
              }).catch((error) => {
                console.log(error);
              })
          }
          return product;
        });
      });
    }
    //(로그인 후) 위시가 처음이 아니라면
    else {
      const wishlist = JSON.parse(product_wishlist);
      //해당 제품의 위시를 취소
      if (wishlist.includes(productSeq)) {
        localStorage.setItem('product_wishlist', JSON.stringify(wishlist.filter(seq => seq !== productSeq)));
        //로컬스토리지에 저장된 해당 제품의 위시 데이터 업데이트(삭제)하고 db에도 반영
        setProductList(prevProductList => {
          return prevProductList.map((product) => {
            if (product.productSeq === productSeq) {
              product.wishlist = false;
              product.wishlistCnt -= 1;
              axios.delete(process.env.REACT_APP_API_URL + '/wishlist/product', {
                data: {
                  memberSeq: memberSeq,
                  productSeq: productSeq
                }
              },
                {
                  headers: {
                    'Authorization': `Bearer ${accessToken}`
                  }
                }).then((response) => {
                  console.log(response);
                }).catch((error) => {
                  console.log(error);
                })
            }
            return product;
          });
        });
      }
      //새로운 제품을 위시
      else {
        wishlist.push(productSeq);
        localStorage.setItem('product_wishlist', JSON.stringify(wishlist));
        setProductList(prevProductList => {
          return prevProductList.map((product) => {
            if (product.productSeq === productSeq) {
              product.wishlist = true;
              product.wishlistCnt += 1;
              axios.post(process.env.REACT_APP_API_URL + '/wishlist/product', {
                memberSeq: memberSeq,
                productSeq: productSeq
              },
                {
                  headers: {
                    'Authorization': `Bearer ${accessToken}`
                  }
                }).then((response) => {
                  console.log(response);
                }).catch((error) => {
                  console.log(error);
                })
            }
            return product;
          });
        });
      }
    }
  }

  return (
    <div className="product-list-wrap">
      <div className="product-list">
        {
          productList.map((item, index) => {
            return (
              <div className="product-item" key={index}>
                {
                  item.thumbnail === null ? <div className="product-image" style={{ backgroundImage: `url('https://via.placeholder.com/140')` }} onClick={() => onClickProductItem(item.productSeq)}></div>
                    : <div className="product-image" style={{ backgroundImage: `url('${process.env.REACT_APP_API_URL}/image/product/${item.thumbnail}')` }} onClick={() => onClickProductItem(item.productSeq)} ></div>
                }
                <div className="product-info">
                  <p className="product-type" onClick={() => onClickProductItem(item.productSeq)}>{item.furnitureTypeName}</p>
                  <h2 className="product-name" onClick={() => onClickProductItem(item.productSeq)}>{item.name}</h2>
                  <p className="product-size" onClick={() => onClickProductItem(item.productSeq)}>{item.width}x{item.height}x{item.depth}cm</p>
                  <div className="product-changeable-list" onClick={() => onClickProductItem(item.productSeq)}>
                    {item.sizeChangeable ? <div className="size-changeable">사이즈</div> : ""}
                    {item.colorChangeable ? <div className="color-changeable">컬러</div> : ""}
                    {item.textureChangeable ? <div className="texture-changeable">재질</div> : ""}
                  </div>
                  <div className="product-bottom-area">
                    <h2 className="product-price" onClick={() => onClickProductItem(item.productSeq)}>
                      <span>{item.price.toLocaleString()}</span>
                      <span>원</span>
                    </h2>
                    <div className="product-review-and-like-count">
                      <div className="product-review-count">
                        <span>리뷰</span>
                        <span>{item.reviewCnt}</span>
                      </div>
                      <div className="product-like-count">
                        <span>{item.wishlistCnt}</span>
                        <span onClick={() => onClickwishlist(item.productSeq)}>
                          {
                            item.wishlist ?
                              <img src={wishlist_active} alt="wishlist_active" width={15} /> :
                              <img src={wishlist_deactive} alt="wishlist_deactive" width={15} />
                          }
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
        {
          productList.length === 0 ? <div className="no-product">
            <img src={no_product} />
            <h1>검색 조건에 해당하는 상품이 없습니다.</h1>
          </div> : ""
        }
      </div>
    </div>
  );
}
export default ProductListComponent;