import { memo } from "react";
import { AccumulativeShadows, RandomizedLight } from "@react-three/drei";
import { isMobile } from "react-device-detect";

export const Environment = memo(
  ({ lightIntensity, direction = [5, 12, 5] }) => (
    <>
      <directionalLight
        position={[8, 15, 8]}
        castShadow
        intensity={lightIntensity}
        shadow-mapSize={2048}
        shadow-bias={-0.0001}
      >
        <orthographicCamera
          attach="shadow-camera"
          args={[-25, 25, 25, -25, 0.1, 25]}
        />
      </directionalLight>
      {!isMobile && (
        <AccumulativeShadows
          resolution={1024}
          frames={100}
          color={"#ddd"}
          alphaTest={0.38}
          colorBlend={0.5}
          opacity={0.15}
          scale={16}
        >
          <RandomizedLight
            radius={20}
            ambient={0.5}
            position={[10, 5, -15]}
            rotation={[0, -Math.PI / 2, 0]}
            bias={0.01}
          />
        </AccumulativeShadows>
      )}
    </>
  )
);

Environment.displayName = "environment";
