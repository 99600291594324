import * as THREE from "three";
import { useEffect, useRef, useState } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { Billboard, Image, Text } from "@react-three/drei";
import { useGesture } from "@use-gesture/react";
import { useDragState } from "./DragState";

const usePersonControls = () => {
  const keys = {
    KeyW: "forward",
    KeyS: "backward",
    KeyA: "left",
    KeyD: "right",
    Space: "jump",
  };

  const moveFieldByKey = (key) => keys[key];

  const [movement, setMovement] = useState({
    forward: false,
    backward: false,
    left: false,
    right: false,
    jump: false,
  });

  useEffect(() => {
    const handleKeyDown = (e) => {
      setMovement((m) => ({ ...m, [moveFieldByKey(e.code)]: true }));
    };
    const handleKeyUp = (e) => {
      setMovement((m) => ({ ...m, [moveFieldByKey(e.code)]: false }));
    };
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);
  return movement;
};

export function PopupUI({
  index,
  enabled,
  position = new THREE.Vector3(1.5, -0.5, -0.5),
  nameText,
  onDragging,
  onDragStartIcon,
  onDragIcon,
  onDragEndIcon,
  onPointerMove,
  onClickCopy,
  onClickDelete,
  onClickRotateLeft,
  onClickRotateRight,
  onClickRotateUp,
  onClickRotateDown,
}) {
  const [hoveredMove, hoverMove] = useState();
  const [hoveredCopy, hoverCopy] = useState();
  const [hoveredDelete, hoverDelete] = useState();
  const [hoveredL, hoverL] = useState();
  const [hoveredR, hoverR] = useState();
  const [hoveredU, hoverU] = useState();
  const [hoveredD, hoverD] = useState();

  const buttonWidth = 1;
  const widthSpace = 0.1;
  const x = position.x;
  const y = position.y;
  const z = position.z;

  const { forward, backward, left, right, jump } = usePersonControls();

  return (
    <group>
      <Billboard>
        <IconButton
          visible={enabled}
          url={"/three/1.jpg"}
          color={"black"}
          onHover={() => {}}
          onClick={() => {}}
          onDragging={onDragging}
          transparent
          position={[x, y + 2.16, z]}
          width={buttonWidth}
          height={0.7}
          text={nameText}
          textColor="white"
        />
        <IconButton
          visible={enabled}
          url={"/three/1.jpg"}
          color={hoveredR ? "grey" : "black"}
          onHover={hoverR}
          onClick={onClickRotateRight}
          onDragging={onDragging}
          transparent
          position={[x, y + 1.44, z]}
          width={buttonWidth}
          height={0.7}
          text="회전"
          textColor="white"
        />
        <IconButton
          visible={enabled}
          url={"/three/1.jpg"}
          color={hoveredCopy ? "grey" : "black"}
          onHover={hoverCopy}
          onClick={onClickCopy}
          onDragging={onDragging}
          transparent
          position={[x, y + 0.72, z]}
          width={buttonWidth}
          height={0.7}
          text="복제"
          textColor="white"
        />
        <IconButton
          visible={enabled}
          url={"/three/1.jpg"}
          color={hoveredDelete ? "grey" : "black"}
          onHover={hoverDelete}
          onClick={onClickDelete}
          onDragging={onDragging}
          transparent
          position={[x, y, z]}
          width={buttonWidth}
          height={0.7}
          text="삭제"
          textColor="white"
        />
        
      </Billboard>
    </group>
  );
}

function IconButton({
  visible,
  url,
  onClick,
  onHover,
  onDragging,
  onDragStartIcon,
  onDragIcon,
  onDragEndIcon,
  onPointerMove,
  width = 1.0,
  height = 1.0,
  color = "white",
  text = "",
  textColor = "black",
  position,
  ...props
}) {
  const imageRef = useRef();
  const textRef = useRef();
  const { camera } = useThree();
  const { setDragAvailable } = useDragState();
  const [isDragging, setIsDragging] = useState(false);
  const setDragging = (b) => {
    setIsDragging(b);
    onDragging?.(b);
  };
  const bind = useGesture({
    onDragStart: ({ event: e }) => {
      e.stopPropagation();
      onDragStartIcon?.(e);
    },
    onDrag: ({ event: e }) => {
      e.stopPropagation();
      onDragIcon?.(e);
    },
    onDragEnd: ({ event: e }) => {
      e.stopPropagation();
      onDragEndIcon?.(e);
    },
    onPointerOver: ({ event: e }) => {
      e.stopPropagation();
      onHover(true);
    },
    onPointerOut: ({ event: e }) => {
      e.stopPropagation();
      onHover(false);
    },
    onPointerDown: ({ event: e }) => {
      e.stopPropagation();
      setDragging(true);
      setDragAvailable(true);
    },
    onPointerUp: ({ event: e }) => {
      e.stopPropagation();
      setDragging(false);
      setDragAvailable(false);
    },
    onPointerMove: ({ event: e }) => {
      e.stopPropagation();
      onPointerMove?.(e);
    },
    onClick: ({ event: e }) => {
      e.stopPropagation();
      onHover(false);
      onClick?.(e);
    },
  });

  useEffect(() => {
    imageRef.current.material.depthTest =
      textRef.current.material.depthTest = false;
    imageRef.current.renderOrder = 10;
    textRef.current.renderOrder = 11;
  }, []);
  const [visibleBounds, setVisibleBounds] = useState(null);
  const GetWidth = () => {
    if (visibleBounds !== null) {
      const boxSize = new THREE.Vector3();
      visibleBounds.getSize(boxSize);
      return boxSize.x + 0.5;
    }
    return width;
  };

  const groupPosition = new THREE.Vector3(
    position[0] + GetWidth() / 2 + 0.3,
    position[1] + height / 2,
    position[2]
  );
  return (
    <group {...props} position={groupPosition} {...bind()}>
      <Image
        ref={imageRef}
        url={url}
        alt=""
        color={color}
        visible={visible}
        scale={visible ? 1 : 0}
      >
        <roundedPlaneGeometry args={[GetWidth(), height, 0.2]} />
        <Text
          ref={textRef}
          color={textColor}
          anchorX="center"
          anchorY="middle"
          fontSize={0.3}
          onSync={(mesh) => {
            const visibleBounds = mesh.textRenderInfo.visibleBounds;
            setVisibleBounds(
              new THREE.Box3(
                new THREE.Vector3(visibleBounds[0], visibleBounds[1], 0),
                new THREE.Vector3(visibleBounds[2], visibleBounds[3], 0)
              )
            );
          }}
        >
          {text}
        </Text>
      </Image>
    </group>
  );
}
