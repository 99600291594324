import "../styles/MyReviewsListComponent.sass";
import no_product from "../images/no-product.svg";
import {useEffect, useState} from "react";
import axios from 'axios';
import {useNavigate} from "react-router-dom";


const MyReviewListComponent = ({ loginInfo, accessToken }) => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [reviews, setReviews] = useState([]);

  const fetchReviews = (page) => {
      try {
        axios.get(process.env.REACT_APP_API_URL + '/review/member/' + loginInfo.memberSeq, {
          params: { pageNo: page },
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
        }).then((response) => {
          if (response.data === null || response.data === undefined || response.data === '') return;
          // 페이지 1이면 목록 초기화, 그렇지 않으면 기존 목록에 추가
          if (page === 1) {
            setReviews(response.data.reviewList);
          } else {
            setReviews(prevReviews => [...prevReviews, ...response.data.reviewList]);
          }          setCurrentPage(page);
          setTotalPage(response.data.totalPage);
        });
      } catch (error) {
        console.log(error);
      }      
   };

  useEffect(() => {
    setReviews([]);
    fetchReviews(1);
  }, []);

  const onClickEditBtn = (item) => {
    navigate(`/review/edit/${item.productReviewSeq}`, { state: { review: item } });

  }

  useEffect(() => {
    const handleScroll = () => {
      if(window.scrollY + window.innerHeight >= document.documentElement.scrollHeight) {
        if(currentPage < totalPage) {
          fetchReviews(currentPage+1);
          setCurrentPage(currentPage+1);
        }
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [currentPage, totalPage]);

  const onClickReviewItem = (productReviewSeq) => {
    navigate(`/review/detail/${productReviewSeq}`);
  }  
  const onClickDeleteBtn = async (item) => {
    const confirmDelete = window.confirm("리뷰를 삭제 하시겠습니까?");

    if (confirmDelete) {
      try {
        const response = await axios.delete(`${process.env.REACT_APP_API_URL}/review/${item.productReviewSeq}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
        if (response.status === 200) {
          fetchReviews(1)
          alert(response.data); // "해당 리뷰가 삭제되었습니다."
        }
      } catch (error) {
        console.error("리뷰 삭제 도중 오류가 발생했습니다.", error);
        alert("리뷰 삭제 도중 오류가 발생했습니다.");
      }
    } else {
      // 사용자가 삭제를 취소한 경우
      alert("리뷰 삭제가 취소되었습니다.");
    }
   }

  return (
    <div className="reviews-list-wrap">
      <div className="reviews-list">
        {
          reviews.map((item, index) => {
            return (
              <div className="reviews-item" key={index}>
                {
                  item.reviewImgList === null
                    ? <div className="reviews-image" key={index} style={{ backgroundImage: `url('https://via.placeholder.com/140')` }} onClick={() => onClickReviewItem(item.productReviewSeq)}></div>
                    : item.reviewImgList && item.reviewImgList.map((img, index) => (
                      <div className="reviews-image" key={index} style={{ backgroundImage: `url('${process.env.REACT_APP_API_URL}/image/review/${img}')` }} onClick={() => onClickReviewItem(item.productReviewSeq)}></div>
                    ))
                }
                <div className="reviews-info">
                  <h2 className="reviews-name" onClick={() => onClickReviewItem(item.productReviewSeq)}>{item.title}</h2>
                  <p className="review-author">작성자: {item.author}</p>
                <p className="review-content" onClick={() => onClickReviewItem(item.productReviewSeq)}>
                  {item.content.length > 30 ? item.content.substring(0, 30) + '...' : item.content}
                </p>
                  <div className="review-buttons">
                    <button className="review-edit-button" onClick={() => onClickEditBtn(item)}>수정하기</button>
                    <button className="review-delete-button" onClick={() => onClickDeleteBtn(item)}>삭제하기</button>
                  </div>
                </div>
              </div>
            )
          })
        }
        {
          reviews.length === 0 ? <div className="no-product">
            <img src={no_product} />
            <h1>리뷰가 없습니다.</h1>
          </div> : ""
        }
      </div>
    </div>
  );
  
}
export default MyReviewListComponent;