import "../styles/ModuleAssembleView.sass";
import ModuleListComponent from "../components/module/ModuleListComponent";
import FurnitureView from "../components/three/FurnitureViewAsync";
import Flicking from "@egjs/react-flicking";
import chevron from "../images/chevron-right.svg";
import "@egjs/react-flicking/dist/flicking.css";
import cart_ico from "../images/cart-ico.svg";
import three_dimension_ico from "../images/3d-mode.svg";
import back_ico from "../images/back-btn.svg";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { productListSetTitle, productListSetListDivision } from "../redux/actions/productList.js"
import {useDispatch, useSelector} from "react-redux";
import { useNavigate} from 'react-router-dom';
import axios from "axios";

const ModuleAssembleView = ({keywordList, furnutureType, form}) => {
  const [showRecommend, setShowRecommend] = useState(false);
  const [showModuleListPopup, setShowModuleListPopup] = useState(false);
  const [selectedModules, setSelectedModules] = useState([]);
  const [recommendModules, setRecommendModules] = useState([]);
  const loginInfo = useSelector((state) => state.userInfo.userInfo);
  useEffect(() => {
    const fetchRecommendModules = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/recommend/module`
        );
        setRecommendModules(response.data.list);
      } catch (error) {
        console.error(
          "추천 모듈 데이터를 불러오는 중에 문제가 발생했습니다 : ",
          error
        );
      }
    };
    fetchRecommendModules();
  }, []);

  const area3dRef = useRef();

  const returnModelFilePath = async (module) => {
    const model = {
            id: module.moduleSeq,
            name: module.title,
            path: `${process.env.REACT_APP_API_URL}/image/3dmodel/${module.moduleModelFile}`,
            position: [0, 0, 0],
            rotation: [0, 0, 0],
            price : module.price,
            image : `${process.env.REACT_APP_API_URL}/image/module/${module.imageFile}`,
            furnitureType : furnutureType.customFurnitureTypeSeq,
            furnitureForm : form.customFurnitureFormSeq,
            keywordList : keywordList

    }
    setSelectedModules(prevModules => {
      const updatedModules = [...prevModules, module];
      return updatedModules;
    });

    if (area3dRef.current) {
      const ret = area3dRef.current.addItem(model);
      }   
  }


  const onClickAddItem = (module) => {
    returnModelFilePath(module);
  };

  const onAddModule = (module) => {
    returnModelFilePath(module);
    setShowModuleListPopup(false);
    setShowRecommend(false);
  };

  const onClickMode = () => {
    if (area3dRef.current) area3dRef.current.resetCamera();
  };
  const onClickSave = () => {
    const loginUser = loginInfo; 
    const temporaryModels = JSON.parse(localStorage.getItem('models')); 
    if (!loginUser) {
      alert("로그인한 유저만 사용 가능합니다.");
      return;
    }
    if (area3dRef.current) {
      const result = area3dRef.current.saveData();
      const modelMap = mergeModels(result.models)
      result.models = modelMap

      if(!temporaryModels){
        result.memberSeq = loginUser.memberSeq 
        const models = [result];
        localStorage.setItem('models', JSON.stringify(models));
        alert("임시 저장 완료. 나의 가구에서 확인할 수 있습니다.")
      }
      else{
        const modelIndex = temporaryModels.findIndex(obj => obj.memberSeq === loginUser.memberSeq); 
        if (modelIndex !== -1) {
          temporaryModels[modelIndex].models = result.models;
        }
        else {
          result.memberSeq = loginUser.memberSeq; 
          temporaryModels.push(result); 
        }
        localStorage.setItem('models', JSON.stringify(temporaryModels));   
        alert("임시 저장 완료. 나의 가구에서 확인할 수 있습니다.")
      }
    }
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onClickSimilarProduct = () => {
    if (keywordList.length === 0) {
      alert('선택된 키워드가 없습니다.');
      return;
    }
    dispatch(productListSetTitle("비슷한 제품"));
    dispatch(productListSetListDivision("similar"));
    navigate("/product", {state : keywordList[0]})    
  };
  const onClickBuy = () => {
    if (!loginInfo) {
      alert("로그인한 유저만 접근 가능합니다.");
      return;
    }
    const result = area3dRef.current.saveData();

    if(result.models.length < 1){
      alert("부품을 선택해주세요.");
      return;
    }
    area3dRef.current.resetItems();
    const modelMap = mergeModels(result.models)
    navigate("/order", { state: { type : "C", purchaseItemList : modelMap } });
  }


  const mergeModels = (models) => {
  const modelMap = models.reduce((acc, model) => {
    if (acc[model.itemSeq]) {
      acc[model.itemSeq].count += model.count;
      acc[model.itemSeq].position.push(model.position);
      acc[model.itemSeq].rotation.push(model.rotation);
      acc[model.itemSeq].totalPrice += model.price;
    } else {
      acc[model.itemSeq] = {
        ...model,
        count: model.count,
        position: [model.position],
        rotation: [model.rotation],
        totalPrice : model.price
      };
    }
    return acc;
  }, {});

  return Object.values(modelMap);
};

  return (
    <div className="module-assemble-view-wrap">
      <div className="module-assemble-3d-area">
        {/* 3D 가구조립 모듈이 들어갈 영역 */}
        <Suspense fallback={<div>로딩 중. . .</div>}>
          <FurnitureView ref={area3dRef} />
        </Suspense>
      </div>
      <div className="side-btn-area">
        <button className="btn-similar-product" onClick={onClickSimilarProduct}>
          <img src={cart_ico} alt="cart" />
          <span>비슷한제품</span>
        </button>
        <button className="btn-3d-mode">
          <img src={three_dimension_ico} alt="3d" onClick={onClickMode} />
          <span>정면 보기</span>
        </button>
        <button className="btn-back">
          <img src={back_ico} alt="back" />
          <span>뒤로</span>
        </button>
      </div>
      <div className={"recommend-wrap " + (showRecommend ? "show" : "")}>
        <div
          className={"expand-btn " + (showRecommend ? "show" : "")}
          onClick={() => setShowRecommend(!showRecommend)}
        >
          <span>추천구성</span>
          <img src={chevron} alt="chevron" />
        </div>
        <div className="module-recommend-list">
          <div className="module-recommend-title-area">
            <h2>모듈을 추가해보세요</h2>
            <button onClick={() => setShowModuleListPopup(true)}>
              <span>전체보기</span>
              <img src={chevron} alt="chevron" />
            </button>
          </div>
          <div className="module-recommend-item-list">
            <div className="slider-container">
              <Flicking
                moveType="snap"
                circular={false}
                bound={true}
                align="prev"
              >
                {recommendModules.map((module) => (
                  <div
                    key={module.rmSeq}
                    className="item"
                    style={{
                      backgroundImage: `url(${process.env.REACT_APP_API_URL}/image/module/${module.imageFile})`,
                    }}
                    onClick={() => onClickAddItem(module)}
                  >
                    <span>{module.title}</span>
                  </div>
                ))}
              </Flicking>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-button-area">
        <button onClick={onClickBuy}>가견적 확인하기</button>
        <button onClick={onClickSave}>임시저장</button>
      </div>
      <ModuleListComponent
        show={showModuleListPopup}
        onCloseList={() => setShowModuleListPopup(false)}
        onAddModule={onAddModule}
      />
    </div>
  );
};
export default ModuleAssembleView;
