import cart_ico_dark from '../images/cart-ico-dark.svg';
import '../styles/AddToCartPopupComponent.sass';
import {Link} from "react-router-dom";
const AddToCartPopupComponent = ({onClosePopup}) => {

  return (
    <div className="add-to-cart-popup-wrap">
      <div className="add-to-cart-popup">
        <img src={cart_ico_dark} alt="cart"/>
        <p>상품을 장바구니에 담았습니다.</p>
        <div className="btn-area">
          <Link to="/mypage/cart">장바구니로 이동</Link>
          <button onClick={onClosePopup}>계속하기</button>
        </div>
      </div>
    </div>
  );
}

export default AddToCartPopupComponent;