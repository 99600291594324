import HeaderComponent from "../components/HeaderComponent";
import FurnitureTypeSelectComponent from "../components/module/FurnitureTypeSelectComponent";
import {useState} from "react";
import FurnitureKeywordSelectComponent from "../components/module/FurnitureKeywordSelectComponent";
import FurnitureFormSelectComponent from "../components/module/FurnitureFormSelectComponent";
import ModuleGuideComponent from "../components/module/ModuleGuideComponent";
import ModuleGuideFinish from "../components/module/ModuleGuideFinish";
import ModuleAssembleView from "./ModuleAssembleView";

const ModuleMainView = () => {
  const [furnutureType, setFurnitureType] = useState({});
  const [keywordList, setKeywordList] = useState([]);
  const [furnitureForm, setFurnitureForm] = useState({});
  const [headerTheme, setHeaderTheme] = useState("white");

  const [moduleStep, setModuleStep] = useState(0);

  const onSelectFurnitureType = (furnitureType) => {
    if(!furnitureType.available) return;
    setFurnitureType(furnitureType);
    setModuleStep(1);
  }

  const onSelectFurntureKeyword = (keywordList) => {
    setKeywordList(keywordList);
    setModuleStep(2);
  }

  const onSelectFurnitureForm = (furnitureForm) => {
    setFurnitureForm(furnitureForm);
    setModuleStep(3);
  }

  const onModuleGuideCompleted = () => {
    setModuleStep(4);
    setHeaderTheme("blue")
  }

  const onStartModuleAssemble = () => {
    setModuleStep(5);
    setHeaderTheme("gray")
  }

  return (
    <div className="module-main-view-wrap">
      <HeaderComponent theme={headerTheme}/>
      {moduleStep === 0?<FurnitureTypeSelectComponent onSelectFurnitureType={onSelectFurnitureType}/>:""}
      {moduleStep === 1?<FurnitureKeywordSelectComponent onSelectFurntureKeyword={onSelectFurntureKeyword}/>:""}
      {moduleStep === 2?<FurnitureFormSelectComponent furnitureTypeSeq={furnutureType.customFurnitureTypeSeq} onSelectFurnitureForm={onSelectFurnitureForm}/>:""}
      {moduleStep === 3?<ModuleGuideComponent onModuleGuideCompleted={onModuleGuideCompleted}/>:""}
      {moduleStep === 4?<ModuleGuideFinish onStartModuleAssemble={onStartModuleAssemble} />:""}
      {moduleStep === 5?<ModuleAssembleView keywordList={keywordList} furnutureType = {furnutureType} form = {furnitureForm}/>:""}
    </div>

  );
}

export default ModuleMainView;