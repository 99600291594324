import "../styles/ProductListView.sass";
import axios from 'axios';
import HeaderComponent from "../components/HeaderComponent";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import ProductListComponent from "../components/ProductListComponent";
import ProductFilterComponent from "../components/ProductFilterComponent";


const ProductListView = () => {
  const pageTitle = useSelector(state => state.productList.title);
  const categorySeq = useSelector(state => state.productList.categorySeq);
  const listDivision = useSelector(state => state.productList.listDivision);
  const location = useLocation()
  useEffect(() => {
    if (listDivision !== 'category') return;
    axios.get(process.env.REACT_APP_API_URL + '/category/path', {
      params: { categorySeq: categorySeq }
    }).then((response) => {
      setCategoryPath(response.data);
      let title = ""
      response.data.forEach((item) => {
        title += `<span>${item.name}</span>`
      })
    }).catch((err) => {

    })
  }, [categorySeq]);

  window.addEventListener('scroll', () => {
    if (window.scrollY > 0 && !floatingHeader) {
      setFloatingHeader(true);
    } else if (window.scrollY === 0 && floatingHeader) {
      setFloatingHeader(false);
    }
  })
  const [keywordSeq, setKeywordSeq] = useState(location?.state || null);
  const [spaceSeq, setSpaceSeq] = useState(null);
  const [sortType, setSortType] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [categoryPath, setCategoryPath] = useState([]);
  const [floatingHeader, setFloatingHeader] = useState(false);

  const onChangeKeywordFilter = (value) => {
    setKeywordSeq(value)
  }
  const onChangeSpaceFilter = (value) => {
    setSpaceSeq(value)
  }
  const onChangeSortFilter = (value) => {
    setSortType(value.split(",")[0])
    setSortDirection(value.split(",")[1])
  }

  return (
    <div className="product-view-wrap">
      <div className={"product-view-header " + (floatingHeader ? "floating" : "")}>
        <HeaderComponent isMainView={false} />
        <h1>
          {
            listDivision === 'category' ? categoryPath.map((item, index) => {
              if (index !== categoryPath.length - 1) return <span key={index} className="page-title-category">{item.categoryNameKor}<span className="page-title-sep">|</span></span>
              else return <span key={index} className="page-title-category">{item.categoryNameKor}</span>
            }) : pageTitle
          }
        </h1>
        <ProductFilterComponent
          onChangeKeywordFilter={onChangeKeywordFilter}
          onChangeSpaceFilter={onChangeSpaceFilter}
          onChangeSortFilter={onChangeSortFilter}
          similarKeyword={keywordSeq}
        />
      </div>
      <div className="product-view-content">
        <ProductListComponent
          listDivision={listDivision} categorySeq={categorySeq}
          keywordSeq={keywordSeq} spaceSeq={spaceSeq}
          sortType={sortType} sortDirection={sortDirection}
        />
      </div>
    </div>
  );
}

export default ProductListView;