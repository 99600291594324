import React, { useState, useEffect } from 'react';
import '../styles/ReviewEditView.sass';
import axios from 'axios';
import HeaderComponent from '../components/HeaderComponent';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
const ReviewEditView = () => {
  const { productReviewSeq } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const reviewData = location.state?.review;

  const [reviewText, setReviewText] = useState('');
  const [title, setTitle] = useState('');
  const [reviewImage, setReviewImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const accessToken = useSelector((state) => state.userInfo.accessToken);
  const loginInfo = useSelector((state) => state.userInfo.userInfo);


  useEffect(() => {
    if (reviewData && loginInfo) {
      setTitle(reviewData.title);
      setReviewText(reviewData.content);
      setPreviewImage(`${process.env.REACT_APP_API_URL}/image/review/${reviewData.reviewImgList[0]}`);
    } else {
      alert('로그인을 하지 않았거나 데이터를 불러오는 중 문제가 발생했습니다. 관리자에게 문의바랍니다.')
      navigate('/');
    }
  }, [reviewData]);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleReviewChange = (e) => {
    setReviewText(e.target.value);
  };


  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file === null) return;
    setPreviewImage(URL.createObjectURL(file));
    setReviewImage(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const loginUser = loginInfo;
    if (loginUser === null || loginUser === undefined || loginUser === '') return;

    const updateData = {
      productReviewSeq: reviewData.productReviewSeq,
      title: title,
      content: reviewText,
      memberSeq: loginUser.memberSeq
    };

    const formData = new FormData();
    formData.append('reviewData', new Blob([JSON.stringify(updateData)], { type: "application/json" }));
    if (reviewImage) {
      formData.append('reviewImageFile', reviewImage);
    }

    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/review/update`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${accessToken}`
        },
      });
      alert("리뷰가 수정되었습니다.");
      navigate(`/review/detail/${productReviewSeq}`);
    } catch (error) {
      console.error('Error updating review', error);
      alert('리뷰 수정 중 오류가 발생했습니다. 관리자에게 문의바랍니다.')
      navigate('/');
    }
  };

  return (
    <div className="product-view-wrap">
      <HeaderComponent isMainView={false} />
      <div className="review-component">
        <h2>구매 후기 수정</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="제목을 입력해주세요"
            value={title}
            onChange={handleTitleChange}
            required
          />
          <textarea
            placeholder="후기를 작성해주세요"
            value={reviewText}
            onChange={handleReviewChange}
            required
          ></textarea>
          <div className="image-upload">
            <label htmlFor="reviewImage">이미지 업로드</label>
            <input type="file" id="reviewImage" accept="image/*" onChange={handleImageChange} />
            {previewImage && <img src={previewImage} alt="Preview" />}
          </div>
          <button type="submit">리뷰 수정</button>
        </form>
      </div>
    </div>
  );
};

export default ReviewEditView;
