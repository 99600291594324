import {
  MAIN_MENU_SET_VISIBLE
} from "../actions/mainMenu";

const initialState = {
  visible: false
};

export const mainMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case MAIN_MENU_SET_VISIBLE:
      return {
        ...state,
        visible: action.visible
      };
    default:
      return state;
  }
}
