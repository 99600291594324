import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from 'axios';
import ModalPopupComponent from "../components/ModalPopupComponent";
import { useSelector, useDispatch } from "react-redux"; 
import { userAccessTokenSet, userInfoSet } from "../redux/actions/userInfo";
const NaverAuthView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [modalPopup, setModalPopup] = useState(false);
  const accessToken = useSelector((state) => state.userInfo.accessToken);

  useEffect(() => {
    const queryParams = window.location.hash.substring(1).split("&");
    const params = {};
    queryParams.forEach((param) => {
      const [key, value] = param.split("=");
      params[key] = value;
    });
    getUserData(params);
  }, []);

  const getUserData = async (params) => {
    const result = await axios.post(process.env.REACT_APP_API_URL+'/member/naver-auth', params);
    if(result.data.result === "success") {
      try{
        dispatch(userAccessTokenSet(result.data.accessToken));
        dispatch(userInfoSet(result.data.loginMember))
        sessionStorage.removeItem("socialInfo");
        const wishlistResponse = await axios.get(`${process.env.REACT_APP_API_URL}/wishlist/product/list/seq?memberSeq=${result.data.loginMember.memberSeq}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
        const moduleWishlistSeqs = await axios.get(`${process.env.REACT_APP_API_URL}/wishlist/module/list/seq?memberSeq=${result.data.loginMember.memberSeq}`, {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        });
        localStorage.setItem('product_wishlist', JSON.stringify([...new Set(wishlistResponse.data)]));
        localStorage.setItem('module_wishlist', JSON.stringify(moduleWishlistSeqs.data));
        navigate('/');
      }
      catch (err) {
        alert('위시리스트 데이터를 불러오는 도중 에러가 발생했습니다.')
        console.error(err)
      }
    }
    else if(result.data.result === "nodata") {
      const loginData = {
        socialToken: result.data.socialToken,
        socialType: "naver"
      }
      sessionStorage.setItem("socialInfo", JSON.stringify(loginData));
      setModalPopup(true);
    }
    else {
      alert("네이버 로그인 중 오류가 발생했습니다.");
    }
  }

  return (
    <div>
      <div>
        {modalPopup ? <ModalPopupComponent
          title="가입하지 않은 사용자입니다"
          contents={["가입하지 않은 사용자입니다", "회원가입 후 이용해주세요"]}
          links={[{title: "회원가입하기", link: "/sns-join"}]}
          onClickClose={() => {
            setModalPopup(false);
            navigate('/login');
          }}
        /> : ""
        }
      </div>
    </div>
  );
}
export default NaverAuthView;