import React from 'react';
import HeaderComponent from "../components/HeaderComponent";
import "../styles/OrderCheckView.sass";
import { useSearchParams } from "react-router-dom";

const PaymentFailView = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const message = searchParams.get("message");

  return (
    <div className="payment-success-view-wrap">
      <HeaderComponent />
      <div className="payment-success-content">
        <div className="icon"></div>
        <h1>결제가 실패했습니다</h1>
        {message && <p>{`사유: ${message}`}</p>}
        {code && <p>{`에러코드: ${code}`}</p>}
      </div>
    </div>
  );
};

export default PaymentFailView;