export const PRODUCT_LIST_SET_CATEGORY_SEQ = "PRODUCT_LIST_SET_CATEGORY_SEQ";
export const PRODUCT_LIST_SET_LIST_DIVISION = "PRODUCT_LIST_SET_LIST_DIVISION";
export const PRODUCT_LIST_SET_TITLE = "PRODUCT_LIST_SET_TITLE";
export const PRODUCT_LIST_SET_SELECTED_PRODUCT_SEQ = "PRODUCT_LIST_SET_SELECTED_PRODUCT_SEQ";
export const PRODUCT_LIST_SET_KEYWORD_SEQ = "PRODUCT_LIST_SET_KEYWORD_SEQ";

export const productListSetTitle = (title) => {
  return {
    type: PRODUCT_LIST_SET_TITLE,
    title: title,
    selectedProdctSeq: 0
  }
}
export const productListSetListDivision = (listDivision) => {
  return {
    type: PRODUCT_LIST_SET_LIST_DIVISION,
    listDivision: listDivision
  }
}
export const productListSetCategorySeq = (categorySeq) => {
  return {
    type: PRODUCT_LIST_SET_CATEGORY_SEQ,
    categorySeq: categorySeq
  }
}

export const productListSetSelectedProductSeq = (productSeq) => {
  return {
    type: PRODUCT_LIST_SET_SELECTED_PRODUCT_SEQ,
    productSeq: productSeq
  }
}

export const productListSetKeywordSeq = (keywordSeq) => {
  return {
    type: PRODUCT_LIST_SET_KEYWORD_SEQ,
    keywordSeq: keywordSeq
  }
}