import HeaderComponent from "../components/HeaderComponent";

const PurchaseListView = () => {
  return (
    <div>
      <HeaderComponent />
      <h1>Purchase List View</h1>
    </div>
  );
}
export default PurchaseListView;