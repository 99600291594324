import "../../styles/module/ModuleListComponent.sass";
import close_btn from "../../images/close-btn.png";
import wishlist_deactive_outline from "../../images/wishlist-deactive-outline.png";
import wishlist_active from "../../images/wishlist-active.png";

import ProductFilterComponent from "../ProductFilterComponent";
import ModuleDetailComponent from './ModuleDetailComponent';

import {useEffect, useState} from "react";

import axios from 'axios';

import { useSelector } from "react-redux"; 


const ModuleListComponent = ({show, onCloseList, onAddModule}) => {
  const [selectedKeywordFilterSeq, setSelectedKeywordFilterSeq] = useState(0);
  const [selectedSpaceFilterSeq, setSelectedSpaceFilterSeq] = useState(0);
  const [selectedSortFilter, setSelectedSortFilter] = useState("popular,desc");
  const [selectedModule, setSelectedModule] = useState(null);
  const [totalModuleCount, setTotalModuleCount] = useState(120);
  const [moduleList, setModuleList] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [selectedModuleSeq, setSelectedModuleSeq] = useState(null);

  const accessToken = useSelector((state) => state.userInfo.accessToken);
  const loginInfo = useSelector((state) => state.userInfo.userInfo);
  
  useEffect(() => {
    const handleScroll = (e) => {
      const { scrollTop, clientHeight, scrollHeight } = e.target;
      if (scrollTop + clientHeight >= scrollHeight -5) {
        if (currentPage < totalPage) {
          setCurrentPage(currentPage + 1);
        }
      }
    };

    const moduleListElement = document.querySelector('.module-list');
    moduleListElement.addEventListener('scroll', handleScroll);

    return () => {
      moduleListElement.removeEventListener('scroll', handleScroll);
    };
  }, [currentPage, totalPage]);

  const onChangeKeywordFilter = (keywordSeq) => {
    setCurrentPage(1);
    setSelectedKeywordFilterSeq(keywordSeq);
  }

  const onChangeSpaceFilter = (spaceSeq) => {
    setCurrentPage(1);
    setSelectedSpaceFilterSeq(spaceSeq);
  }
  const onChangeSortFilter = (sort) => {
    setCurrentPage(1);
    setSelectedSortFilter(sort)
  };

  const onModuleSelect = (module) => {
    if(selectedModule && (module.moduleSeq === selectedModule.moduleSeq)) {
      setSelectedModule(null);
      return;
    }
    setSelectedModule(module);
  }

  const getModuleList = () => {
    const params = {};
    params['pageNo'] = currentPage;
    params['pageSize'] = 10;
    if(selectedKeywordFilterSeq > 0) params['keywordSeq'] = selectedKeywordFilterSeq;
    if(selectedSpaceFilterSeq > 0) params['spaceSeq'] = selectedSpaceFilterSeq;
    if(selectedSortFilter) {
      params['orderBy'] = selectedSortFilter.split(",")[0];
      params['orderDirection'] = selectedSortFilter.split(",")[1];
    }
    axios.get(process.env.REACT_APP_API_URL + "/module/list", {
      params:params
    }).then((response) => {
      response.data.moduleList.map((module) => {
        module.moduleWishlist = false;
        return module;
      });
      setTotalModuleCount(response.data.totalCount);
      setTotalPage(response.data.totalPage);
      if(currentPage === 1) {
        setModuleList(response.data.moduleList);
      }
      else {
        setModuleList(prevModuleList => {
          return [...prevModuleList, ...response.data.moduleList];
        });
      }
    }).catch((err) => {
      console.log(err);
    })
  }
  useEffect(() => {
    getModuleList();
  },[selectedKeywordFilterSeq, selectedSpaceFilterSeq, selectedSortFilter, currentPage]);

  const onClickWishList = async (moduleSeq) => {
    const loginUser = loginInfo;
    if(loginUser === null || loginUser === undefined || loginUser === '') return;
    const memberSeq = loginUser.memberSeq;
    const module_wishlist = await localStorage.getItem('module_wishlist');
    //(로그인 후) 위시가 처음이라면
    if(module_wishlist === null || module_wishlist === undefined || module_wishlist === '') {
      //제품인덱스 저장
      localStorage.setItem('module_wishlist', JSON.stringify([moduleSeq]));
      //해당 제품 객체을 찾아 위시 데이터 업데이트하고 업데이트 한 값을 db에도 업데이트.
      setModuleList(prevModuleList => {
        return prevModuleList.map((module) => {
          if(module.moduleSeq === moduleSeq) {
            module.wishlist = true;
            //module.wishlistCnt += 1;
            axios.post(process.env.REACT_APP_API_URL + '/wishlist/module', {
              memberSeq: memberSeq,
              moduleSeq: moduleSeq
            }, {
              headers: {
                'Authorization': `Bearer ${accessToken}`
              }
            }
          ).then((response) => {
            }).catch((error) => {
              console.log(error);
            })
          }
          return module;
        });
      });
    }
   //(로그인 후) 위시가 처음이 아니라면
   else {
     const wishlist = JSON.parse(module_wishlist);
     //해당 제품의 위시를 취소
     if(wishlist.includes(moduleSeq)) {
       localStorage.setItem('module_wishlist', JSON.stringify(wishlist.filter(seq => seq !== moduleSeq)));
       //로컬스토리지에 저장된 해당 제품의 위시 데이터 업데이트(삭제)하고 db에도 반영
       setModuleList(prevModuleList => {
         return prevModuleList.map((module) => {
           if(module.moduleSeq === moduleSeq) {
             module.wishlist = false;
             //module.wishlistCnt -= 1;
             axios.delete(process.env.REACT_APP_API_URL + '/wishlist/module', {
               data: {
                 memberSeq: memberSeq,
                 moduleSeq: moduleSeq
               }
             },
               {
                 headers: {
                   'Authorization': `Bearer ${accessToken}`
                 }
               }).then((response) => {
             }).catch((error) => {
               console.log(error);
             })
           }
           return module;
         });
       });
     }
     //새로운 제품을 위시
     else {
       wishlist.push(moduleSeq);
       localStorage.setItem('module_wishlist', JSON.stringify(wishlist));
       setModuleList(prevModuleList => {
         return prevModuleList.map((module) => {
           if(module.moduleSeq === moduleSeq) {
            module.wishlist = true;
             //module.wishlistCnt += 1;
             axios.post(process.env.REACT_APP_API_URL + '/wishlist/module', {
               memberSeq: memberSeq,
               moduleSeq: moduleSeq
             },
               {
                 headers: {
                   'Authorization': `Bearer ${accessToken}`
                 }
               }).then((response) => {
             }).catch((error) => {
               console.log(error);
             })
           }
           return module;
         });
        });
     }
   }
 }

  const onClickModuleAdd = (module) => {
    onAddModule(module);
    setSelectedModule(null);
  }  
  const onClickModuleDetails = (moduleSeq) => {
    setSelectedModuleSeq(moduleSeq);
  };
  const onCloseDetailView = () => {
    setSelectedModuleSeq(null);
  };

    const handleOnCloseList = () => {
    // 상태 초기화
    setSelectedKeywordFilterSeq(0);
    setSelectedSpaceFilterSeq(0);
    setSelectedSortFilter("popular,desc");
    setSelectedModule(null);
    setTotalModuleCount(120);
    setModuleList([]);
    setCurrentPage(1);
    setTotalPage(1);
    setSelectedModuleSeq(null);
    onCloseList();
  };
  return (
    <div class={"module-list-comp-wrap "+(show?"open":"")}>
      <div className="module-list-comp-header">
        <h2>모듈 추가하기</h2>
        <button onClick={handleOnCloseList}>
          <img src={close_btn} alt="close" />
        </button>
      </div>
      <h1><span>선반</span><span>{totalModuleCount}개의 모듈</span></h1>
      <ProductFilterComponent
        onChangeKeywordFilter={onChangeKeywordFilter}
        onChangeSpaceFilter={onChangeSpaceFilter}
        onChangeSortFilter={onChangeSortFilter}
      />
      <div className="module-list">
        {
          moduleList.map((module, index) => {
            return (
              <div className={"module-item "+(selectedModule && (module.moduleSeq === selectedModule.moduleSeq)?"selected":"")} key={index}>
                <div className="module-name" onClick={() => onModuleSelect(module)}>
                  <h2>{module.title}</h2>
                  <p>{module.choiceCount}번 선택</p>
                </div>
                <div className="module-image" onClick={() => onModuleSelect(module)}>
                  <img src={`${process.env.REACT_APP_API_URL}/image/module/${module.imageFile}`} alt="module"/>
                </div>
                <div className="module-price" onClick={() => onModuleSelect(module)}>￦{module.price.toLocaleString()}</div>
                <div className="wishlist-btn" onClick={() => onClickWishList(module.moduleSeq)}>
                  {module.wishlist?<img src={wishlist_active} alt="wishlist"/>:
                    <img src={wishlist_deactive_outline} alt="wishlist"/>}
                </div>
              </div>
            )
          })
        }
      </div>
      {show && selectedModule ? (
        <div className="module-actions">
          <button className="module_add" onClick={() => onClickModuleAdd(selectedModule)}>
            모듈 추가하기
          </button>
          <button className="module_details" onClick={() => onClickModuleDetails(selectedModule.moduleSeq)}>
            상세보기
          </button>
        </div>
      ) : (
        ""
      )}
      <div>
      {selectedModuleSeq && (
        <ModuleDetailComponent moduleSeq={selectedModuleSeq} onClose={onCloseDetailView} />
      )}
      </div>
    </div>
  );
}
export default ModuleListComponent;