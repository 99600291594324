import React, { useEffect, useState, Suspense, useRef } from "react";
import FurnitureView from "../components/three/FurnitureView";
import check from "../images/check.png";
import HeaderComponent from "../components/HeaderComponent";
import ToastMessageComponent from "../components/ToastMessageComponent";
import axios from "axios";
import "../styles/OrderCheckView.sass";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux"; 


const OrderCheckView = () => {
  const location = useLocation();
  const itemList = location.state.purchaseItemList;
  const itemType = location.state.type
  const [toastMessage, setToastMessage] = useState(null);
  const [toastType, setToastType] = useState("info");

  const [selectedItems, setSelectedItems] = useState([]);
  const [purchaseItemList, setPurchaseItemList] = useState([]);
  const [addressList, setAddressList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [type, setType] = useState(null);
  const [selectedItemsTotalPrice, setSelectedItemsTotalPrice] = useState(0);
  const [preload, setPreload] = useState(null);
  const [enableClick, setEnableClick] = useState(false);
  const accessToken = useSelector((state) => state.userInfo.accessToken);
  const loginInfo = useSelector((state) => state.userInfo.userInfo);
  const [loginUser, setLoginUser] = useState(loginInfo);

  const navigate = useNavigate();
  const area3dRef = useRef();

  useEffect(() => {
    loadAddressList();
  }, []);

  useEffect(() => {
    if (!itemList || !itemType) {
      alert("잘못된 접근입니다.");
      navigate("/");
    } else {
      if (itemType === "C") {
        const updatedItems = itemList.map((item) => ({
          ...item,
          selected: true,
        }));
        setPurchaseItemList(updatedItems);
        setSelectedItems(updatedItems);
      } else {
        setPurchaseItemList(itemList);
      }
      setType(itemType);
    }
  }, [itemList, itemType, navigate]);

  useEffect(() => {
    if (loginInfo === null || loginInfo === undefined || loginInfo === "") {
      alert("로그인한 유저만 접근 가능합니다.");
      navigate("/");
    }
  }, []);

  useEffect(() => {
    const total = selectedItems.reduce((acc, item) => acc + item.price * item.count, 0);
    setSelectedItemsTotalPrice(total);
  }, [selectedItems]);

  const onClickAddress = (addressId) => {
    setSelectedAddress(addressId)
  };

  useEffect(() => {
    if (type === "C" && purchaseItemList.length > 0) {
      const data = addModules3dData(purchaseItemList);

      setPreload({ models: data });
    }
  }, [purchaseItemList, type]);


  const addModules3dData = (itemList) => {
    const data = [];
    itemList.forEach(item => {
      if (item.count === 1) {
           
          const newItem = {
            ...item,
            count: 1,
            position: item.position[0],
            rotation: item.rotation[0],
          };
          data.push(newItem);
        
      } else {
        for (let i = 0; i < item.count; i++) {
          const newItem = {
            ...item,
            count: 1,
            position: item.position[i],
            rotation: item.rotation[i],
          };
          data.push(newItem);
        }
      }
    });
    return data;
  };

  const loadAddressList = () => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/member/address/list`,
        {headers : {
        'Authorization': `Bearer ${accessToken}`
      }})
      .then((res) => {
        setAddressList(res.data);
      })
      .catch((err) => {
        console.error("Error fetching address : ", err);
      });
  };
  const onClickItemChk = (e, item) => {
    const checked = e.target.checked;
    if (checked) {
      setSelectedItems((prevSelectedItems) => [...prevSelectedItems, item]);
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((seq) => seq !== item)
      );
    }
  };

  const formatPrice = (price) => {
    return price.toLocaleString();
  };

  const handleCheckout = () => {
    if(selectedItems.length === 0 ){
      alert("구매할 항목을 선택해주세요");
      return;
    }else if(!selectedAddress){
      alert("배송지를 선택해 주세요.")
      return;
    }
    let orderName = '';
    if(type === "C") {
      orderName = document.getElementById("customFurnitureTitle").value
      if(orderName === ""){
        alert("이름을 입력해주세요.");
        return;
      }
    }
    else{
      if (selectedItems.length > 1) {
        orderName = `${selectedItems[0].name} 외 ${selectedItems.length - 1}건`;
      } else {
        orderName = selectedItems[0].name;
      }
    }
    const stateof = {
    totalAmount: selectedItemsTotalPrice, 
    orderName: orderName,
    email: loginUser.email,
    memberSeq: loginUser.memberSeq,
    items : selectedItems,
    addressSeq : selectedAddress,
    type : type
    };
    if (type === "C") {
      stateof.furnitureType = selectedItems[0].furnitureType;
      stateof.furnitureForm = selectedItems[0].furnitureForm;
      stateof.keywordList = selectedItems[0].keywordList;
    }
    navigate("/checkout", {state : stateof});
  };

  return (
  <div className="payment-view-wrap">
    <HeaderComponent />
      {type === "C" && preload && (
        <div className="module-assemble-3d-area">
          <Suspense fallback={<div>로딩 중. . .</div>}>
            <FurnitureView ref={area3dRef} preload={preload} enableClick={enableClick} />
          </Suspense>
        </div>
      )}
    <div className="payment-item-info">
      {type === "C" && (
        <>
          <h1>맞춤가구 이름</h1>
          <input type="text" id="customFurnitureTitle" maxLength="18" placeholder="맞춤가구 이름을 입력하세요" />
        </>
    )}
      <h1>주문상품정보</h1>
      <div className="payment-item-list">
        {purchaseItemList.map((item, index) => {
          return (
            <div
              key={item.itemSeq} 
              className={"payment-item " + (item.selected ? "selected" : "")}
            >
              <div className="payment-item-header">
                <input
                  type="checkbox"
                  id={`payment-item-chk${index}`}
                  checked={type === "C" ? true : undefined} 
                  onClick={(e) => onClickItemChk(e, item)}
                  disabled={type === "C"} 
                  />
                  <label
                    htmlFor={`payment-item-chk${index}`}
                    style={{ backgroundImage: `url('${check}')` }}
                  ></label>
                </div>
                <div className="payment-item-body">
                  {item.itemType === "P" ? (
                    <div
                      className="payment-item-img"
                      style={{
                        backgroundImage: `url('${process.env.REACT_APP_API_URL}/image/product/${item.image}')`,
                      }}
                    ></div>
                  ) : (
                    <div
                      className="payment-item-img"
                      style={{
                        backgroundImage: `url('${item.image}')`,
                      }}
                    ></div>
                  )}
                  <div className="payment-item-detail">
                    <h2 className="payment-item-title">{item.name}</h2>
                    <p className="payment-item-price">
                      {isNaN(item.price) ? "0" : formatPrice(item.price)}원
                    </p>
                    <p className="payment-item-count">수량 {item.count}개</p>
                    <div className="payment-item-price-sum">
                      합계 : {formatPrice(item.price * item.count)}원
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="payment-total-price">
          총 결제액 : {formatPrice(selectedItemsTotalPrice)}원
        </div>
        <div className="shipping-payment-info">
          <h1>배송지 선택</h1>
          <div className="address-list">
            {addressList.map((address, index) => {
              return (
                <div
                  key = {address.memberAddressSeq}
                  className={
                    "address-item " +
                    (selectedAddress === address.memberAddressSeq
                      ? "selected"
                      : "")
                  }
                  onClick={() => onClickAddress(address.memberAddressSeq)}
                >
                  <div className="address-title">{address.title}</div>
                  <div className="address-content">
                    ({address.zipcode}) {address.address}{" "}
                    {address.detailAddress}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="payment-view-btn-area">
          <button
            className="cart-purchase-btn"
            disabled={selectedItems.length === 0}
            onClick={handleCheckout}
          >
            구매하기
          </button>
        </div>
      </div>
      {toastMessage ? (
        <ToastMessageComponent
          message={toastMessage}
          type={toastType}
          time={3000}
          closeToast={() => setToastMessage(null)}
        />
      ) : (
        ""
      )}
    </div>
  );
};
export default OrderCheckView;
