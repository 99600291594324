import {useEffect, useRef} from 'react';
import "../styles/ToastMessageComponent.sass";
const ToastMessageComponent = ({ message, type, time, closeToast }) => {

  const toast = useRef();

  useEffect(() => {
    toast.current.style.opacity = 1;
    setTimeout(() => {
      toast.current.style.opacity = 0;
    }, 2000)
    const timer = setTimeout(() => {
      closeToast();
    }, time);
    // return () => clearTimeout(timer);
  }, [message]);

  const styleOuter = {
    position: "fixed",
    bottom: "24px",
    width: "384px",
    zIndex: "9999999999",
    opacity: 1,
    transition: "opacity 1s"
  }

  const style = {
    backgroundColor: type === "error" ? "#e74133" : "#242627",
    width: "90%",
    margin: "0 auto",
    color: "#fff",
    textAlign: "center",
    borderRadius: "5px",
    fontSize: "14px",
    height: "40px",
    lineHeight: "40px",
  }




  return (
    <div className={"toast-message"} ref={toast}>
      <div style={style}>
        <p>{message}</p>
      </div>
    </div>
  )
}

export default ToastMessageComponent;
