import { combineReducers, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import sessionStorage from "redux-persist/lib/storage/session"; // 세션 스토리지 임포트
import { productListReducer } from "./redux/reducers/productList.js";
import { mainMenuReducer } from "./redux/reducers/mainMenu.js";
import { userInfoReducer } from "./redux/reducers/userInfoReducer.js";

// 로컬 스토리지에 저장될 리듀서
const productPersistConfig = {
  key: "productList",
  storage,
};

const mainMenuPersistConfig = {
  key: "mainMenu",
  storage,
};

// 세션 스토리지에 저장될 리듀서
const userInfoPersistConfig = {
  key: "userInfo",
  storage: sessionStorage, // 세션 스토리지 설정
};

// 각각의 리듀서에 persistReducer 적용
const rootReducer = combineReducers({
  productList: persistReducer(productPersistConfig, productListReducer),
  mainMenu: persistReducer(mainMenuPersistConfig, mainMenuReducer),
  userInfo: persistReducer(userInfoPersistConfig, userInfoReducer), // 세션 스토리지에 저장
});

export const store = createStore(rootReducer);
export const persistor = persistStore(store);