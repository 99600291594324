import axios from 'axios';
import banner_btn_arrow from "../images/banner-btn-arrow.png";

import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/MainBannerView.sass"


const MainBannerView = () => {
  const [bannerList, setBannerList] = useState([]);
  const [smallBannerList, setSmallBannerList] = useState([]);
  const location = useLocation();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay:true,
    autoplaySpeed: 3000,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    accessibility: false,
    arrows: false
  }

  const getMainBanner = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/banner/all`
    );
    setBannerList(response.data.mainBannerList);
  }
  useEffect(() => {
    getMainBanner();
    getSmallBanner();
  }, []);

  const getSmallBanner = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/ad-banner/list`
    );
    setSmallBannerList(response.data.list);
  }

  return (
    <div className="main-banner-view-wrap">
      <div className="main-banner">
        <Slider {...settings}>
          {
            bannerList && bannerList.map((item, index) => (
              <div key={index}>
                <div className="banner-content" style={{backgroundImage: `url(${process.env.REACT_APP_API_URL}/image/banner/${item.imageFile})`}}>
                  <h2>{item.bannerTitle}</h2>
                  <p>{item.bannerContent}</p>
                  <div className="button-area">
                    {
                      item.buttons.map((button, index) => (
                        <span key={index} className="banner-btn">
                              <span>{button.buttonTitle}</span>
                              <img src={banner_btn_arrow} alt="arrow_img"/>
                            </span>
                      ))
                    }
                  </div>
                </div>
              </div>
            ))
          }
        </Slider>
      </div>
      <div className="main-button-area">
        {
          smallBannerList && smallBannerList.map((item, index) => {
            return <div className="small-banner-item" key={index}>
              <a href={item.adBannerLinkUrl} target="_blank" key={index}>
                <img src={`${process.env.REACT_APP_API_URL}/image/banner/${item.adBannerImg}`} alt="banner_img"/>
              </a>
            </div>
          })
        }
      </div>
    </div>
  );
}

export default MainBannerView;
