import HeaderComponent from "../components/HeaderComponent";
import not_found from "../images/not-found.svg";
import "../styles/NotfoundPage.sass";
import {Link} from "react-router-dom";
const NotfoundPage = () => {
  return (
    <div className="not-found-page-wrap">
      <HeaderComponent/>
      <div className="not-found-page">
        <img src={not_found} alt="not_found"/>
        <h1>페이지가 존재하지 않습니다</h1>
        <Link className="go-to-main" to="/">메인페이지로 이동</Link>
      </div>
    </div>
  );
}
export default NotfoundPage;