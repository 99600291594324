import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import HeaderComponent from "../components/HeaderComponent";
import "../styles/PaymentSuccessView.sass";
import { useSelector } from "react-redux";
const PaymentSuccessView = () => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [responseData, setResponseData] = useState(null);
  const accessToken = useSelector((state) => state.userInfo.accessToken);
  useEffect(() => {
    async function confirm() {
      const storedPaymentData = JSON.parse(localStorage.getItem("paymentData"));
      const requestData = {
        orderId: searchParams.get("orderId"),
        amount: searchParams.get("amount"),
        paymentKey: searchParams.get("paymentKey"),
      };
      try {

        const check = checkValues(storedPaymentData, requestData);
        if (check) {
          requestData.orderName = storedPaymentData.orderName;
          requestData.memberSeq = storedPaymentData.memberSeq;
          requestData.items = storedPaymentData.items;
          requestData.addressSeq = storedPaymentData.addressSeq;
          requestData.type = storedPaymentData.type;
          if (requestData.type === "C") {
            requestData.furnitureType = storedPaymentData.furnitureType;
            requestData.furnitureForm = storedPaymentData.furnitureForm;
            requestData.keywordList = storedPaymentData.keywordList;
          }
          const res = await axios.post(
            process.env.REACT_APP_API_URL + "/order/payment/confirm", requestData,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          if (res.status === 200 && res.data.code === 0) {
            const encryptedSecretKey = "Basic " + btoa("test_gsk_docs_OaPz8L5KdmQXkzRz3y47BMw6:");
            const options = {
              method: 'GET',
              url: `https://api.tosspayments.com/v1/payments/${requestData.paymentKey}`,
              headers: {
                Authorization: encryptedSecretKey
              }
            };
            const paymentResponse = await axios.request(options);
            if (paymentResponse.status === 200) {
              setIsConfirmed(true);
              setResponseData(paymentResponse.data);
              localStorage.removeItem("paymentData");
            } else {
              throw new Error("결제 확인 중 문제가 발생했습니다. ");
            }
          } else {
            throw new Error("결제 승인 요청이 실패했습니다.");
          }
        } else {
          throw new Error("결제 정보 인증 데이터와 저장된 결제 데이터가 다릅니다.");
        }
      } catch (error) {
        console.error(error);
        alert(error.message);
        localStorage.removeItem("paymentData");
        await handlePaymentError(requestData);
        navigate(`/payment/fail?code=${error.code}&message=${error.message}`);
      }
    }

    confirm();
  }, [searchParams, navigate]);

  async function handlePaymentError(requestData) {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/order/${requestData.orderId}/${requestData.amount}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.data.code !== 0) {
        throw new Error("임시 데이터 삭제에 실패했습니다: => ", response.data);
      }
    } catch (error) {
      console.error(error);
      alert(error.message);
      navigate(`/payment/fail?code=${error.code}&message=${error.message}`);
    }
  }

  const checkValues = (paymentData, requestData) => {
    if (paymentData.orderId !== requestData.orderId) {
      console.log('결제 정보 인증 데이터와 저장된 결제 데이터가 다릅니다(orderId)');
      console.log("payment data => ", paymentData);
      console.log("reqest data => ", requestData);
      return false;
    }

    if (paymentData.totalAmount !== parseInt(requestData.amount, 10)) {
      console.log('결제 정보 인증 데이터와 저장된 결제 데이터가 다릅니다(totalAmount)');
      console.log("payment data => ", paymentData);
      console.log("reqest data => ", requestData);
      return false;
    }
    return true;
  }

  const onClickOrderHistory = () => {
    navigate(`/mypage/orders`);
  }

  return (
    <div className="payment-success-view-wrap">
      <HeaderComponent />
      {isConfirmed ? (
        <div className="payment-success-content">
          <div className="icon"></div>
          <h1>결제가 완료되었습니다</h1>
          <p>
            결제가 완료되었습니다.
            <br />
            주문내역에서 현황을 확인해 주세요
          </p>
          <button onClick={() => onClickOrderHistory()}>주문내역 확인</button>
        </div>) : (
        <div className="payment-success-content">
          <div className="icon"></div>
          <h1>결제 진행중입니다.</h1>
        </div>
      )}

    </div>
  );
};

export default PaymentSuccessView;
