import * as THREE from "three";
import { OrbitControls } from "@react-three/drei";
import { useThree } from "@react-three/fiber";
import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import { useInitVar } from "./FurnitureView";
import gsap from "gsap";

const CameraControls = forwardRef(
  (
    {
      position,
      target,
      isDragging,
      minDistance = 10,
      maxDistance = 100,
      minPan = new THREE.Vector3(-8, 1, -4),
      maxPan = new THREE.Vector3(8, 12, 4),
    },
    ref
  ) => {
    const { camera } = useThree();
    const controlRef = useRef(null);
    const { setCameraControls } = useInitVar();

    useImperativeHandle(ref, () => {}, []);

    useEffect(() => {
      var _v = new THREE.Vector3();
      controlRef.current.addEventListener("change", function () {
        _v.copy(this.target);
        this.target.clamp(minPan, maxPan);
        _v.sub(this.target);
        camera.position.sub(_v);
      });
    }, []);
    useEffect(() => {
      camera.position.set(position.x, position.y, position.z);
      controlRef.current.target.set(target.x, target.y, target.z);
      setCameraControls(controlRef.current);
    }, [controlRef.current]);

    function cameraAnimate() {
      if (!position || !target) {
        return;
      }
      if (controlRef.current) {
        gsap.timeline().to(camera.position, {
          duration: 0.4,
          repeat: 0,
          x: position.x,
          y: position.y,
          z: position.z,
          ease: "power3.Out",
        });

        gsap.timeline().to(controlRef.current.target, {
          duration: 0.4,
          repeat: 0,
          x: target.x,
          y: target.y,
          z: target.z,
          ease: "power3.Out",
        });
      }
    }

    useEffect(() => {
      cameraAnimate();
    }, [target, position]);

    return (
      <OrbitControls
        ref={controlRef}
        enabled={!isDragging}
        minDistance={minDistance}
        maxDistance={maxDistance}
        makeDefault
      />
    );
  }
);

export { CameraControls };
