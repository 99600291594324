import "../../styles/module/FurnitureKeywordSelectComponent.sass"
import {useEffect, useState} from "react";
import axios from "axios";
const FurnitureKeywordSelectComponent = ({onSelectFurntureKeyword}) => {
  const [keywordList, setKeywordList] = useState([]);
  const [selectedKeywordList, setSelectedKeywordList] = useState([]);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/product/keyword/list`).then(
      (res) => {
        setKeywordList(res.data);
      }
    ).catch((err) => {
      console.log(err);
    });
  }, []);

  return (
    <div className="furniture-keyword-select-comp-wrap">
      <h2>관련 키워드를 선택해주세요</h2>
      <p>선택한 키워드는 첫 세팅을 위해 사용되며<br/>
        이후 자유롭게 선택이 가능합니다.</p>
      <div className="furniture-keyword-select-list">
        {
          keywordList.map((keyword) => {
            return (
              <div className={"furniture-keyword "+(selectedKeywordList.includes(keyword.productKeywordSeq)?"selected":"")} key={keyword.keywordSeq} onClick={() => {
                if(selectedKeywordList.includes(keyword.productKeywordSeq)) {
                  setSelectedKeywordList(selectedKeywordList.filter(seq => seq !== keyword.productKeywordSeq));
                } else {
                  setSelectedKeywordList([...selectedKeywordList, keyword.productKeywordSeq]);
                }
              }}>
                <h2>{keyword.keyword}</h2>
              </div>
            )
          })
        }
      </div>
      <button 
        className="select-complete-btn" 
        onClick={() => onSelectFurntureKeyword(selectedKeywordList)}
        disabled={selectedKeywordList.length === 0}
      >
        선택완료
      </button>    </div>
  )
}
export default FurnitureKeywordSelectComponent;
