import "../styles/MyPageMenuComponent.sass"
import close_btn from "../images/close-btn.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mainMenuSetVisible } from "../redux/actions/mainMenu";
import { userAccessTokenSet, userInfoSet } from "../redux/actions/userInfo";
const MyPageMenuComponent = ({ visible }) => {
  const dispatch = useDispatch();
  const [isLogin, setIsLogin] = useState(true);
  const [loginUser, setLoginUser] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const accessToken = useSelector((state) => state.userInfo.accessToken);
  const loginInfo = useSelector((state) => state.userInfo.userInfo);

  useEffect(() => {
    dispatch(mainMenuSetVisible(false));
  }, [location])

  useEffect(() => {
    if (accessToken === null || accessToken === undefined || accessToken === '') {
      setIsLogin(false);
    } else {
      setIsLogin(true);
      setLoginUser(loginInfo);
    }
    console.log("user info => ", loginUser);
  }, [visible]);
  const onClickCloseBtn = () => {
    dispatch(mainMenuSetVisible(false))
  }
  const onClickLogoutBtn = () => {
    localStorage.removeItem('loginUser');
    localStorage.removeItem('product_wishlist');
    localStorage.removeItem('module_wishlist');
    localStorage.removeItem('persist:root');
    localStorage.removeItem('kakao_token');
    localStorage.removeItem('models');
    dispatch(userAccessTokenSet(null))
    dispatch(userInfoSet(null))
    dispatch(mainMenuSetVisible(false))
    setIsLogin(false);
    navigate("/")
  }
  return (
    <div className={"mypage-menu-wrap " + (visible ? "show" : "")}>
      {/*<div className={"mypage-menu-wrap show"}>*/}
      <div className="mypage-menu">
        <button className="mypage-close-btn" onClick={onClickCloseBtn}>
          <img src={close_btn} alt="close_btn" />
        </button>
        {!isLogin ? <div className="mypage-login-btn-area">
          <h1>로그인을 진행해주세요</h1>
          <Link to="/login" className="mypage-login-btn">사잇 로그인하기</Link>
        </div> : ""}
        {isLogin ? <div className="mypage-menu-list-wrap">
          <div className="mypage-user-info">
            <h1><span>{loginUser ? loginUser.name : ""}</span> <span>님</span></h1>
            <div className="mypage-user-button-area">
              <Link to="/customfurniture" className="mypage-user-button">나의 가구</Link>
              <Link to="/mypage/cart" className="mypage-user-button">장바구니</Link>
            </div>
          </div>
          <div className="mypage-menu-list">
            <Link className="mypage-menu-item" to="/mypage/info">회원 정보</Link>
            <Link className="mypage-menu-item" to="/mypage/addressbook">배송지 정보</Link>
            <Link className="mypage-menu-item" to="/mypage/wishlist">위시리스트</Link>
            <Link className="mypage-menu-item" to="/mypage/reviews">나의 리뷰</Link>
            <Link className="mypage-menu-item" to="/mypage/orders">주문내역</Link>
          </div>
        </div> : ""}
        {isLogin ? <div className="mypage-sign-out-btn-area">
          <button to="/signout" onClick={onClickLogoutBtn}>로그아웃</button>
          <span>|</span>
          <Link to="/member/withdrawal">탈퇴하기</Link>
        </div> : ""}
      </div>
    </div>
  );
}

export default MyPageMenuComponent;