import axios from 'axios';
import {useEffect, useState} from "react";
import "../styles/ProductFilterComponent.sass";
const ProductFilterComponent = ({onChangeKeywordFilter,onChangeSpaceFilter,onChangeSortFilter, similarKeyword = null}) => {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const usageResponse = await axios.get(process.env.REACT_APP_API_URL + '/product/keyword/list');
        setUsageFilterList(usageResponse.data);
        
        const spaceResponse = await axios.get(process.env.REACT_APP_API_URL + '/product/space/list');
        setSpaceFilterList(spaceResponse.data);

        if (similarKeyword) {
          const similarKeywordItem = usageResponse.data.find(item => item.productKeywordSeq === similarKeyword);
          if (similarKeywordItem) {
            setSelectedUsageFilter(similarKeywordItem.productKeywordSeq);
            onChangeKeywordFilter(similarKeywordItem.productKeywordSeq);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [similarKeyword, onChangeKeywordFilter]);

  const [usageFilterList, setUsageFilterList] = useState([]);
  const [spaceFilterList, setSpaceFilterList] = useState([]);
  const [selectedUsageFilter, setSelectedUsageFilter] = useState(0);
  const [selectedSpaceFilter, setSelectedSpaceFilter] = useState(0);
  const [selectedSortFilter, setSelectedSortFilter] = useState("popular,desc");
  const onChangeProductUsage = (e) => {
    setSelectedUsageFilter(e.target.value);
    onChangeKeywordFilter(e.target.value);
  }
  const onChangeProductSpace = (e) => {
    setSelectedSpaceFilter(e.target.value);
    onChangeSpaceFilter(e.target.value);
  }
  const onChangeProductSort = (e) => {
    setSelectedSortFilter(e.target.value);
    onChangeSortFilter(e.target.value);
  }

  return (
    <div className="product-filter-area">
      <select className="product-filter-usage" value={selectedUsageFilter} onChange={onChangeProductUsage}>
        <option value="0">사용용도별</option>
        {
          usageFilterList.map((item, index) => {
            return <option key={index} value={item.productKeywordSeq}>{item.keyword}</option>
          })
        }
      </select>
      <select className="product-filter-space" value={selectedSpaceFilter} onChange={onChangeProductSpace}>
        <option value="0">공간별</option>
        {
          spaceFilterList.map((item, index) => {
            return <option key={index} value={item.productSpaceSeq}>{item.productSpaceName}</option>
          })
        }
      </select>
      <select className="product-filter-sort" value={selectedSortFilter} onChange={onChangeProductSort}>
        <option value="popular,desc">인기순</option>
        <option value="price,asc">낮은가격순</option>
        <option value="price,desc">높은가격순</option>
      </select>
    </div>
  )
}

export default ProductFilterComponent;