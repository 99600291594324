import "../styles/AllReviewsView.sass";
import axios from 'axios';
import HeaderComponent from "../components/HeaderComponent";
import {useEffect, useState} from "react";
import { useParams } from 'react-router-dom';
import AllReviewsListComponent from "../components/AllReviewsListComponent";


const AllReviewsView = () => {
  const { productSeq } = useParams();
  const [floatingHeader, setFloatingHeader] = useState(false);
  const [productData, setProductData] = useState(null)
  useEffect(() => {
    window.scrollTo(0, 0);
    if(productSeq === undefined) return;
    axios.get(process.env.REACT_APP_API_URL + '/product/'+productSeq).then((response) => {
      setProductData(response.data);
    }).catch((err) => {
      console.log(err);
    })
  },[productSeq]);

  window.addEventListener('scroll', () => {
    if(window.scrollY > 0 && !floatingHeader) {
      setFloatingHeader(true);
    } else if(window.scrollY === 0 && floatingHeader){
      setFloatingHeader(false);
    }
  })  

  return (
    <div className="product-view-wrap">
      <div className={"product-view-header "+(floatingHeader?"floating":"")}>
        <HeaderComponent isMainView={false}/>
        <h1>
          {productData ? productData.name : "Loading..."}
        </h1>

      </div>
      <div className="product-view-content">
        <AllReviewsListComponent
          productSeq={productSeq}
        />
      </div>
    </div>
  );
}

export default AllReviewsView;