import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import '../styles/ReviewWriteView.sass';
import axios from 'axios';
import HeaderComponent from '../components/HeaderComponent';

const ReviewWriteView = () => {
  const [reviewText, setReviewText] = useState('');
  const [title, setTitle] = useState('');
  const [reviewImage, setReviewImage] = useState(null);
  const [loginUser, setLoginUser] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const { productSeq, orderSeq } = useParams(); // 
  const navigate = useNavigate();
  const [isReviewCompleted, setIsReviewCompleted] = useState(false);
  const accessToken = useSelector((state) => state.userInfo.accessToken);
  const loginInfo = useSelector((state) => state.userInfo.userInfo);


  useEffect(() => {
    checkUserAndAuthorization();
  }, [orderSeq, productSeq]);

  const checkUserAndAuthorization = async () => {
    try {
      const user = loginInfo;
      if (!user) {
        alert("로그인한 유저만 접근 가능합니다.");
        navigate("/");
        return;
      }
      setLoginUser(user);

      const ownershipResponse = await axios.get(`${process.env.REACT_APP_API_URL}/order/owner`, {
        params: { orderSeq, memberSeq: user.memberSeq },
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      if (!ownershipResponse.data === true) {
        alert("해당 결제가 존재하지 않거나 권한이 없습니다.");
        navigate("/");
        return;
      }
      const orderContainsProduct = await axios.get(`${process.env.REACT_APP_API_URL}/order/product-check`, {
        params: { orderSeq, productSeq },
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }

      });
      if (!orderContainsProduct.data === true) {
        alert("존재하지 않는 결제 상품입니다.");
        navigate("/");
        return;
      }
      const reviewResponse = await axios.get(`${process.env.REACT_APP_API_URL}/review/exists`, {
        params: { orderSeq, productSeq, memberSeq: user.memberSeq },
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      });
      if (!reviewResponse.data === false) {
        setIsReviewCompleted(true);
        alert('이미 작성한 리뷰입니다.');
        navigate('/');
      }
    } catch (error) {
      console.error('Error checking user authorization or review status', error);
      alert("문제가 발생했습니다. 메인 화면으로 돌아갑니다.")
      navigate("/")
    }
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleReviewChange = (e) => {
    setReviewText(e.target.value);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file === null) return;
    setPreviewImage(URL.createObjectURL(file));
    setReviewImage(file);
  };

  const handleSubmit = async (e) => {
    const loginUser = loginInfo;
    if (loginUser === null || loginUser === undefined || loginUser === '') return;
    e.preventDefault();
    const reviewData = {
      productSeq: productSeq,
      title: title,
      content: reviewText,
      memberSeq: loginUser.memberSeq,
      status: "N",
      orderSeq: orderSeq
    };
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/review`, reviewData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${accessToken}`
        },
      });
      if (reviewImage) {
        const success = await uploadReviewImage(response.data.product_review_seq);
        if (success) {
          alert("리뷰가 등록되었습니다.");
        } else {
          alert("리뷰가 이미지 업로드 중 문제가 발생했습니다. 관리자에게 문의하세요.");
        }
      } else {
        alert("리뷰가 등록되었습니다.")
      }
      navigate("/mypage/orders");

    } catch (error) {
      console.error('Error submitting review', error);
    }
  };

  const uploadReviewImage = async (productReviewSeq) => {
    try {
      let imageData = new FormData();
      imageData.append('productReviewSeq', productReviewSeq);
      imageData.append('reviewImage', reviewImage);
      const imageResponse = await axios.post(`${process.env.REACT_APP_API_URL}/review/image`, imageData, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization': `Bearer ${accessToken}`

        },
      });
      return true;
    } catch (error) {
      console.error('Error uploading image', error);
      return false;
    }
  };

  return (
    <div className="product-view-wrap">
      <HeaderComponent isMainView={false} />
      <div className="review-component">
        <h2>구매 후기 작성</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="제목을 입력해주세요"
            value={title}
            onChange={handleTitleChange}
            required
          />
          <textarea
            placeholder="후기를 작성해주세요"
            value={reviewText}
            onChange={handleReviewChange}
            required
          ></textarea>
          <div className="image-upload">
            <label htmlFor="reviewImage">이미지 업로드</label>
            <input type="file" id="reviewImage" accept="image/*" onChange={handleImageChange} />
            {previewImage && <img src={previewImage} alt="Preview" />}
          </div>
          <button type="submit">리뷰 작성</button>
        </form>
      </div>
    </div>
  );
};

export default ReviewWriteView;
