import {
  PRODUCT_LIST_SET_CATEGORY_SEQ,
  PRODUCT_LIST_SET_LIST_DIVISION,
  PRODUCT_LIST_SET_TITLE,
  PRODUCT_LIST_SET_SELECTED_PRODUCT_SEQ,
  PRODUCT_LIST_SET_KEYWORD_SEQ
} from "../actions/productList";

const initialState = {
  title: "",
  listDivision: "",
  categorySeq: "",
  selectedProductSeq: 0,
  keywordSeq:""
};

export const productListReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_LIST_SET_TITLE:
      return {
        ...state,
        title: action.title
      };
    case PRODUCT_LIST_SET_LIST_DIVISION:
      return {
        ...state,
        listDivision: action.listDivision
      };
    case PRODUCT_LIST_SET_CATEGORY_SEQ:
      return {
        ...state,
        categorySeq: action.categorySeq
      };
      
    case PRODUCT_LIST_SET_SELECTED_PRODUCT_SEQ:
      return {
        ...state,
        selectedProductSeq: action.productSeq
      };
    case PRODUCT_LIST_SET_KEYWORD_SEQ:
      return {
        ...state,
        keywordSeq: action.keywordSeq,
      };
    default:
      return state;
  }
};