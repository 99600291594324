import MainBannerView from "./MainBannerView";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainView from "./MainView";
import ProductListView from "./ProductListView";
import ModuleMainView from "./ModuleMainView";
import { useSelector } from "react-redux";
import ProductDetailView from "./ProductDetailView";
import LoginView from "./LoginView";
import MemberJoinView from "./MemberJoinView";
import { useEffect } from "react";
import CategoryView from "./CategoryView";
import MyPageMenuComponent from "../components/MyPageMenuComponent";
import MemberInfoView from "./MemberInfoView";
import PurchaseListView from "./PurchaseListView";
import MemberAddressView from "./MemberAddressView";
import MemberWishlistView from "./MemberWishlistView";
import MemberCartView from "./MemberCartView";
import NotfoundPage from "./NotfoundPage";
import EmailFindView from "./EmailFindView";
import FindPasswordView from "./FindPasswordView";
import KakaoAuthView from "./KakaoAuthView";
import NaverAuthView from "./NaverAuthView";
import MemberSNSJoinView from "./MemberSNSJoinView";
import GoogleAuthView from "./GoogleAuthView";
import CustomFurnitureView from "./CustomFurnitureView";
import ReviewWriteView from "./ReviewWriteView";
import AllReviews from "./AllReviewsView";
import ReviewDetailVeiw from "./ReviewDetailView";
import MyReviewsView from "./MyReviewsView";
import ReviewEditView from "./ReviewEditView"
import OrderCheckView from "./OrderCheckView";
import PaymentSuccessView from "./PaymentSuccessView";
import { CheckoutPage } from "./CheckoutView";
import PaymentFailView from "./PaymentFailView";
import OrderHistoryView from "./OrderHistoryView"
import OrderDetailView from "./OrderDetailView"

const ViewContainer = () => {

  useEffect(() => {
    const handleResize = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const mainMenuVisible = useSelector(state => state.mainMenu.visible);
  return (
    <div>
      <BrowserRouter>
        <div className="app">
          <div className="left-side">
            <MainBannerView />
          </div>
          <div className="right-side">
            <Routes>
              <Route path="/" element={<MainView />} />
              <Route path="/find-email" element={<EmailFindView />} />
              <Route path="/find-pw" element={<FindPasswordView />} />
              <Route path="/product" element={<ProductListView />} />
              <Route path="/custom" element={<ModuleMainView />} />
              <Route path="/product/detail/:productSeq" element={<ProductDetailView />} />
              <Route path="/login" element={<LoginView />} />
              <Route path="/join" element={<MemberJoinView />} />
              <Route path="/sns-join" element={<MemberSNSJoinView />} />
              <Route path="/categories" element={<CategoryView />} />
              <Route path="/mypage/info" element={<MemberInfoView />} />
              <Route path="/mypage/cart" element={<MemberCartView />} />
              <Route path="/mypage/purchaselist" element={<PurchaseListView />} />
              <Route path="/mypage/cardinfo" element={<CategoryView />} />
              <Route path="/mypage/addressbook" element={<MemberAddressView />} />
              <Route path="/mypage/wishlist" element={<MemberWishlistView />} />\
              <Route path="/mypage/reviews" element={<MyReviewsView />} />\
              <Route path="/mypage/orders" element={<OrderHistoryView />} />
              <Route path="/customfurniture" element={<CustomFurnitureView />} />\
              <Route path="/kakao-auth" element={<KakaoAuthView />} />
              <Route path="/naver-auth" element={<NaverAuthView />} />
              <Route path="/google-auth" element={<GoogleAuthView />} />
              <Route path="/review/:productSeq/:orderSeq" element={<ReviewWriteView />} />
              <Route path="/all-reviews/:productSeq" element={<AllReviews />} />
              <Route path="/review/detail/:productReviewSeq" element={<ReviewDetailVeiw />} />
              <Route path="/review/edit/:productReviewSeq" element={<ReviewEditView />} />
              <Route path="/order" element={<OrderCheckView />} />
              <Route path="/order/detail/:orderSeq" element={<OrderDetailView />} />
              <Route path="/payment/success" element={<PaymentSuccessView />} />
              <Route path="/payment/fail" element={<PaymentFailView />} />
              <Route path="/checkout" element={<CheckoutPage />} />
              <Route path="*" element={<NotfoundPage />} />
            </Routes>
            <MyPageMenuComponent visible={mainMenuVisible} />
          </div>
        </div>
        {/*{mainMenuVisible?<CategoryMenuComponent/>:""}*/}
      </BrowserRouter>
    </div>
  );
}

export default ViewContainer;