import "../styles/ProductDetailView.sass";
import HeaderComponent from "../components/HeaderComponent";
import { useEffect, useState } from "react";
import axios from "axios";
import ProductOptionPopup from "../components/ProductOptionPopup";
import wishlist_active from "../images/wishlist-active.png";
import wishlist_deactive from "../images/wishlist-deactive-outline.png";
import AddToCartPopupComponent from "../components/AddToCartPopupComponent";
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";


const ProductDetailView = () => {
  const { productSeq } = useParams();
  const [wishlist, setWishlist] = useState(false);
  const accessToken = useSelector((state) => state.userInfo.accessToken);
  const loginInfo = useSelector((state) => state.userInfo.userInfo);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (productSeq === undefined) return;
    axios.get(process.env.REACT_APP_API_URL + '/product/' + productSeq).then((response) => {
      setProductDetail(response.data);
    }).catch((err) => {
      console.log(err);
    })

    axios.get(process.env.REACT_APP_API_URL + '/review/recent/' + productSeq).then((response) => {
      setReviewList(response.data);
    }).catch((err) => {
      console.log(err);
    })

    const product_wishlist = localStorage.getItem('product_wishlist');
    if (product_wishlist !== null && product_wishlist !== undefined && product_wishlist !== '') {
      const wishlist = JSON.parse(product_wishlist);
      if (wishlist.includes(productSeq)) {
        setWishlist(true);
      }
    }
  }, [productSeq]);

  const [productDetail, setProductDetail] = useState({});
  const [reviewList, setReviewList] = useState([]);

  const [isPurchasePopupOpen, setIsPurchasePopupOpen] = useState(false);
  const [purchaseTarget, setPurchaseTarget] = useState("purchase");

  const [addCartPopupOpen, setAddCartPopupOpen] = useState(false);
  const onClosePopup = () => {
    document.body.style.overflow = 'unset';
    setIsPurchasePopupOpen(false)
  }

  const onOpenPopup = (target) => {
    const user = loginInfo;
    if (!user) {
      alert("로그인한 유저만 접근 가능합니다.");
      return;
    }
    setPurchaseTarget(target)
    setIsPurchasePopupOpen(true)
    document.body.style.overflow = 'hidden';
  }
  const onClickWishlist = () => {
    if (loginInfo === null || loginInfo === undefined || loginInfo === '') return;
    const memberSeq = loginInfo.memberSeq;
    const product_wishlist = localStorage.getItem('product_wishlist');
    if (product_wishlist !== null && product_wishlist !== undefined && product_wishlist !== '') {
      const wishlist = JSON.parse(product_wishlist);
      if (wishlist.includes(productSeq)) {
        const newWishlist = wishlist.filter((item) => item !== productSeq);
        const product = productDetail;
        localStorage.setItem('product_wishlist', JSON.stringify(newWishlist));
        setWishlist(false);
        product.wishlistCnt -= 1;
        setProductDetail(product);
        axios.delete(process.env.REACT_APP_API_URL + '/wishlist/product', {
          data: {
            memberSeq: memberSeq,
            productSeq: productSeq
          },
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        }).then((response) => {
          console.log(response);
        }).catch((error) => {
          console.log(error);
        })
      } else {
        wishlist.push(productSeq);
        localStorage.setItem('product_wishlist', JSON.stringify(wishlist));
        setWishlist(true);
        const product = productDetail;
        product.wishlistCnt += 1;
        setProductDetail(product);
        axios.post(process.env.REACT_APP_API_URL + '/wishlist/product', {
          memberSeq: memberSeq,
          productSeq: productSeq
        },
          {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          }).then((response) => {
          }).catch((error) => {
            console.log(error);
          })
      }
    } else {
      localStorage.setItem('product_wishlist', JSON.stringify([productSeq]));
      setWishlist(true);
      const product = productDetail;
      product.wishlistCnt += 1;
      setProductDetail(product);
      axios.post(process.env.REACT_APP_API_URL + '/wishlist/product', {
        memberSeq: memberSeq,
        productSeq: productSeq
      },
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        }
      ).then((response) => {
      }).catch((error) => {
        console.log(error);
      })
    }
  }

  const onAddCart = (cartItem) => {
    setIsPurchasePopupOpen(false);

    cartItem.memberSeq = loginInfo.memberSeq;
    cartItem.width = productDetail.width;
    cartItem.height = productDetail.height;
    cartItem.depth = productDetail.depth;
    axios.post(`${process.env.REACT_APP_API_URL}/cart`, cartItem, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    }).then((response) => {
      setAddCartPopupOpen(true);
    }).catch((error) => {
      console.log(error);
    })
  }

  const onCloseAddCartPopup = () => {
    document.body.style.overflow = 'unset';
    setAddCartPopupOpen(false);
  }

  return (
    <div className="product-detail-wrap">
      <HeaderComponent />
      <div className="product-detail-content">
        <div className="product-image" style={{ backgroundImage: `url('${process.env.REACT_APP_API_URL}/image/product/${productDetail.thumbnail}')` }}></div>
        <div className="product-basic-info">
          <h2 className="product-title">{productDetail.name}</h2>
          <div className="product-specs">
            <span className="spec-size">{productDetail.width}x{productDetail.height}x{productDetail.depth}cm</span>
            <span className="spec-changeable">
              {productDetail.sizeChangeable ? <span className="size-changeable">사이즈</span> : ""}
              {productDetail.colorChangeable ? <span className="color-changeable">컬러</span> : ""}
              {productDetail.textureChangeable ? <span className="texture-changeable">재질</span> : ""}
            </span>
          </div>
          <p className="product-price">{productDetail.price !== undefined ? productDetail.price.toLocaleString() : 0}원</p>
          <div className="product-review-and-like-count">
            <span className="review-cnt"><b>리뷰</b><span>{productDetail.reviewCnt}</span></span>
            <span className="wishlist-cnt">
              <span onClick={onClickWishlist}>
                {
                  wishlist ?
                    <img src={wishlist_active} alt="wishlist_active" width={20} /> :
                    <img src={wishlist_deactive} alt="wishlist_deactive" width={20} />
                }
              </span>
              <span>{productDetail.wishlistCnt}</span>
            </span>
          </div>
        </div>
        <div className="product-purchase-btns">
          <button className="purchase" onClick={() => { onOpenPopup("purchase") }}>구매하기</button>
          <button className="add-to-cart" onClick={() => { onOpenPopup("cart") }}>장바구니</button>
        </div>
        <div className="product-detail" dangerouslySetInnerHTML={{ __html: productDetail.detailContent }}>
        </div>
      </div>
      <div className="product-review-area">
        <div className="review-header">
          <h1>구매 후기</h1>
          <Link to={`/all-reviews/${productSeq}`} className="all-reviews-button">전체 후기 보러 가기</Link>
        </div>
        {
          reviewList.map((review, index) => {
            return (
              <div className="product-review-item" key={index}>
                <div className="review-img-wrap">
                  {
                    review.reviewImgList.map((img, index) => {
                      return (
                        <div className="review-img" key={index} style={{ backgroundImage: `url('${process.env.REACT_APP_API_URL}/image/review/${img}')` }}></div>
                      )
                    })
                  }
                </div>
                <h2 className="review-title">{review.title}</h2>
                <p className="review-content">
                  {review.content.length > 30 ? review.content.substring(0, 30) + '...' : review.content}
                </p>
                <h3 className="review-author-name">{review.author}</h3>
              </div>
            )
          })
        }
      </div>
      <ProductOptionPopup target={purchaseTarget}
        image={productDetail.thumbnail}
        productName={productDetail.name}
        open={isPurchasePopupOpen}
        productSeq={productDetail.productSeq}
        productPrice={productDetail.price}
        sizeChangeable={productDetail.sizeChangeable}
        colorChangeable={productDetail.colorChangeable}
        textureChangeable={productDetail.textureChangeable}
        onClosePopup={onClosePopup}
        onClickAddCart={onAddCart} />
      {addCartPopupOpen ? <AddToCartPopupComponent onClosePopup={onCloseAddCartPopup} /> : ""}
    </div>
  );
}
export default ProductDetailView;