import HeaderComponent from "../components/HeaderComponent";
import "../styles/MemberAddressView.sass";
import plus_btn from "../images/plus-btn.png";
import { useDaumPostcodePopup } from 'react-daum-postcode';
import {useEffect, useState} from "react";
import address_btn from "../images/address-btn.png";
import ToastMessageComponent from "../components/ToastMessageComponent";
import { useDispatch, useSelector } from "react-redux";



import axios from "axios";
const MemberAddressView = () => {
  const open = useDaumPostcodePopup("https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js");
  const [address, setAddress] = useState(null);
  const [addressName, setAddressName] = useState('');
  const [detailAddress, setDetailAddress] = useState('');
  const [zoneCode, setZoneCode] = useState('');

  const [toastMsg, setToastMsg] = useState(null);
  const [toastType, setToastType] = useState("info");

  const [addressEditMode, setAddressEditMode] = useState(null);

  const [editAddressSeq, setEditAddressSeq] = useState(null);
  const [addressNameError, setAddressNameError] = useState(false);
  const [addressError, setAddressError] = useState(false);
  const [detailAddressError, setDetailAddressError] = useState(false);

  const [addressList, setAddressList] = useState([]);

  const accessToken = useSelector((state) => state.userInfo.accessToken);
  const loginInfo = useSelector((state) => state.userInfo.userInfo);

  useEffect(() => {
    loadAddressList()
  }, []);
  const loadAddressList = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/member/address/list`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((res) => {
      setAddressList(res.data);
    }).catch((err) => {
      console.log(err);
    });
  }
  const initializeInputStates = () => {
    setAddress(null);
    setAddressName('');
    setDetailAddress('');
    setZoneCode('');
    setAddressError(false);
    setAddressNameError(false);
    setDetailAddressError(false);
  }
  const handleComplete = (data) => {
    setAddress(data.address);
    setZoneCode(data.zonecode);
  };

  const handleClick = () => {
    open({ onComplete: handleComplete });
  };

  const checkAddressValidation = () => {
    let result = true;
    if(addressName === undefined || addressName === null || addressName === ''){
      setAddressNameError(true);
      result = false;
    }
    else {
      setAddressNameError(false);
    }

    if(address === undefined || address === null || address === ''){
      setAddressError(true);
      result = false;
    }

    if(detailAddress === undefined || detailAddress === null || detailAddress === ''){
      setDetailAddressError(true);
      result = false;
    }
    else {
      setDetailAddressError(false);
    }

    return result;
  }
  const onClickAddAddress = () => {
    if(!checkAddressValidation()) return;
    const data = {
      memberSeq: loginInfo.memberSeq,
      title: addressName,
      address: address,
      detailAddress: detailAddress,
      zipcode: zoneCode,
    }
    axios.post(`${process.env.REACT_APP_API_URL}/member/address`, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
    })    
    .then((res) => {
      setToastMsg("주소지가 등록되었습니다.");
      setToastType("info");
      initializeInputStates();
      setAddressEditMode(null);
      data['memberAddressSeq'] = res.data.memberAddressSeq;
      setAddressList([...addressList, data]);
    }).catch((err) => {
      console.log(err);
      setToastMsg("주소지 등록에 실패했습니다.");
      setToastType("error");
    });
  }
  const onClickUpdateAddress = () => {
    if(!checkAddressValidation()) return;
    const data = {
      memberAddressSeq: editAddressSeq,
      memberSeq: loginInfo.memberSeq,
      title: addressName,
      address: address,
      detailAddress: detailAddress,
      zipcode: zoneCode,
    }
    axios
      .patch(`${process.env.REACT_APP_API_URL}/member/address`, data, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
      }) 
      .then((res) => {
        if (res.data && res.data.memberAddressSeq === editAddressSeq) {
          setToastMsg("주소지가 수정되었습니다.");
          setToastType("info");
          initializeInputStates();
          loadAddressList();
          setAddressEditMode(null);
        } else {
          throw new Error("수정에 실패했습니다."); 
        }
      })
      .catch((err) => {
        console.log(err);
        setToastMsg("주소지 수정에 실패했습니다.");
        setToastType("error");
      });
  };

  const onClickDeleteAddress = (address) => {
    if(window.confirm("주소를 삭제하시겠습니까?") === false) return;
    axios.delete(`${process.env.REACT_APP_API_URL}/member/address/${address.memberAddressSeq}`,  {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
    })
    .then((res) => {
      if(res.data && res.data.memberAddressSeq === address.memberAddressSeq){
      setToastMsg("주소지가 삭제되었습니다.");
      setToastType("info");
      setAddressList(addressList.filter((item) => item.memberAddressSeq !== address.memberAddressSeq));
      } else {
        throw new Error("삭제에 실패했습니다."); 
      }
    }).catch((err) => {
      setToastMsg("주소지 삭제에 실패했습니다.");
      setToastType("error");
    });
  }
  const onClickEditAddress = (address) => {
    setAddressEditMode("edit");
    setEditAddressSeq(address.memberAddressSeq);
    setAddressName(address.title);
    setAddress(address.address);
    setDetailAddress(address.detailAddress);
    setZoneCode(address.zipcode);
  }

  const onClickCancel = () => {
    setAddressEditMode(null);
    initializeInputStates();
  }

  return (
    <div className="member-address-view-wrap">
      <HeaderComponent />
      {!addressEditMode?<div className="member-address-view">
        <h1>주소록 관리</h1>
        <div className="address-list">
          {addressList.map((address, index) => {
            return (
              <div key={index} className="address-item">
                <div className="address-title">{address.title}</div>
                <div className="address-content">({address.zipcode}) {address.address} {address.detailAddress}</div>
                <div className="address-btns">
                  <button onClick={() => onClickEditAddress(address)}>수정</button>
                  <button onClick={() => onClickDeleteAddress(address)}>삭제</button>
                </div>
              </div>
            )
          })}
        </div>
        <button onClick={() => setAddressEditMode("new")} className="add-address-btn">
          <img src={plus_btn} alt="plus-btn" />
          <span>주소지 추가하기</span>
        </button>
      </div>:
        <div className="address-edit-wrap">
          <h1>주소지 {addressEditMode === 'new' ? '추가' : "수정"}</h1>
          <div className="address-edit-row">
            <p>주소지명</p>
            <input type="text" className={addressNameError ? "error" : ""} value={addressName}
                   onChange={(e) => setAddressName(e.target.value)}/>
            {addressNameError ? <p className="err-msg">주소지 명을 입력해주세요</p> : ""}
          </div>
          <div className="address-edit-row">
            <p>주소지</p>
            <button type="text" className={addressError ? "error" : ""}
                    onClick={handleClick}>{address ? address : "주소검색"}
              <img src={address_btn} alt="address_btn"/>
            </button>
            {addressError ? <p className="err-msg">주소지를 입력해주세요</p> : ""}
          </div>
          <div className="address-edit-row">
            <p>상세주소</p>
            <input type="text" className={detailAddressError ? "error" : ""} value={detailAddress}
                   onChange={(e) => setDetailAddress(e.target.value)}/>
            {detailAddressError ? <p className="err-msg">상세주소를 입력해주세요</p> : ""}
          </div>
          {addressEditMode === 'new' ?
            <button className="submit-address" onClick={onClickAddAddress}>등록하기</button> :
            <button className="submit-address" onClick={onClickUpdateAddress}>수정하기</button>
          }
          <button className="cancel-address" onClick={onClickCancel}>취소</button>
        </div>}
      {toastMsg ?
        <ToastMessageComponent message={toastMsg} type={toastType} time={3000} closeToast={() => {setToastMsg(null)}}/>:""}
    </div>
  );
}
export default MemberAddressView;