import * as THREE from "three";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useThree, Canvas, extend, invalidate } from "@react-three/fiber";
import {
  useGLTF,
  useFBX,
  Grid,
  Center,
  GizmoHelper,
  GizmoViewport,
  Billboard,
  Bvh,
} from "@react-three/drei";
import {
  EffectComposer,
  Selection,
  Outline,
  Select,
} from "@react-three/postprocessing";
import { CameraControls } from "./CameraControls";
import { Leva, useControls } from "leva";
import { easing, geometry } from "maath";
import { HexColorPicker } from "react-colorful";
import gsap from "gsap";
import { PopupUI } from "./PopupUI";
import { usePlace } from "./Place";
import { v4 as uuidv4 } from "uuid";
import { TexturePicker } from "./TexturePicker";
import { Environment } from "./Environment";
import { useGesture } from "@use-gesture/react";
import { isMobile } from "react-device-detect";
import { useDragState } from "./DragState";


extend(geometry);

//카메라 초기값
export function useInitVar() {
  const cameraX = 0;
  const cameraY = 6;
  const cameraZ = 40;
  const targetX = 0;
  const targetY = 6;
  const targetZ = 0;
  const cameraMinDistance = 10;
  const cameraMaxDistance = 100;
  const cameraMinPan = new THREE.Vector3(-8, 1, -4);
  const cameraMaxPan = new THREE.Vector3(8, 12, 4);
  const modelScale = new THREE.Vector3(10, 10, 10);
  const isControlHidden = true;
  const isPickerHidden = true;
  const isAxisHelperHidden = true;
  const isShowMapHidden = true;
  const [cameraControls, setCameraControls] = useState(null);
  return {
    cameraPosition: new THREE.Vector3(cameraX, cameraY, cameraZ),
    cameraTarget: new THREE.Vector3(targetX, targetY, targetZ),
    cameraMinDistance: cameraMinDistance,
    cameraMaxDistance: cameraMaxDistance,
    cameraMinPan: cameraMinPan,
    cameraMaxPan: cameraMaxPan,
    modelScale: modelScale,
    isControlHidden: isControlHidden,
    isPickerHidden: isPickerHidden,
    isAxisHelperHidden: isAxisHelperHidden,
    isShowMapHidden: isShowMapHidden,
    cameraControls: cameraControls,
    setCameraControls: setCameraControls,
  };
}

function Scene(props, ref) {
  const { enableClick } = props;

  const {
    cameraPosition,
    cameraTarget,
    cameraMinDistance,
    cameraMaxDistance,
    cameraMinPan,
    cameraMaxPan,
    isControlHidden,
    isPickerHidden,
    isAxisHelperHidden,
  } = useInitVar();

  useImperativeHandle(ref, () => ({
    resetCamera: () => {
      setCamPosition(cameraPosition);
      setTarget(cameraTarget);
    },
    addItem: (data) => {
      if (gearCount >= 20) {
        return { error: -1000, message: "가구를 더 추가 할 수 없습니다." };
      }
      clearSelect();
      const y = GetHeightOfGears();
      const initParams = {
        added: true,
        unitPosition: new THREE.Vector3(-1, y, 0),
      };
      addPlace(uuidv4(), data.id, data.name, data.path, initParams, data.price, data.image, data.furnitureType, data.furnitureForm, data.keywordList);
      return { error: 0 };
    },
    addItemsForInint : (data) => {
      if (gearCount >= 20) {
        return { error: -1000, message: "가구를 더 추가 할 수 없습니다." };
      }
      clearSelect();
    // 위치 값이 숫자인지 확인하고 변환
    const positionX = parseFloat(data.position[0]);
    const positionY = parseFloat(data.position[1]);
    const positionZ = parseFloat(data.position[2]);
      const y = GetHeightOfGears();

    const initParams = {
        added: true,
        unitPosition: new THREE.Vector3(-1, y, 0),
    };
      addPlace(uuidv4(), data.id, data.name, data.path, initParams, data.price, data.image, data.furnitureType, data.furnitureForm, data.keywordList);
      return { error: 0 };
    },
    saveData: () => {
      const data = { models: [] };
      furnitureDataToArray(data.models);
      return data;
    },
    resetItems : () => {
      resetPlace();
      return {error : 0};
    }
  }));

  const {
    gearCount,
    clearSelect,
    current,
    changeTexture,
    addPlace,
    GetHeightOfGears,
    furnitureDataToArray,
    resetPlace
  } = usePlace();
  const [camPosition, setCamPosition] = useState(cameraPosition);
  const [target, setTarget] = useState(cameraTarget);
  const [needUpdate, setNeedUpdate] = useState();
  const [isDragging, setIsDragging] = useState(false); 
  const setDragging = (b) => {
    setIsDragging(b);
  };
  const {
    backgroundColor,
    ambientLight,
    lightIntensity,
    grid,
    cameraRotation,
  } = useControls({
    backgroundColor: { value: "#f0f0f0", label: "배경색" },
    ambientLight: { value: 1, min: 0, max: 10, label: "AmbientLight" },
    lightIntensity: { value: 5, min: 0, max: 50, label: "Light" },
    grid: { value: true },
    cameraRotation: { value: true, label: "카메라회전" },
  });

  

  const { setPreload } = usePlace();
  useEffect(() => {
    return () => {
          resetPlace();
    };
  }, []);
  
  useEffect(() => {
    if (props.preload) {
      const preload = props.preload;
      setPreload(preload);
    }
  }, []);

  useLayoutEffect(() => {
    invalidate();
  }, [needUpdate]);

  return (
    <>
      <Leva
        hidden={isControlHidden}
        collapsed
        titleBar={{ position: { x: 0, y: 55 } }}
      />
      <meta
        name="viewport"
        content="width=device-width, user-scalable=no, minimum-scale=1.0, maximum-scale=1.0"
      ></meta>
      <Canvas
        shadows={"variance"}
        raycaster={{ params: { Line: { threshold: 0.15 } } }}
        flat
        gl={{ antialias: false }}
        dpr={[1, 1.5]}
        camera={{
          position: camPosition,
          target: target,
          fov: 25,
          near: 0.01,
          far: 2000,
        }}
        onPointerMissed={(e) => {
          clearSelect(e.timeStamp);
        }}
        onPointerUp={(e) => setDragging(false)}
      >
        <color attach="background" args={[backgroundColor]} />
        <ambientLight intensity={ambientLight} />
        <pointLight position={[10, 10, 10]} />
        <pointLight position={[-10, -10, -10]} intensity={0.5} />
        <spotLight
          position={[-10, 10, 10]}
          angle={0.2}
          intensity={2}
          castShadow
        />
        {grid && (
          <Grid
            sectionSize={5}
            cellSize={1}
            infiniteGrid
            fadeDistance={75}
            position={(0, -0.02, 0)}
            cellColor={"#6f6f6f"}
            sectionColor={"#9d4b4b"}
            followCamera={false}
          />
        )}
        <Bvh firstHitOnly>
          <Selection>
            <EffectComposer autoClear={false}>
              <Outline
                blur
                visibleEdgeColor="firebrick"
                hiddenEdgeColor="firebrick"
                edgeStrength={1000}
                width={500}
              />
            </EffectComposer>
            <FurnitureList
              setCamPosition={setCamPosition}
              setTarget={setTarget}
              setDragging={setDragging}
              needUpdate={() => setNeedUpdate(true)}
              enableClick={enableClick} 
            />
          </Selection>
        </Bvh>
        <Environment lightIntensity={lightIntensity} />
        {cameraRotation && (
          <CameraControls
            position={camPosition}
            target={target}
            minDistance={cameraMinDistance}
            maxDistance={cameraMaxDistance}
            minPan={cameraMinPan}
            maxPan={cameraMaxPan}
            isDragging={isDragging}
          />
        )}
        {!isAxisHelperHidden && (
          <GizmoHelper
            alignment="bottom-right"
            margin={[75, 75]}
            renderPriority={5}
          >
            <GizmoViewport axisHeadScale={1} />
          </GizmoHelper>
        )}
      </Canvas>
      {!isPickerHidden && <Picker />}
      {!isPickerHidden && (
        <TexturePicker enabled={current} onClick={changeTexture} />
      )}
    </>
  );
}

export function FurnitureList({
  setCamPosition,
  setTarget,
  setDragging,
  needUpdate,
  onHover,
  enableClick, 
}) {
  const { places: list, clearSelect } = usePlace();
  const { camera } = useThree();
  const { cameraPosition, cameraTarget } = useInitVar();
  //카메라 목표 갱신
  useEffect(() => {
    camera.position.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
    camera.target = cameraTarget;
    clearSelect();
    setCamPosition(camera.position);
    setTarget(cameraTarget);
  }, []);
  return (
    <group>
      {Array.from({ length: list.length }, (_, i) => (
        <Gear
          key={list[i].id}
          gear={list[i]}
          index={i}
          setCamPosition={setCamPosition}
          setTarget={setTarget}
          setDragging={setDragging}
          needUpdate={needUpdate}
          onHover={onHover}
          enableClick={enableClick} 
        />
      ))}
    </group>
  );
}

function Gear({
  gear,
  index,
  setDragging,
  needUpdate,
  onHover,
  enableClick
}) {
  const { isShowMapHidden } = useInitVar();
  const {
    addPlace,
    setGearCB,
    clearSelect,
    unitScale,
    isCollision,
    setGearCenterProps,
    deletePlace,
    setCurrent,
    setColorItem,
    current,
    getPivotPosition,
    getCenterPosition,
    getUnitPosition,
    getGridPosition,
    textures,
  } = usePlace();
  const {
    available: dragAvailable,
    enabled: dragEnabled,
    position: dragPosition,
    opacity,
    setDragState,
    setDragAvailable,
    setDragEnabled,
    setDragPosition,
  } = useDragState();
  const ref = useRef();
  const cloneRef = useRef();
  const [initUnitScale, setInitUnitScale] = useState(false);
  const [event, setEvent] = useState(); 
  const [isClickable, setClickable] = useState(false); 
  const [highlighted, highlight] = useState(false); 
  const [blockPopupUI, setBlockPopupUI] = useState(false); 
  const [meshes] = useState([]);
  const [materials] = useState([]);
  const [colors] = useState([]);
  const [changeColors] = useState([]);

  const path = gear.path;
  const isFBX = false; 
  useEffect(() => {
    meshes.splice(0, meshes.length);
    materials.splice(0, materials.length);
    colors.splice(0, colors.length);
    let cloneMaterials = {};
    const clone = cloneRef.current;
    clone.traverse((child) => {
      if (child.isMesh) {
        child.data = gear;
        child.castShadow = true;
        child.receiveShadow = true;
        if (Array.isArray(child.material)) {
          const newChildMaterial = [];
          for (const material of child.material) {
            if (
              Object.keys(cloneMaterials).find(({ mat }) => mat === material)
            ) {
              newChildMaterial.push(cloneMaterials[material]);
            } else {
              const p = { ...material };
              delete p.type;
              delete p.uuid;
              const newMat = isFBX
                ? new THREE.MeshLambertMaterial(p)
                : material.clone();
              newChildMaterial.push(newMat);
              cloneMaterials[material] = newMat;
              materials.push(newMat);
              colors.push(newMat.color);
              changeColors.push(newMat.color);
              setColorItem(newMat.uuid, "#" + newMat.color.getHexString());
            }
          }
          child.material = newChildMaterial;
        } else {
          //단일 재질
          if (cloneMaterials[child.material]) {
            child.material = cloneMaterials[child.material];
          } else {
            const p = { ...child.material };
            delete p.type;
            delete p.uuid;
            const newMat = isFBX
              ? new THREE.MeshLambertMaterial(p)
              : child.material.clone();
            child.material = newMat; 
            cloneMaterials[child.material] = newMat;
            materials.push(newMat);
            colors.push(newMat.color);
            changeColors.push(newMat.color);
            setColorItem(newMat.uuid, "#" + newMat.color.getHexString());
          }
        }
        meshes.push(child);
      }
    });
  }, []);
  useEffect(() => {
    const initParams = gear.initParams;
    if (initParams) {
      if (initParams.changeColors) {
        changeColors.forEach((clr, i) => clr.set(initParams.changeColors[i]));
        materials.forEach((mat, i) => (mat.color = initParams.changeColors[i]));
      }
      if (initParams.rotation) gear.rotation = initParams.rotation;
      if (initParams.unitPosition) gear.unitPosition = initParams.unitPosition;
      if (initParams.added) {
        while (true) {
          if (!isCollision(gear, gear.unitPosition)) break;
          gear.unitPosition.setX(gear.unitPosition.x + 1);
        }
      }
      gear.updatePositionByUnit();
      setDragState({
        available: false,
        enabled: false,
        index: index,
        position: gear.position,
        rotation: gear.rotation,
      });
      gear.initParams = null;
    }
  }, []);
  const onCentered = (props) => {
    setGearCenterProps(gear, props);
    gear.unit = new THREE.Vector3(
      Math.round((props.width / unitScale.x) * 10) / 10,
      Math.round((props.height / unitScale.y) * 10) / 10,
      Math.round((props.depth / unitScale.z) * 10) / 10
    );
    if (!initUnitScale) {
      setInitUnitScale(true);
    }
  };
  useEffect(() => {
    if (event) {
      const floorPlane = new THREE.Box3(
        new THREE.Vector3(-100, -100, 0),
        new THREE.Vector3(100, 100, 0)
      );
      let planeIntersectPoint = new THREE.Vector3();
      if (floorPlane.isPlane)
        event.ray.intersectPlane(floorPlane, planeIntersectPoint);
      else if (floorPlane.isBox3)
        event.ray.intersectBox(floorPlane, planeIntersectPoint);
      setDragPosition(
        new THREE.Vector3(
          planeIntersectPoint.x,
          planeIntersectPoint.y,
          planeIntersectPoint.z
        )
      );
    }
  }, [event]);
  useEffect(() => {
    if (highlighted && dragEnabled) {
      const viewportPos = dragPosition;
      const centerPos = getCenterPosition(gear, viewportPos);
      const gridPos = getGridPosition(gear, centerPos);
      const pivotPos = getPivotPosition(gear, gridPos);
      ref.current.position.set(pivotPos.x, pivotPos.y, pivotPos.z);
      const unitPos = getUnitPosition(gear, gridPos);
      setRed(isCollision(gear, unitPos));
    }
  }, [dragPosition]);
  useEffect(() => {
    const pivotPos = getPivotPosition(gear, gear.position);
    ref.current.position.set(pivotPos.x, pivotPos.y, pivotPos.z);
    ref.current.rotation.set(gear.rotation.x, gear.rotation.y, gear.rotation.z);
  }, [gear.position, gear.rotation]);
  const setHighlight = (b) => {
    highlight(b);
  };
  const setColor = (matUUID, color) => {
    materials.forEach((mat, i) => {
      if (mat.uuid === matUUID) {
        mat.color = changeColors[i] = color;
      }
    });
  };
  const setRed = (b) => {
    materials.forEach((mat, i) => {
      mat.color = b ? new THREE.Color("red") : changeColors[i];
    });
  };
  const setChangeColor = () => {
    materials.forEach((mat, i) => {
      mat.color = changeColors[i];
    });
  };
  const setOriginalColor = () => {
    materials.forEach((mat, i) => {
      mat.color = changeColors[i] = colors[i];
    });
  };
  const setTransparent = (b) => {
    materials.forEach((mat) => {
      mat.transparent = b;
      mat.opacity = b ? opacity : 1;
    });
  };
  const unselect = () => {
    setHighlight(false);
    setTransparent(false);
  };
  const changeTexture = (index) => {
    const texture = textures[index];
    materials.forEach((mat) => (mat.map = texture));
  };
  setGearCB(gear, setColor, unselect, changeTexture);
  //선택
  const selectCurrent = (material) => {
    if (Array.isArray(material)) {
      const uuid = [];
      const name = [];
      material.forEach((mat) => {
        uuid.push(mat.uuid);
        name.push(mat.name);
      });
      setCurrent(gear.id, uuid, name);
    } else {
      setCurrent(gear.id, material.uuid, material.name);
    }
  };
  const onSelect = (e) => {
    if (enableClick === false) {
      return; 
    }
    if (!isClickable) {
      clearSelect(e.timeStamp, gear.id);
      return;
    }
    if (e.object.data) {      
      if (e.object.data.id === gear.id)
        clearSelect(e.timeStamp, gear.id);
      setCurrent(null);
      setHighlight(true);
      selectCurrent(e.object.material);
    }
  };

  const onDragStartIcon = (e) => {
    if (e.object.data && e.object.data.id === gear.id) {
      setClickable(true);
    }
    if (highlighted) {
      setDragState({
        available: false,
        enabled: true,
        index: index,
        point: { x: e.x, y: e.y },
        position: ref.current.position,
        rotation: gear.rotation,
      });
      if (ref.current) ref.current.renderPriority = 1;
      setTransparent(true);
      setDragging(true);
      setClickable(false);
    }
  };
  const onDragIcon = (e) => {
    if (dragAvailable) {
    }
    if (dragEnabled) {
      setEvent(e); 
    }
  };
  //UI 이동 끝
  const onDragEndIcon = (state) => {
    const { event: e } = state;
    setDragging(false);
    setDragAvailable(false);
    setDragEnabled(false);
    setTransparent(false);
    setChangeColor();
    if (state.tap) onSelect(e);
    if (!ref.current) return;
    ref.current.renderPriority = 0;
    const centerPos = getCenterPosition(gear, ref.current.position);
    const unitPos = getUnitPosition(gear, centerPos);
    if (isCollision(gear, unitPos)) {
      const centerPos = gear.position;
      const gridPos = getGridPosition(gear, centerPos);
      const pivotPos = getPivotPosition(gear, gridPos);
      ref.current.position.set(pivotPos.x, pivotPos.y, pivotPos.z);
    } else {
      gear.unitPosition = unitPos;
      gear.updatePositionByUnit();
    }
  };
  const bind = useGesture({
    onDragStart: (state) => {
      const { event: e } = state;
      e.stopPropagation();
      onDragStartIcon?.(e);
    },
    onDrag: (state) => {
      const { event: e } = state;
      e.stopPropagation();
      onDragIcon?.(e);
    },
    onDragEnd: (state) => {
      const { event: e } = state;
      e.stopPropagation();
      onDragEndIcon?.(state);
    },
    onPinchStart: (state) => {
      const { event: e } = state;
      e.stopPropagation();
    },
    onPinch: (state) => {
      const { event: e } = state;
      e.stopPropagation();
    },
    onPinchEnd: (state) => {
      const { event: e } = state;
      e.stopPropagation();
    },
    onDoubleClick: ({ event: e }) => {
      e.stopPropagation();
    },
    onClick: ({ event: e }) => {
      e.stopPropagation();
      onSelect(e); 
    },
    onHover: (state) => {
      const { event: e } = state;
      e.stopPropagation();
      onHover?.(state);
    },
    onPointerOver: (state) => {
      const { event: e } = state;
      e.stopPropagation();
    },
    onPointerOut: (state) => {
      const { event: e } = state;
      e.stopPropagation();
    },
    onPointerEnter: (state) => {
      const { event: e } = state;
      e.stopPropagation();
    },
    onPointerLeave: (state) => {
      const { event: e } = state;
      e.stopPropagation();
    },
    onPointerDown: (state) => {
      const { event: e } = state;
      e.stopPropagation();
    },
    onPointerUp: (state) => {
      const { event: e } = state;
      e.stopPropagation();
    },
  });
  const onClickCopy = (e) => {
    clearSelect();
    const initParams = {
      added: true,
      changeColors: changeColors,
      unitPosition: new THREE.Vector3(
        gear.unitPosition.x + 1,
        gear.unitPosition.y,
        gear.unitPosition.z
      ),
      rotation: gear.rotation,
    };
    addPlace(uuidv4(), gear.name, gear.path, initParams);
  };
  const onClickDelete = (e) => {
    setHighlight(false);
    deletePlace(gear);
    needUpdate();
  };
  const onClickRotateLeft = (e) => {
    setBlockPopupUI(true);
    setTimeout(() => setBlockPopupUI(false), 400);
    gsap
      .to(ref.current.rotation, {
        duration: 0.4,
        repeat: 0,
        y: () => {
          return gear.rotation.y - Math.PI / 2;
        },
        ease: "power3.Out",
      })
      .then(() => {
        gear.rotation = ref.current.rotation;
      });
  };
  const onClickRotateRight = (e) => {
    setBlockPopupUI(true);
    setTimeout(() => setBlockPopupUI(false), 400);
    gsap
      .to(ref.current.rotation, {
        duration: 0.4,
        repeat: 0,
        y: () => {
          return gear.rotation.y + Math.PI / 2;
        },
        ease: "power3.Out",
      })
      .then(() => {
        gear.rotation = ref.current.rotation;
      });
  };
  const onClickRotateUp = (e) => {
    setBlockPopupUI(true);
    setTimeout(() => setBlockPopupUI(false), 400);
    gsap
      .to(ref.current.rotation, {
        duration: 0.4,
        repeat: 0,
        x: () => {
          return gear.rotation.x - Math.PI / 2;
        },
        ease: "power3.Out",
      })
      .then(() => {
        gear.rotation = ref.current.rotation;
      });
  };
  const onClickRotateDown = (e) => {
    setBlockPopupUI(true);
    setTimeout(() => setBlockPopupUI(false), 400);
    gsap
      .to(ref.current.rotation, {
        duration: 0.4,
        repeat: 0,
        x: () => {
          return gear.rotation.x + Math.PI / 2;
        },
        ease: "power3.Out",
      })
      .then(() => {
        gear.rotation = ref.current.rotation;
      });
  };
  const { camera } = useThree();
  const x = (unitScale.x * gear.unit.x) / 2;
  const y = -(unitScale.y * gear.unit.y) / 2;
  const z = -(unitScale.z * gear.unit.z) / 2;
  const positionOfPopup = new THREE.Vector3(x, y, z);
  //#region 모델 반환
  return (
    <>
      <group ref={ref} {...bind()}>
        <Center onCentered={onCentered}>
          <Select enabled={highlighted}>
            <Model ref={cloneRef} path={path} />
          </Select>
        </Center>
        <PopupUI
          index={index}
          enabled={highlighted && current && !blockPopupUI}
          position={positionOfPopup}
          nameText={gear.name}
          onDragging={setDragging}
          onDragStartIcon={onDragStartIcon}
          onDragIcon={onDragIcon}
          onDragEndIcon={onDragEndIcon}
          onClickCopy={onClickCopy}
          onClickDelete={onClickDelete}
          onClickRotateLeft={onClickRotateLeft}
          onClickRotateRight={onClickRotateRight}
          onClickRotateUp={onClickRotateUp}
          onClickRotateDown={onClickRotateDown}
        />
        {!isShowMapHidden && <ShowMap materials={materials} />}
      </group>
    </>
  );
}

function PositionOfPopup(gear, object, camera) {
  if (object == null) return;
  const boundingBox = new THREE.Box3().setFromObject(object, true);
  var boxSize = new THREE.Vector3();
  boundingBox.getSize(boxSize);
  const half = boxSize.multiplyScalar(0.5);
  const position = new THREE.Vector3(half.x, -half.y, -half.z);
  return position;
}
function computeScreenSpaceBoundingBox(mesh, camera) {
  var vertices = mesh.geometry.vertices;
  var vertex = new THREE.Vector3();
  var min = new THREE.Vector3(1, 1, 1);
  var max = new THREE.Vector3(-1, -1, -1);

  for (var i = 0; i < vertices.length; i++) {
    var vertexWorldCoord = vertex
      .copy(vertices[i])
      .applyMatrix4(mesh.matrixWorld);
    var vertexScreenSpace = vertexWorldCoord.project(camera);
    min.min(vertexScreenSpace);
    max.max(vertexScreenSpace);
  }

  return new THREE.Box2(min, max);
}
function getBox(meshes) {
  const bbox = new THREE.Box3().setFromObject(meshes);
  const bboxSize = new THREE.Vector3(bbox);
  bbox.getSize(bboxSize);
  return bboxSize;
}
function LoadFBX(ref, path, props) {
  const fbx = useFBX(path);
  const clone = useMemo(() => fbx.clone(), [fbx]);
  clone.scale.set(0.01, 0.01, 0.01); 
  return (
    <group ref={ref} {...props}>
      <primitive object={clone} />
    </group>
  );
}
function LoadGLTF(ref, path, props) {
  const gltf = useGLTF(path);
  const { scene, nodes, materials } = gltf;
  const clone = useMemo(() => scene.clone(), [scene]);
  const { modelScale } = useInitVar();
  clone.scale.set(modelScale.x, modelScale.y, modelScale.z); 
  return <primitive ref={ref} object={clone} {...props} />;

}
function LoadBox(ref, ...props) {
  const texture = new THREE.TextureLoader().load("/three/0.jpg");
  return (
    <mesh ref={ref} {...props}>
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial map={texture} />
    </mesh>
  );
}
const Model = forwardRef(({ path, ...props }, ref) => {
  return LoadGLTF(ref, path, props);
});

function Picker() {
  const { setColorItem, current, colorItems, setGearColor } = usePlace();
  if (current) {
    if (Array.isArray(current.uuid)) {
      current.uuid.forEach((uuid) => {
        if (uuid && !colorItems[uuid]) {
          setColorItem(uuid, "#fff");
        }
      });
    } else {
      if (current.uuid && !colorItems[current.uuid]) {
        setColorItem(current.uuid, "#fff");
      }
    }
  }
  const color = (i) => {
    return current
      ? Array.isArray(current.uuid)
        ? current.uuid.map((uuid) => colorItems[uuid])[i]
        : colorItems[current.uuid]
      : null;
  };
  const onChange = (color, i) => {
    if (Array.isArray(current.uuid)) {
      setColorItem(current.uuid[i], color);
      setGearColor(current.uuid[i], new THREE.Color(color));
    } else {
      setColorItem(current.uuid, color);
      setGearColor(current.uuid, new THREE.Color(color));
    }
  };
  return (
    <>
      {current && Array.isArray(current.uuid) && (
        <ul className="pickerlist">
          {current.name.map((name, i) => (
            <li key={name}>
              <HexColorPicker
                className="pickerItem"
                color={color(i)}
                onChange={(color) => onChange(color, i)}
              />
            </li>
          ))}
        </ul>
      )}
      {!current ||
        (!Array.isArray(current.uuid) && (
          <div style={{ display: current ? "block" : "none" }}>
            <HexColorPicker
              className="picker"
              color={color()}
              onChange={onChange}
            />
          </div>
        ))}
    </>
  );
}

function ShowMap({ materials }) {
  if (!materials || !Array.isArray(materials)) return;
  const textures = materials.map((mat, i) => {
    return (
      <group key={i}>
        <mesh position={[-6, -i, 10]}>
          <planeGeometry args={[1, 1]} />
          <meshBasicMaterial map={mat.map} />
        </mesh>
        <mesh position={[-5, -i, 10]}>
          <planeGeometry args={[1, 1]} />
          <meshBasicMaterial map={mat.metalnessMap} />
        </mesh>
        <mesh position={[-6, -i - 1, 10]}>
          <planeGeometry args={[1, 1]} />
          <meshBasicMaterial map={mat.normalMap} />
        </mesh>
        <mesh position={[-5, -i - 1, 10]}>
          <planeGeometry args={[1, 1]} />
          <meshBasicMaterial map={mat.roughnessMap} />
        </mesh>
      </group>
    );
  });
  return <Billboard>{textures}</Billboard>;
}

export default forwardRef(Scene);
