import "../styles/AllReviewsListComponent.sass";
import no_product from "../images/no-product.svg";
import {useEffect, useState} from "react";
import axios from 'axios';
import {useNavigate} from "react-router-dom";
import { useSelector } from "react-redux";

const AllReviewsListComponent = ({productSeq}) => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [reviews, setReviews] = useState([]);
  const accessToken = useSelector((state) => state.userInfo.accessToken);
  const loginInfo = useSelector((state) => state.userInfo.userInfo);

  const fetchReviews = (page) => {
      try {
        axios.get(process.env.REACT_APP_API_URL + '/review/list/'+productSeq, {
          params: { page: page },
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        }).then((response) => {
          //console.log('response after fetch reviews : ', response.data);
          if (response.data === null || response.data === undefined || response.data === '') return;
          setReviews(prevReviews => [...prevReviews, ...response.data.reviewList]);
          setCurrentPage(page);
          setTotalPage(response.data.totalPage);
        });
      } catch (error) {
        console.log(error);
      }      
   };

  useEffect(() => {
    setReviews([]);
    fetchReviews(1);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if(window.scrollY + window.innerHeight >= document.documentElement.scrollHeight) {
        if(currentPage < totalPage) {
          fetchReviews(currentPage+1);
          setCurrentPage(currentPage+1);
        }
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [currentPage, totalPage]);

  const onClickReviewItem = (productReviewSeq) => {
    // dispatch(productListSetSelectedProductSeq(productSeq));
    navigate(`/review/detail/${productReviewSeq}`);
  }  

  return (
    <div className="reviews-list-wrap">
      <div className="reviews-list">
        {
          reviews.map((item, index) => {
            return (
              <div className="reviews-item" key={index}>
                {
                  item.reviewImgList === null
                    ? <div className="reviews-image" key={index} style={{ backgroundImage: `url('https://via.placeholder.com/140')` }} onClick={() => onClickReviewItem(item.productReviewSeq)}></div>
                    : item.reviewImgList && item.reviewImgList.map((img, index) => (
                      <div className="reviews-image" key={index} style={{ backgroundImage: `url('${process.env.REACT_APP_API_URL}/image/review/${img}')` }} onClick={() => onClickReviewItem(item.productReviewSeq)}></div>
                    ))
                }
                <div className="reviews-info">
                  <h2 className="reviews-name" onClick={() => onClickReviewItem(item.productReviewSeq)}>{item.title}</h2>
                  <p className="review-author">작성자: {item.author}</p>
                  <p className="review-content" onClick={() => onClickReviewItem(item.productReviewSeq)}>
                  {item.content.length > 50 ? item.content.substring(0, 50) + '...' : item.content}
                  </p>
                </div>
              </div>
            )
          })
        }
        {
          reviews.length === 0 ? <div className="no-product">
            <img src={no_product} />
            <h1>리뷰가 없습니다.</h1>
          </div> : ""
        }
      </div>
    </div>
  );
}
export default AllReviewsListComponent;