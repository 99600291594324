export const USER_ACCESS_TOKEN_SET = "USER_ACCESS_TOKEN_SET"
export const USER_INFO_SET = "USER_INFO_SET"
export const userAccessTokenSet = (accessToken) => {
  return {
    type: USER_ACCESS_TOKEN_SET,
    payload: accessToken
  }
}
export const userInfoSet = (userInfo) => {
  return {
    type: USER_INFO_SET,
    payload: userInfo
  }
}