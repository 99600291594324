import { useLayoutEffect } from "react";
import { texture_default } from "./Place";

const imgPreload = () => {
  texture_default.forEach((path) => {
    let img = new Image();
    img.src = path;
  });
};

export function TexturePicker({ enabled, onClick }) {
  const list = texture_default;

  useLayoutEffect(() => {
    imgPreload();
  });

  return (
    <ul hidden={!enabled} className="furnitureview-list">
      {list.map((name) => (
        <li key={name}>
          <img
            src={name}
            width={45}
            height={45}
            alt="empty"
            onClick={(e) => onClick?.(name)}
          />
        </li>
      ))}
    </ul>
  );
}
