import sait_main_logo from "../images/sait-main-logo.png";
import google_login from "../images/google-login.png";
import kakao_login from "../images/kakao-login.png";
import {store} from "../store";
import axios from 'axios';

import {Link, useNavigate} from "react-router-dom";
import "../styles/LoginView.sass";

import {useEffect, useState} from "react";
import ToastMessageComponent from "../components/ToastMessageComponent";
import { useDispatch, useSelector } from 'react-redux';
import {userAccessTokenSet, userInfoSet} from "../redux/actions/userInfo";

const LoginView = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [toastMessage, setToastMessage] = useState(null);
  const [toastType, setToastType] = useState('error');

  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onClickLogin = async () => {
    const spaceRegExp = /\s/g;
    if(email === '' || email === null || email === undefined || spaceRegExp.test(email)) {
      setEmailError(true);
      return;
    }
    setEmailError(false);
    if(password === '' || password === null || password === undefined || spaceRegExp.test(password)) {
      setPasswordError(true);
      return;
    }
    setPasswordError(false);
    let loginResponse;
    try{
      loginResponse = await axios.post(`${process.env.REACT_APP_API_URL}/member/login/sait`, {
        email: email,
        password: password
      });
    } catch(err) {
      setToastMessage('로그인 중 오류가 발생했습니다.');
      setToastType('error');
    }

    if(loginResponse.data.result === 'success') {
      dispatch(userAccessTokenSet(loginResponse.data.accessToken));
      dispatch(userInfoSet(loginResponse.data.loginMember))
      let wishlistResponse;
      try {
        wishlistResponse = await axios.get(`${process.env.REACT_APP_API_URL}/wishlist/product/list/seq`, {
          headers: {
            'Authorization': `Bearer ${loginResponse.data.accessToken}`
          }
        });
        const moduleWishlistSeqs = await axios.get(`${process.env.REACT_APP_API_URL}/wishlist/module/list/seq`, {
          headers: {
            'Authorization': `Bearer ${loginResponse.data.accessToken}`
          }
        });
        localStorage.setItem('product_wishlist', JSON.stringify([...new Set(wishlistResponse.data)]));
        localStorage.setItem('module_wishlist', JSON.stringify(moduleWishlistSeqs.data));
        navigate('/');
      }
      catch (err) {
        setToastMessage('위시리스트 조회 중 오류가 발생했습니다.');
        setToastType('error');
      }
    } else {
      setToastMessage("이메일 또는 비밀번호 오류입니다");
      setToastType('error');
      return
    }


  }


  const onClickKakao = () => {
    const api_key = process.env.REACT_APP_KAKAO_API_KEY;
    const redirect_uri = process.env.REACT_APP_KAKAO_REDIRECT_URL;
    const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${api_key}&redirect_uri=${redirect_uri}&response_type=code`;
    window.location.href = kakaoURL;
  }

  const onClickGoogleLogin = () => {
    const googleAuthURL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_URI}&response_type=code&scope=email%20profile%20openid&access_type=offline`;
    window.location.href = googleAuthURL;
  }

  const {naver} = window;
  const naverLogin = new naver.LoginWithNaverId({
    clientId: process.env.REACT_APP_NAVER_CLIENT_ID,
    callbackUrl: process.env.REACT_APP_NAVER_CALLBACK_URL,
    isPopup: false,
    callbackHandle: true,
    loginButton: {
      color: "green", 
      type: 1, 
      height: 50, 
    },
  });
  useEffect(() => {
    naverLogin.init();
  }, []);

  return (
    <div className="login-view-wrap">
      <Link to="/">
        <img src={sait_main_logo} alt="sait-main-logo" className="sait-main-logo"/>
      </Link>
      <div className="login-form">
        <div className="input-row">
          <p>이메일</p>
          <input type="text" className={emailError?"error":""} placeholder="이메일 입력" value={email} onChange={(e) => setEmail(e.target.value)}/>
          {emailError?<p className="err-msg">이메일을 입력해주세요</p>:""}
        </div>
        <div className="input-row">
          <p>비밀번호</p>
          <input type="password" className={passwordError?"error":""} placeholder="비밀번호 입력" value={password} onChange={(e) => setPassword(e.target.value)}/>
          {passwordError?<p className="err-msg">비밀번호를 입력해주세요</p>:""}
        </div>
      </div>
      <div className="login-btn-area">
        <button onClick={onClickLogin}>로그인</button>
        <Link to="/join">회원가입</Link>
      </div>
      <div className="find-id-pw">
        <Link to="/find-email" className="find-btn">이메일 찾기</Link>
        <Link to="/find-pw" className="find-btn">비밀번호 찾기</Link>
      </div>
      <div className="sns-login">
        <h2 className="sns-login-title">
          <span className="line"></span>
          <span className="text">소셜계정으로 로그인</span>
          <span className="line"></span>
        </h2>
        <div className="sns-login-btn-area">
          <button><img src={google_login} alt="sns-login" onClick={onClickGoogleLogin}/></button>
          <button id="naverIdLogin"></button>
          <button><img src={kakao_login} alt="sns-login" onClick={onClickKakao}/></button>
        </div>
      </div>
      {toastMessage?<ToastMessageComponent message={toastMessage} closeToast={() => setToastMessage(null)} time={3000}
                              type={toastType}/>:""}
    </div>
  );
}

export default LoginView;