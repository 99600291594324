import "../styles/CategoryMenuComponent.sass";
import axios from 'axios'
import {useEffect, useState} from "react";
import HeaderComponent from "./HeaderComponent";
import chevron from "../images/chevron-right.svg";
import {productListSetCategorySeq, productListSetListDivision, productListSetTitle} from "../redux/actions/productList";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {mainMenuSetVisible} from "../redux/actions/mainMenu";

const CategoryMenuComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const rootCategorySeq = useSelector(state => state.productList.categorySeq);


  useEffect(() => {
    axios.get(process.env.REACT_APP_API_URL+'/category/all').then((res) => {
      let categoryList = res.data.filter((category) => category.parentCategorySeq === null);
      let subCategories = {};
      let subSubCategories = {};
      categoryList.forEach((category) => {
        if(category.hasSubCategory) {
          subCategories[category.categorySeq] = res.data.filter((subCategory) => subCategory.parentCategorySeq === category.categorySeq);
          subCategories[category.categorySeq].forEach((subCategory) => {
            if(subCategory.hasSubCategory) {
              subSubCategories[subCategory.categorySeq] = res.data.filter((subSubCategory) => subSubCategory.parentCategorySeq === subCategory.categorySeq);
            }
          })
        }
      })
      setRootCategoryList(categoryList);
      setSubCategoryList(subCategories);
      setSubSubCategoryList(subSubCategories);
      setSelectedRootCategory(rootCategorySeq);
      setSelectedRootCategoryName(categoryList.filter((category) => category.categorySeq === rootCategorySeq)[0].categoryNameKor);
    }).catch((err) => {
      console.log(err);
    });
  }, []);

  const [rootCategoryList, setRootCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subSubCategoryList, setSubSubCategoryList] = useState([]);

  const [selectedRootCategory, setSelectedRootCategory] = useState(0);
  const [selectedSubCategory, setSelectedSubCategory] = useState(0);

  const [selectedRootCategoryName, setSelectedRootCategoryName] = useState("");
  const [selectedSubCategoryName, setSelectedSubCategoryName] = useState("");

  const onClickRootCategory = (category) => {
    setSelectedRootCategory(category.categorySeq);
    setSelectedRootCategoryName(category.categoryNameKor);
  }
  const onClickSubCategory = (category) => {
    //넘어온 서브 카테고리에 서브서브 카테고리가 없다면 제품 화면으로 넘어감.
    if(!category.hasSubCategory) {
      dispatch(productListSetCategorySeq(category.categorySeq));
      dispatch(productListSetTitle(category.categoryNameKor));
      dispatch(productListSetListDivision("category"));
      dispatch(mainMenuSetVisible(false));
      navigate("/product")
      return;
    }
    //넘어온 서브 카테고리에 서브서브 카테고리가 있다면 서브서브카테고리로 넘어감.
    setSelectedSubCategory(category.categorySeq);
    setSelectedSubCategoryName(category.categoryNameKor);
  }
  //중분류를 클릭했을 때 해당 중분류에 해당하는 제품들의 목록 페이지로 이동
  const onClickSubCategoryName = (category) => {
    dispatch(productListSetCategorySeq(category.categorySeq));
    dispatch(productListSetTitle(category.categoryNameKor));
    dispatch(productListSetListDivision("category"));
    dispatch(mainMenuSetVisible(false));
    navigate("/product")
    return;
  }
  //중분류에 할당된 화살표를 클릭했을 때 해당 중분류에 속한 소분류 카테고리로 이동.
  const onClickSubCategoryArrow = (category) => {
    setSelectedSubCategory(category.categorySeq);
    setSelectedSubCategoryName(category.categoryNameKor);
  }
  const onClickSubSubCategory = (category) => {
    dispatch(productListSetCategorySeq(category.categorySeq));
    dispatch(productListSetTitle(category.categoryNameKor));
    dispatch(productListSetListDivision("category"));
    dispatch(mainMenuSetVisible(false));
    navigate("/product")
  }

  const onClickMypageBtn = () => {
    dispatch(mainMenuSetVisible(false));
    navigate("/mypage");
  }
  const moveToSubCategory = () => {
    setSelectedSubCategory(0);
    setSelectedSubCategoryName("");
  }
  const moveToRootCategory = () => {
    setSelectedRootCategory(0);
    setSelectedRootCategoryName("");
  }
  return (
    <div className="category-menu-wrap">
      <HeaderComponent/>
      <div className="rootCategory-list">
        <h1 className="category-menu-title">카테고리</h1>
        {
          rootCategoryList.map((category) => {
            return (
              <div className="category-menu-btn" key={category.categorySeq}
                   onClick={() => onClickRootCategory(category)}>
                <span>{category.categoryNameKor}</span>
                <span className="sub-category-chevron">
                  <img src={chevron}/>
                </span>
              </div>
            )
          })
        }
        <div className="category-menu-btn">브랜드별</div>
        <div className="category-menu-btn">타임세일</div>
        <div className="category-menu-btn">BEST 100</div>
      </div>
      <div className={"subCategories " + (selectedRootCategory !== 0 ? "show" : "")}>
        <h1 className="category-menu-title" onClick={moveToRootCategory}>
          <img src={chevron} className="chevron-reverse"/>
          {selectedRootCategoryName}
        </h1>
        {
          Object.keys(subCategoryList).map((key) => {
            return (
              <div key={key}
                   className={"subCategory-list " + (key.toString() === selectedRootCategory.toString() ? "show" : "")}>
                {
                  subCategoryList[key].map((category) => {
                    return (
                      <div className="category-menu-btn" key={category.categorySeq}>
                        <span onClick={() => onClickSubCategoryName(category)}>{category.categoryNameKor}</span>
                        {category.hasSubCategory ? <span className="sub-category-chevron" onClick={() => onClickSubCategoryArrow(category)}>
                          <img src={chevron}/>
                        </span> : ""}
                      </div>
                    )
                  })
                }
              </div>
            )
          })
        }
      </div>
      <div className={"subSubCategories " + (selectedSubCategory !== 0 ? "show" : "")}>
        <h1 className="category-menu-title" onClick={moveToSubCategory}>
          <img src={chevron} className="chevron-reverse"/>
          <span>{selectedRootCategoryName}</span>
          <span>|</span>
          <span>{selectedSubCategoryName}</span>
        </h1>
        {
          Object.keys(subSubCategoryList).map((key) => {
            return (
              <div key={key}
                   className={"sub-subCategory-list " + (key.toString() === selectedSubCategory.toString() ? "show" : "")}>
                {
                  subSubCategoryList[key].map((category) => {
                    return (
                      <div className="category-menu-btn" key={category.categorySeq}
                           onClick={() => onClickSubSubCategory(category)}>
                        {category.categoryNameKor}
                      </div>
                    )
                  })
                }
              </div>
            )
          })
        }
      </div>
    </div>
  );
}

export default CategoryMenuComponent;