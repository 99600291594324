import "../styles/AllReviewsView.sass";
import HeaderComponent from "../components/HeaderComponent";
import {useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import MyReviewListComponent from "../components/MyReviewsListComponent"
import { useSelector } from 'react-redux';


const MyReviewsView = () => {
  const [floatingHeader, setFloatingHeader] = useState(false);
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.userInfo.accessToken);
  const loginInfo = useSelector((state) => state.userInfo.userInfo);
  useEffect(() => {
    if(loginInfo === null || loginInfo === undefined || loginInfo === '') {
      alert("로그인한 후 이용 가능합니다.")
      navigate("/")
    }
  },[loginInfo]);

  window.addEventListener('scroll', () => {
    if(window.scrollY > 0 && !floatingHeader) {
      setFloatingHeader(true);
    } else if(window.scrollY === 0 && floatingHeader){
      setFloatingHeader(false);
    }
  })  

  return (
    <div className="product-view-wrap">
      <div className={"product-view-header "+(floatingHeader?"floating":"")}>
        <HeaderComponent isMainView={false}/>
        <h1>
          내가 작성한 리뷰들
        </h1>

      </div>
      <div className="product-view-content">
        <MyReviewListComponent loginInfo={loginInfo} accessToken={accessToken} />
      </div>
    </div>
  );
}

export default MyReviewsView;