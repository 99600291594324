import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { loadTossPayments, ANONYMOUS } from "@tosspayments/tosspayments-sdk";
import HeaderComponent from "../components/HeaderComponent";
import axios from "axios";
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from "react-redux";

import "../styles/CheckoutView.sass";

export function CheckoutPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { totalAmount, orderName, email, memberSeq, items, addressSeq, type } = location.state;
  const [ready, setReady] = useState(false);
  const [widgets, setWidgets] = useState(null);
  const [amount, setAmount] = useState(null);
  const accessToken = useSelector((state) => state.userInfo.accessToken);

  useEffect(() => {
    async function fetchPaymentWidgets() {
      const tossPayments = await loadTossPayments(process.env.REACT_APP_TOSS_CLIENTKEY);
      const widgets = tossPayments.widgets({ customerKey: ANONYMOUS });
      setWidgets(widgets);
    }
    fetchPaymentWidgets();
  }, []);

  useEffect(() => {
    if (!totalAmount || !orderName || !email || !memberSeq || !items || !addressSeq || !type) {
      alert("잘못된 접근입니다.");
      navigate("/");
    } else {
      setAmount({ currency: "KRW", value: totalAmount });
    }
  }, [totalAmount, orderName, email, memberSeq, items, addressSeq, type, navigate]);

  useEffect(() => {
    async function renderPaymentWidgets() {
      if (widgets == null) {
        return;
      }
      await widgets.setAmount(amount);
      await widgets.renderPaymentMethods({
        selector: "#payment-method",
        variantKey: "DEFAULT",
      });

      await widgets.renderAgreement({
        selector: "#agreement",
        variantKey: "AGREEMENT",
      });
      setReady(true);
    }

    renderPaymentWidgets();
  }, [widgets, amount]);

  const onClickPayment = async () => {
    const newOrderId = uuidv4();
    try {      
      const response2 = await axios.post(
        process.env.REACT_APP_API_URL + "/order/payment/temporary",
        {
          orderId: newOrderId,
          amount: amount.value,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const paymentData = {
        orderId : newOrderId,
        totalAmount: totalAmount,
        orderName: orderName,
        email: email,
        memberSeq: memberSeq,
        items: items,
        addressSeq: addressSeq,
        type: type
      };
      if(items[0].itemType === "C"){
        paymentData.furnitureType = items[0].furnitureType;
        paymentData.furnitureForm = items[0].furnitureForm;
        paymentData.keywordList = items[0].keywordList;
      }
      localStorage.setItem("paymentData", JSON.stringify(paymentData));

      const response1 = await widgets?.requestPayment({
        orderId: newOrderId,
        orderName: orderName,
        customerEmail: email,
        successUrl:
          window.location.origin + "/payment/success" + window.location.search,
        failUrl:
      window.location.origin + "/payment/fail" + window.location.search
      });
    } catch (error) {
      alert("결제 요청을 하는 도중 문제가 발생했습니다.");
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}/order/${newOrderId}/${amount.value}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        )
        .then((response) => {
          if (response.data.code !== 0) {
            return Promise.reject(
              new Error(
                "임시 데이터 삭제에 실패했습니다: " + response.data.message
              )
            );
          } 
        })
        .catch((error) => {
          console.error(error);
          alert(error.message);
        });
      navigate("/");
      console.error(error);
    }
  };

  return (
    <div className="wrapper w-100">
      <HeaderComponent />
      <div className="max-w-540 w-100">
        <div id="payment-method" className="w-100" />
        <div id="agreement" className="w-100" />
        <div className="btn-wrapper w-100">
          <button className="btn-payment" onClick={onClickPayment}>
            결제하기
          </button>
        </div>
      </div>
    </div>
  );
}
