import "../styles/ReviewDetailView.sass";
import HeaderComponent from "../components/HeaderComponent";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";


const ReviewDetailVeiw = () => {
  const { productReviewSeq } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (productReviewSeq === undefined) return;
    axios.get(process.env.REACT_APP_API_URL + '/review/' + productReviewSeq).then((response) => {
      setReviewDetail(response.data);
    }).catch((err) => {
      console.log(err);
    })
  }, [productReviewSeq]);

  const [reviewDetail, setReviewDetail] = useState({});

  return (
    <div className="review-detail-wrap">
      <HeaderComponent />
      <div className="review-detail-content">
        {
          reviewDetail.reviewImgList === null ? <div className="review-image" style={{ backgroundImage: `url('https://via.placeholder.com/140')` }}></div>
            : reviewDetail.reviewImgList && reviewDetail.reviewImgList.map((img, index) => (
              <div className="review-image" key={index} style={{ backgroundImage: `url('${process.env.REACT_APP_API_URL}/image/review/${img}')` }}></div>
            ))

        }
        <div className="review-basic-info">
          <h2 className="review-title">{reviewDetail.title}</h2>
          <h3 className="review-author-name">{reviewDetail.author}</h3>
        </div>
        <div className="review-detail">
          <p>{reviewDetail.content}</p>
        </div>
      </div>
    </div>
  );
}
export default ReviewDetailVeiw;