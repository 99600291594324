import "../../styles/module/ModuleDetailComponent.sass";
import {useEffect, useState} from "react";
import axios from "axios";


const ModuleDetailComponent = ({ moduleSeq, onClose }) => {
  const [moduleDetail, setModuleDetail] = useState({});
 useEffect(() => {
    if (!moduleSeq) return;

    // 모듈 상세 정보 가져오기
    axios.get(`${process.env.REACT_APP_API_URL}/module/${moduleSeq}`)
      .then((response) => {
        setModuleDetail(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [moduleSeq]);


  return (
    <div className="module-detail-wrap">
      <div className="module-detail-content">
        <div className="module-image" style={{ backgroundImage: `url('${process.env.REACT_APP_API_URL}/image/module/${moduleDetail.imageFile}')` }}></div>
          {moduleDetail.secondImageFile && (
            <div className="second-image">
              <img src={`${process.env.REACT_APP_API_URL}/image/module/${moduleDetail.secondImageFile}`} alt="Second view" />
            </div>
          )}
        <div className="module-basic-info">
          <h2 className="module-title">{moduleDetail.title}</h2>
          <div className="module-specs">
            <span className="spec-size">{moduleDetail.width}x{moduleDetail.height}x{moduleDetail.depth}cm</span>
          </div>
          <p className="module-price">{moduleDetail.price !== undefined ? moduleDetail.price.toLocaleString() : 0}원</p>
        </div>
        <button onClick={onClose}>닫기</button>
      </div>
    </div>
  );
};

export default ModuleDetailComponent;