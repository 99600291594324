import React, { useEffect, useState, createRef, useRef } from "react";
import HeaderComponent from "../components/HeaderComponent";
import axios from "axios";
import "../styles/OrderHistoryView.sass";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';


const OrderHistory = () => {
  const [orderHistory, setOrderHistory] = useState([]);
  const [counts, setCounts] = useState({ total: 0, paid: 0, delivered: 0, refunded: 0 });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);

  const accessToken = useSelector((state) => state.userInfo.accessToken);
  const loginInfo = useSelector((state) => state.userInfo.userInfo);
  const [loginUser, setLoginUser] = useState(loginInfo);

  const navigate = useNavigate();
  const area3dRefs = useRef([]);

  useEffect(() => {
    loadOrderHistory();
  }, []);

  useEffect(() => {
    if (loginInfo === null || loginInfo === undefined || loginInfo === "") {
      alert("로그인한 유저만 접근 가능합니다.");
      navigate("/");
    }
  }, []);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY + window.innerHeight >= document.documentElement.scrollHeight) {
        if (currentPage < totalPage) {
          loadOrderHistory(currentPage + 1);
          setCurrentPage(currentPage + 1);
        }
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [currentPage, totalPage]);

  const loadOrderHistory = (page = 1) => {
    const memberSeq = loginUser.memberSeq;
    axios
      .get(`${process.env.REACT_APP_API_URL}/order/member/${memberSeq}`, {
        params: {
          pageNo: page
        },
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      })
      .then((res) => {
        setOrderHistory(prevOrderHistory => [...prevOrderHistory, ...res.data.orderHistory]);
        setCounts({
          total: res.data.totalOrderCount,
          paid: res.data.paidOrderCount,
          delivered: res.data.deliveredOrderCount,
          refunded: res.data.refundedOrderCount,
        });
        setTotalPage(res.data.totalPage);
        setCurrentPage(res.data.currentPage);
      })
      .catch((err) => {
        console.error('Error fetching order history:', err);
      });
  };

  const handleTracking = (orderSeq) => {
    navigate(`/order/detail/${orderSeq}`);
  };

  const onClickOrderHistoryItem = (order) => () => {
    console.log("log in onclick => ", order);
    navigate(`/order/detail/${order.orderSeq}`);

  };

  return (
    <div className="order-history-view-wrap">
      <HeaderComponent />
      <div className="order-history-item-info">
        <h1>결제 내역</h1>
        <div className="order-summary">
          <div className="order-summary-item active">
            전체
            <div className="order-summary-item-count">{counts.total}</div>
          </div>
          <div className="order-summary-item">
            결제완료
            <div className="order-summary-item-count">{counts.paid}</div>
          </div>
          <div className="order-summary-item">
            배송
            <div className="order-summary-item-count">{counts.delivered}</div>
          </div>
          <div className="order-summary-item">
            환불
            <div className="order-summary-item-count">{counts.refunded}</div>
          </div>
        </div>
      </div>
      <div className="order-history-item-list">
        {orderHistory.map((order, index) => {
          if (!area3dRefs.current[index]) {
            area3dRefs.current[index] = createRef();
          }
          return (
            <div className="order-history-item" key={index} >
              <div className="order-history-item-top">
                <div className="order-history-item-image">
                  {order.productType === 'C' ? (
                    <img src={`${process.env.REACT_APP_API_URL}/image/module/${order.customFurnitureModules[0].thumbnail}`} alt="Module" />

                  ) : (
                    <img src={`${process.env.REACT_APP_API_URL}/image/product/${order.thumbnail}`} alt="Product" />
                  )}
                </div>
                <div className="order-details" onClick={onClickOrderHistoryItem(order)}>
                  <div className="order-date">{order.orderDate.split('T')[0]}</div>
                  <div className="order-number">주문번호 {order.orderSeq}</div>
                  <div className="order-name">{order.orderName}</div>
                  <div className="order-price">{order.amountPrice.toLocaleString()}원</div>
                  <div className={`order-status ${order.orderStatus}`}>{order.orderStatus}</div>
                </div>
              </div>
              <div className="order-actions">
                {order.orderStatus === '결제완료' && <button onClick={onClickOrderHistoryItem(order)}>결제취소</button>}
                {order.orderStatus === '배송중' && <button onClick={() => handleTracking(order.orderSeq)}>배송조회/환불요청</button>}
                {order.orderStatus === '배송완료' && <button onClick={onClickOrderHistoryItem(order)}>환불요청</button>}
                {order.orderStatus === '환불요청' && <button onClick={onClickOrderHistoryItem(order)}>환불요청취소</button>}
                {order.orderStatus === '제작중' && <button onClick={onClickOrderHistoryItem(order)}>환불요청</button>}
                {order.orderStatus === '제작중환불요청' && <button onClick={onClickOrderHistoryItem(order)}>환불요청취소</button>}
                {order.orderStatus === '배송중환불요청' && <button onClick={onClickOrderHistoryItem(order)}>환불요청취소</button>}

              </div>
            </div>
          );
        })}
      </div>
    </div>
  );

};

export default OrderHistory;