import "../styles/MemberCartView.sass"
import HeaderComponent from "../components/HeaderComponent";
import check from "../images/check.png";
import close_btn from "../images/close-btn.png";
import minus_btn from "../images/minus-btn.png";
import plus_btn from "../images/plus-btn.png";
import chevron from "../images/chevron-right.svg"
import {useEffect, useState} from "react";
import axios from 'axios';
import ToastMessageComponent from "../components/ToastMessageComponent";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";



const MemberCartView = () => {
  const [cartList, setCartList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [toastMessage, setToastMessage] = useState(null);
  const [toastType, setToastType] = useState("info");
  const navigate = useNavigate();
  const accessToken = useSelector((state) => state.userInfo.accessToken);
  const loginInfo = useSelector((state) => state.userInfo.userInfo);
  useEffect(() => {
    loadCartList();
  }, []);

  const loadCartList = () => {
    const loginUser = loginInfo;
    if(loginUser === null || loginUser === undefined || loginUser === '') return;
    axios.get(`${process.env.REACT_APP_API_URL}/cart`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    }).then((res) => {
      res.data.map((cart) => {
        cart.selected = false;
        return cart;
      });
      setCartList(res.data);
    }).catch((err) => {
      console.log(err);
    })
  }

  const onClickCartChk = (e, cartSeq) => {
    const checked = e.target.checked;
    setCartList(prevCartList => {
      return prevCartList.map((cart) => {
        if(cart.cartSeq === cartSeq) {
          cart.selected = checked;
        }
        return cart;
      });
    });
    setSelectedItems(prevSelectedItems => {
      if(checked) {
        return [...prevSelectedItems, cartSeq];
      } else {
        return prevSelectedItems.filter((item) => item !== cartSeq);
      }
    });
  }

  const onBlurItemCount = (e, cartSeq) => {
    const reg = /^[0-9]*$/;
    const count = e.target.value;
    if(count < 1 || !reg.test(count)) {
      setCartList(prevCartList => {
        return prevCartList.map((cart) => {
          if(cart.cartSeq === cartSeq) {
            cart.count = 1;
            onChangeCartItemCount(cartSeq, 1);
          }
          return cart;
        });
      });
      return;
    }
    setCartList(prevCartList => {
      return prevCartList.map((cart) => {
        if(cart.cartSeq === cartSeq) {
          cart.count = count;
          onChangeCartItemCount(cartSeq, count);
        }
        return cart;
      });
    });
  }
  const onChangeItemCount = (e, cartSeq) => {
    const count = e.target.value;
    setCartList(prevCartList => {
      return prevCartList.map((cart) => {
        if(cart.cartSeq === cartSeq) {
          cart.count = count;

        }
        return cart;
      });
    });
  }
  const onAddItemCount = (cartSeq) => {
    setCartList(prevCartList => {
      return prevCartList.map((cart) => {
        if(cart.cartSeq === cartSeq) {
          cart.count = cart.count + 1;
          onChangeCartItemCount(cartSeq, cart.count);
        }
        return cart;
      });
    });
  }
  const onMinusItemCount = (cartSeq) => {
    setCartList(prevCartList => {
      return prevCartList.map((cart) => {
        if(cart.cartSeq === cartSeq) {
          if(cart.count > 1) {
            cart.count = cart.count - 1;
            onChangeCartItemCount(cartSeq, cart.count);
          }
        }
        return cart;
      });
    });
  }

  const onChangeCartItemCount = (cartSeq, count) => {
    axios.patch(`${process.env.REACT_APP_API_URL}/cart`, {
      cartSeq: cartSeq,
      count: count
    },{
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    }).then((res) => {
    }).catch((err) => {
      console.log(err);
    })
  }

  const onClickRemoveButton = (cartSeq) => {
    axios.delete(`${process.env.REACT_APP_API_URL}/cart`, {
      data: {
        memberSeq: loginInfo.memberSeq,
        cartSeq: cartSeq
      },
      headers: {  
        'Authorization': `Bearer ${accessToken}`
      }
    }).then((res) => {
      setToastMessage("장바구니에서 삭제되었습니다.");
      loadCartList();
      setSelectedItems([]);
    }).catch((err) => {
      console.log(err);
    });
  }

  const onClickRemoveSelected = () => {
    axios.delete(`${process.env.REACT_APP_API_URL}/cart/list`, {
      data: {
        memberSeq: loginInfo.memberSeq,
        cartSeqList: selectedItems
      },
      headers: {  
        'Authorization': `Bearer ${accessToken}`
      }
    }).then((res) => {
      setToastMessage("선택한 상품이 삭제되었습니다.");
      loadCartList();
      setSelectedItems([]);
    }).catch((err) => {
      console.log(err);
    });
  }
  
  const onClickPurchase = () => {
    if(selectedItems.length < 1){
      alert("구매할 상품을 하나 이상 선택해 주세요")
      return;
    }
    const purchaseItemList = selectedItems.map((selectedItem) => {
      const cartItem = cartList.find(item => item.cartSeq === selectedItem);

      if (cartItem) {
        const purchaseItem = {
          itemSeq: cartItem.productNo,
          itemType: cartItem.productType,
          count: cartItem.count,
          price: cartItem.price,
          image: cartItem.thumbnail,
          name: cartItem.title,
          totalPrice: cartItem.price * cartItem.count
        };

        if (cartItem.changedSize) {
          purchaseItem.changedSize = cartItem.changedSize;
        }
        if (cartItem.color) {
          purchaseItem.color = cartItem.color;
        }
        if (cartItem.texture) {
          purchaseItem.texture = cartItem.texture;
        }

        return purchaseItem;
      }
    }).filter(item => item !== undefined); 
    navigate("/order", { state: { type : "P", purchaseItemList : purchaseItemList } });

  }

  return (
    <div className="member-cart-view-wrap">
      <HeaderComponent/>
      <div className="member-cart-view">
        <h1>장바구니</h1>
        <div className="member-cart-list">
          {
            cartList.map((cart, index) => {
              return (
                <div className={"member-cart-item "+(cart.selected?"selected":"")}>
                  <div className="member-cart-item-header">
                    <input type="checkbox" id={`cart-chk${index}`} onClick={(e) => onClickCartChk(e, cart.cartSeq)}/>
                    <label for={`cart-chk${index}`} style={{backgroundImage:`url('${check}')`}}></label>
                    <button className="cart-item-remove" onClick={() => onClickRemoveButton(cart.cartSeq)}>
                      <img src={close_btn} alt="close"/>
                    </button>
                  </div>
                  <div className="member-cart-item-body">
                    {cart.productType === 'P' ?
                      <div className="member-cart-item-img" style={{backgroundImage: `url('${process.env.REACT_APP_API_URL}/image/product/${cart.thumbnail}')`}}></div> :
                      <div className="member-cart-item-img" style={{backgroundImage: `url('${process.env.REACT_APP_API_URL}/image/module/${cart.thumbnail}')`}}></div>
                    }
                    <div className="member-cart-item-detail">
                      <h2 className="cart-item-title">{cart.title}</h2>
                      <p className="cart-item-term">평균 제작일 14일</p>
                      <p className="cart-item-price">{isNaN(cart.price * cart.count)?"0":(cart.price * cart.count).toLocaleString()}원</p>
                      <div className="cart-item-count">
                        <button className="cart-count-minus" style={{backgroundImage:`url('${minus_btn}')`}} onClick={() => onMinusItemCount(cart.cartSeq)}></button>
                        <input type="text" value={cart.count} onBlur={(e) => onBlurItemCount(e, cart.cartSeq)} onChange={(e) => {onChangeItemCount(e, cart.cartSeq)}}/>
                        <button className="cart-count-plus" style={{backgroundImage:`url('${plus_btn}')`}} onClick={() => onAddItemCount(cart.cartSeq)}></button>
                      </div>
                    </div>
                  </div>
                  {
                    cart.width&&cart.height&&cart.depth?<div className="cart-product-size">
                      <span>사이즈</span>
                      <span>{cart.width}&times;{cart.height}&times;{cart.depth}cm</span>
                    </div>:""
                  }
                  {
                    cart.productType === 'P' ?"":
                    <button className="modify-design">
                      <span>디자인 수정하기</span>
                      <img src={chevron} alt="chevron"/>
                    </button>
                  }
                </div>
              )
            })
          }
        </div>
        <div className="member-cart-view-btn-area">
          <button className="cart-purchase-btn" disabled={selectedItems.length === 0} onClick={onClickPurchase}>선택 구매하기</button>
          <button className="cart-delete-btn" disabled={selectedItems.length === 0} onClick={onClickRemoveSelected}>선택 삭제</button>
        </div>
      </div>
      {toastMessage?<ToastMessageComponent message={toastMessage} type={toastType} time={3000}
                              closeToast={() => setToastMessage(null)}/>:""}
    </div>
  );
}

export default MemberCartView;