
import HeaderComponent from "../components/HeaderComponent";
import mail_icon from "../images/mail-icon.png";
import {Link} from "react-router-dom";
import check from "../images/check.png";
import ToastMessageComponent from "../components/ToastMessageComponent";
import ModalPopupComponent from "../components/ModalPopupComponent";
import {useEffect, useState} from "react";
import axios from "axios";
import "../styles/FindPasswordView.sass";
const FindPasswordView = () => {
  const [memberName, setMemberName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationNumber, setVerificationNumber] = useState("");
  const [toastMsg, setToastMsg] = useState(null);
  const [toastType, setToastType] = useState("error");

  const [allAgree, setAllAgree] = useState(false);
  const [agree1, setAgree1] = useState(false);
  const [agree2, setAgree2] = useState(false);
  const [agree3, setAgree3] = useState(false);
  const [agree4, setAgree4] = useState(false);

  const [verificationTime, setVerificationTime] = useState(180);

  const [nameError, setNameError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [verificationNumberError, setVerificationNumberError] = useState(false);
  const [isVerificated, setIsVerificated] = useState(false);

  const [isSendVerificationNumber, setIsSendVerificationNumber] = useState(false);
  const [timeIntervalNo, setTimeIntervalNo] = useState(0);
  const [isVerificationTimeOver, setIsVerificationTimeOver] = useState(false);
  const [modalPopup, setModalPopup] = useState(false);

  const [findedEmail, setFindedEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [maskingEmail, setMaskingEmail] = useState("");
  const onClickSendVerificationNumber = async () => {
    if(memberName === "" || memberName.trim() === "") {
      setNameError(true);
      return;
    }
    else {
      setNameError(false);
    }
    if (!phoneNumber.match(/^01[0-9]{8,9}$/)) {
      setPhoneNumberError(true);
      return;
    }
    if(isSendVerificationNumber && !isVerificationTimeOver) return;

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/member/sendPhoneVerification?phoneNumber=${phoneNumber}`)
    setVerificationTime(180);
    setIsVerificationTimeOver(false);
    setIsSendVerificationNumber(true);
    let timeinterval = setInterval(() => {
      setVerificationTime(verificationTime => verificationTime - 1)
    }, 1000)
    setTimeIntervalNo(timeinterval);
    setToastMsg("인증번호가 전송되었습니다.");
    setToastType("notice")
  }

  const onVerifyPhoneNumber = async () => {
    if(verificationNumber === "") {
      setVerificationNumberError(true);
      return;
    }
    else {
      setVerificationNumberError(false);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/member/verifyPhoneVerification?phoneNumber=${phoneNumber}&code=${verificationNumber}`);
      if(response.data.result === "notVerified") {
        setVerificationNumberError(true);
      }
      else {
        setIsVerificated(true);
        setIsSendVerificationNumber(false);
        setToastMsg("휴대폰 인증이 완료되었습니다.");
        setToastType("notice");
        clearInterval(timeIntervalNo);
        setTimeIntervalNo(0);
      }
    }
  }
  const onClickFindEmailBtn = () => {
    if (!agree1 || !agree2 || !agree3 || !agree4) {
      setToastMsg("(필수) 약관에 모두 동의해주세요.");
      setToastType("error");
      return;
    }
    axios.get(`${process.env.REACT_APP_API_URL}/member/phone/exist?phone=${phoneNumber}`).then((res) => {
      if(res.data.code === 5001) {
        setFindedEmail(true);
        axios.get(`${process.env.REACT_APP_API_URL}/member/email/find?phone=${phoneNumber}`).then((res) => {
          if(res.data.email !== undefined) {
            setEmail(res.data.email);
          }
        }).catch((err) => {
          console.log(err);
        });
      }
      else {
        setModalPopup(true);
      }
    }).catch((err) => {
      console.log(err);
    });
  }
  const onCheckAllAgree = (e) => {
    setAllAgree(e.target.checked);
    setAgree1(e.target.checked);
    setAgree2(e.target.checked);
    setAgree3(e.target.checked);
    setAgree4(e.target.checked);
  }
  const onChangeAgree = (e, index) => {
    if(index === 1) setAgree1(e.target.checked);
    if(index === 2) setAgree2(e.target.checked);
    if(index === 3) setAgree3(e.target.checked);
    if(index === 4) setAgree4(e.target.checked);
  }
  useEffect(() => {
    setAllAgree(agree1 && agree2 && agree3 && agree4);
  }, [agree1, agree2, agree3, agree4]);
  const timeFormat = (time) => {
    const minute = Math.floor(time / 60);
    const second = time % 60;
    return `${minute}:${second < 10 ? "0"+second : second}`;
  }
  const onBlurPhoneNumber = () => {
    if (!phoneNumber.match(/^01[0-9]{8,9}$/)) {
      setPhoneNumberError(true);
    }
    else {
      setPhoneNumberError(false);
    }
  }
  useEffect(() => {
    if(email !== "") {
      const emailSplit = email.split("@");
      const maskingEmail = emailSplit[0].substr(0, 3) + "****@" + emailSplit[1];
      setMaskingEmail(maskingEmail);
    }
  }, [email]);
  return (
    <div className="find-password-view-wrap">
      <HeaderComponent/>
      <div className="password-find-view">
        <div className="step-identitiy-verification">
          <h1>본인확인을 위해<br/>인증을 진행해 주세요</h1>
          <div className="name-input-area">
            <p>가입자명</p>
            {nameError ? <p className="name-error">가입자 명을 입력해주세요.</p> : ""}
            <input type="text" placeholder="이름 입력" disabled={isVerificated} className={nameError ? "error" : ""}
                   value={memberName} onChange={(e) => setMemberName(e.target.value)}/>
          </div>
          <div className="phone-verification-area">
            <p>휴대폰번호 입력</p>
            <div className="row">
              <div
                className={"phone-number-input " + (isVerificated ? "disabled " : " ") + (phoneNumberError ? "error " : " ")}>
                <input type="text" placeholder="숫자만 입력" disabled={isVerificated} value={phoneNumber}
                       onChange={(e) => setPhoneNumber(e.target.value)} onBlur={onBlurPhoneNumber}/>
              </div>
              <button disabled={phoneNumberError}
                      disabled={(isSendVerificationNumber && !isVerificationTimeOver) || isVerificated}
                      onClick={onClickSendVerificationNumber}>{isVerificationTimeOver ? "재전송" : "인증번호전송"}</button>
            </div>
            <div className="row">
              <div
                className={"verification-number-input " + (isVerificated ? "disabled " : " ") + (verificationNumberError || isVerificationTimeOver ? "error " : " ")}>
                <input type="text" placeholder="인증번호 입력" disabled={isVerificated} value={verificationNumber}
                       onChange={(e) => setVerificationNumber(e.target.value)}/>
                {isSendVerificationNumber ?
                  <span className="verification-time">{timeFormat(verificationTime)}</span> : ""}
              </div>
              <button disabled={!isSendVerificationNumber || isVerificationTimeOver}
                      onClick={onVerifyPhoneNumber}>인증번호확인
              </button>
            </div>
            {verificationNumberError ? <p className="error-msg">인증번호를 확인해주세요</p> : ""}
            {phoneNumberError ? <p className="error-msg">휴대폰 번호를 올바르게 입력해주세요.</p> : ""}
            {isVerificationTimeOver ? <p className="error-msg">인증번호 유효시간이 지났습니다.</p> : ""}
          </div>
          <div className="terms-area">
            <div className="row all-agree">
              <input type="checkbox" id="all-agree" className="agree-check-box" onChange={onCheckAllAgree} checked={allAgree}/>
              <label htmlFor="all-agree" className="check-box-btn-label"
                     style={{backgroundImage: `url(${check})`}}></label>
              <label htmlFor="all-agree" className="check-box-content-label">본인 인증 서비스 약관 전체동의</label>
            </div>
            <div className="row">
              <input type="checkbox" id="agree-1" className="agree-check-box" checked={agree1} onChange={(e) => onChangeAgree(e, 1)}/>
              <label htmlFor="agree-1" className="check-box-btn-label" style={{backgroundImage: `url(${check})`}}></label>
              <label htmlFor="agree-1" className="check-box-content-label">휴대폰 본인 인출 서비스 이용약관 동의(필수)</label>
              <a href="#">전문</a>
            </div>
            <div className="row">
              <input type="checkbox" id="agree-2" className="agree-check-box" checked={agree2} onChange={(e) => onChangeAgree(e, 2)}/>
              <label htmlFor="agree-2" className="check-box-btn-label" style={{backgroundImage: `url(${check})`}}></label>
              <label htmlFor="agree-2" className="check-box-content-label">휴대폰 통신사 이용약관 동의(필수)</label>
              <a href="#">전문</a>
            </div>
            <div className="row">
              <input type="checkbox" id="agree-3" className="agree-check-box" checked={agree3} onChange={(e) => onChangeAgree(e, 3)}/>
              <label htmlFor="agree-3" className="check-box-btn-label" style={{backgroundImage: `url(${check})`}}></label>
              <label htmlFor="agree-3" className="check-box-content-label">개인정보 제공 및 이용동의(필수)</label>
              <a href="#">전문</a>
            </div>
            <div className="row">
              <input type="checkbox" id="agree-4" className="agree-check-box" checked={agree4} onChange={(e) => onChangeAgree(e, 4)}/>
              <label htmlFor="agree-4" className="check-box-btn-label" style={{backgroundImage: `url(${check})`}}></label>
              <label htmlFor="agree-4" className="check-box-content-label">고유식별정보 처리(필수)</label>
              <a href="#">전문</a>
            </div>
          </div>
        </div>
        <div className="next-btn-area">
          <button disabled={!isVerificated} onClick={onClickFindEmailBtn}>비밀번호 찾기</button>
        </div>
      </div>
      {toastMsg?<ToastMessageComponent closeToast={() => setToastMsg(null)} message={toastMsg} time={3000} type={toastType}/>:""}
      {modalPopup?<ModalPopupComponent
        title="가입하지 않은 사용자입니다"
        contents={["가입하지 않은 사용자입니다", "회원가입 후 이용해주세요"]}
        links={[{title: "회원가입하기", link: "/join"}]}
        onClickClose={() => {
          setModalPopup(false)
        }}
      />:""
      }
      {findedEmail?<div className="find-password-finished-wrap">
        <img src={mail_icon} alt="mail_icon"/>
        <h1>{maskingEmail}로<br/>임시 비밀번호를 발송하였습니다</h1>
        <p>임시 비밀번호를 발송하였습니다<br/>로그인 후 비밀번호를 변경해주세요</p>
        <Link to="/login" className="find-password-finished-login">로그인하기</Link>
        <Link to="/" className="find-password-finished-home">메인페이지</Link>
      </div>:""}
    </div>
  );
}
export default FindPasswordView;