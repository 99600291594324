import HeaderComponent from "../components/HeaderComponent";
import "../styles/MemberJoinView.sass";
import {useEffect, useState} from "react";
import axios from "axios";
import check from "../images/check.png";
import sait_main_logo from "../images/sait-main-logo.png";
import {Link} from "react-router-dom";
import ToastMessageComponent from "../components/ToastMessageComponent";
import ModalPopupComponent from "../components/ModalPopupComponent";

const MemberSNSJoinView = () => {
  const [step, setStep] = useState(1);
  const [memberName, setMemberName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationNumber, setVerificationNumber] = useState("");

  const [nameError, setNameError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [verificationNumberError, setVerificationNumberError] = useState(false);

  const [verificationTime, setVerificationTime] = useState(180);

  const [toastMsg, setToastMsg] = useState(null);
  const [toastType, setToastType] = useState("error");

  const [isSendVerificationNumber, setIsSendVerificationNumber] = useState(false);
  const [timeIntervalNo, setTimeIntervalNo] = useState(0);
  const [isVerificationTimeOver, setIsVerificationTimeOver] = useState(false);
  const [isVerificated, setIsVerificated] = useState(false);

  const [allAgree, setAllAgree] = useState(false);
  const [agree1, setAgree1] = useState(false);
  const [agree2, setAgree2] = useState(false);
  const [agree3, setAgree3] = useState(false);
  const [agree4, setAgree4] = useState(false);

  const [modalPopup, setModalPopup] = useState(false);
  const [socialInfo, setSocialInfo] = useState(null);

  useEffect(() => {
    const socialInfo = sessionStorage.getItem("socialInfo");
    if(socialInfo === null || socialInfo === undefined) {
      setModalPopup(true);
      return;
    }
    setSocialInfo(JSON.parse(socialInfo));
  }, []);

  const onCloseToast = () => {
    setToastMsg(null);
  }

  const onBlurPhoneNumber = () => {
    if (!phoneNumber.match(/^01[0-9]{8,9}$/)) {
      setPhoneNumberError(true);
    }
    else {
      setPhoneNumberError(false);
    }
  }

  const onVerifyPhoneNumber = async () => {
    if(verificationNumber === "") {
      setVerificationNumberError(true);
      return;
    }
    else {
      setVerificationNumberError(false);
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/member/verifyPhoneVerification?phoneNumber=${phoneNumber}&code=${verificationNumber}`);
      if(response.data.result === "notVerified") {
        setVerificationNumberError(true);
      }
      else {
        setIsVerificated(true);
        setIsSendVerificationNumber(false);
        setToastMsg("휴대폰 인증이 완료되었습니다.");
        setToastType("notice");
        clearInterval(timeIntervalNo);
        setTimeIntervalNo(0);
      }
    }
  }

  const onClickSendVerificationNumber = async () => {
    if(memberName === "" || memberName.trim() === "") {
      setNameError(true);
      return;
    }
    else {
      setNameError(false);
    }
    if (!phoneNumber.match(/^01[0-9]{8,9}$/)) {
      setPhoneNumberError(true);
      return;
    }
    if(isSendVerificationNumber && !isVerificationTimeOver) return;

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/member/sendPhoneVerification?phoneNumber=${phoneNumber}`)
    setVerificationTime(180);
    setIsVerificationTimeOver(false);
    setIsSendVerificationNumber(true);
    let timeinterval = setInterval(() => {
      setVerificationTime(verificationTime => verificationTime - 1)
    }, 1000)
    setTimeIntervalNo(timeinterval);
    setToastMsg("인증번호가 전송되었습니다.");
    setToastType("notice")
  }

  const timeFormat = (time) => {
    const minute = Math.floor(time / 60);
    const second = time % 60;
    return `${minute}:${second < 10 ? "0"+second : second}`;
  }

  const onCheckAllAgree = (e) => {
    setAllAgree(e.target.checked);
    setAgree1(e.target.checked);
    setAgree2(e.target.checked);
    setAgree3(e.target.checked);
    setAgree4(e.target.checked);
  }
  const onChangeAgree = (e, index) => {
    if(index === 1) setAgree1(e.target.checked);
    if(index === 2) setAgree2(e.target.checked);
    if(index === 3) setAgree3(e.target.checked);
    if(index === 4) setAgree4(e.target.checked);
  }
  useEffect(() => {
    setAllAgree(agree1 && agree2 && agree3 && agree4);
  }, [agree1, agree2, agree3, agree4]);

  const onClickJoinBtn = async () => {
    if(!allAgree) {
      setToastMsg("(필수) 약관에 모두 동의해주세요.");
      setToastType("error");
      return;
    }
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/member/socialMemberJoin`, {
      name: memberName,
      phone: phoneNumber,
      accountType: socialInfo.socialType,
      socialType: socialInfo.socialType,
      socialToken: socialInfo.socialToken
    });
    if(response.data.memberSeq === undefined) {
      setToastMsg("회원가입에 실패했습니다.");
      setToastType("error");
      return;
    }
    else {
      setStep(2);
      sessionStorage.removeItem("socialInfo");
    }
  }

  return (
    <div className="member-join-view-wrap">
      <HeaderComponent/>
      {step === 1?<div className="step-identitiy-verification">
        <h1>본인확인을 위해<br/>인증을 진행해 주세요</h1>
        <div className="name-input-area">
          <p>가입자명</p>
          {nameError ? <p className="name-error">가입자 명을 입력해주세요.</p> : ""}
          <input type="text" placeholder="이름 입력" disabled={isVerificated} className={nameError ? "error" : ""}
                 value={memberName} onChange={(e) => setMemberName(e.target.value)}/>
        </div>
        <div className="phone-verification-area">
          <p>휴대폰번호 입력</p>
          <div className="row">
            <div
              className={"phone-number-input " + (isVerificated ? "disabled " : " ") + (phoneNumberError ? "error " : " ")}>
              <input type="text" placeholder="숫자만 입력" disabled={isVerificated} value={phoneNumber}
                     onChange={(e) => setPhoneNumber(e.target.value)} onBlur={onBlurPhoneNumber}/>
            </div>
            <button disabled={phoneNumberError}
                    disabled={(isSendVerificationNumber && !isVerificationTimeOver) || isVerificated}
                    onClick={onClickSendVerificationNumber}>{isVerificationTimeOver ? "재전송" : "인증번호전송"}</button>
          </div>
          <div className="row">
            <div
              className={"verification-number-input " + (isVerificated ? "disabled " : " ") + (verificationNumberError || isVerificationTimeOver ? "error " : " ")}>
              <input type="text" placeholder="인증번호 입력" disabled={isVerificated} value={verificationNumber}
                     onChange={(e) => setVerificationNumber(e.target.value)}/>
              {isSendVerificationNumber ?
                <span className="verification-time">{timeFormat(verificationTime)}</span> : ""}
            </div>
            <button disabled={!isSendVerificationNumber || isVerificationTimeOver}
                    onClick={onVerifyPhoneNumber}>인증번호확인
            </button>
          </div>
        </div>
        {verificationNumberError ? <p className="error-msg">인증번호를 확인해주세요</p> : ""}
        {phoneNumberError ? <p className="error-msg">휴대폰 번호를 올바르게 입력해주세요.</p> : ""}
        {isVerificationTimeOver ? <p className="error-msg">인증번호 유효시간이 지났습니다.</p> : ""}
        <div className="terms-area">
          <div className="row all-agree">
            <input type="checkbox" id="all-agree" className="agree-check-box" onChange={onCheckAllAgree}
                   checked={allAgree}/>
            <label htmlFor="all-agree" className="check-box-btn-label"
                   style={{backgroundImage: `url(${check})`}}></label>
            <label htmlFor="all-agree" className="check-box-content-label">본인 인증 서비스 약관 전체동의</label>
          </div>
          <div className="row">
            <input type="checkbox" id="agree-1" className="agree-check-box" checked={agree1}
                   onChange={(e) => onChangeAgree(e, 1)}/>
            <label htmlFor="agree-1" className="check-box-btn-label" style={{backgroundImage: `url(${check})`}}></label>
            <label htmlFor="agree-1" className="check-box-content-label">휴대폰 본인 인출 서비스 이용약관 동의(필수)</label>
            <a href="#">전문</a>
          </div>
          <div className="row">
            <input type="checkbox" id="agree-2" className="agree-check-box" checked={agree2}
                   onChange={(e) => onChangeAgree(e, 2)}/>
            <label htmlFor="agree-2" className="check-box-btn-label" style={{backgroundImage: `url(${check})`}}></label>
            <label htmlFor="agree-2" className="check-box-content-label">휴대폰 통신사 이용약관 동의(필수)</label>
            <a href="#">전문</a>
          </div>
          <div className="row">
            <input type="checkbox" id="agree-3" className="agree-check-box" checked={agree3}
                   onChange={(e) => onChangeAgree(e, 3)}/>
            <label htmlFor="agree-3" className="check-box-btn-label" style={{backgroundImage: `url(${check})`}}></label>
            <label htmlFor="agree-3" className="check-box-content-label">개인정보 제공 및 이용동의(필수)</label>
            <a href="#">전문</a>
          </div>
          <div className="row">
            <input type="checkbox" id="agree-4" className="agree-check-box" checked={agree4}
                   onChange={(e) => onChangeAgree(e, 4)}/>
            <label htmlFor="agree-4" className="check-box-btn-label" style={{backgroundImage: `url(${check})`}}></label>
            <label htmlFor="agree-4" className="check-box-content-label">고유식별정보 처리(필수)</label>
            <a href="#">전문</a>
          </div>
          <div className="join-btn-area">
            <button onClick={onClickJoinBtn}>회원가입 완료</button>
          </div>
        </div>
      </div>:""}
      {step === 2 ? <div className="step-complete">
        <img src={sait_main_logo} alt="sait_main_logo"/>
        <h1>회원가입이 완료되었습니다.</h1>
        <p>SAIT 회원가입이 완료되었습니다</p>
        <p>로그인 후 서비스를 이용해주세요</p>
        <div className="link-area">
          <Link to="/login" className="step-complete-link">로그인하기</Link>
          <Link to="/find/email" className="step-complete-link">이메일 찾기</Link>
        </div>
      </div> : ""}
      {toastMsg !== null ?
        <ToastMessageComponent closeToast={onCloseToast} message={toastMsg} time={3000} type={toastType}/> : ""}
      {modalPopup ? <ModalPopupComponent
        title="이미 가입한 내역이 있습니다"
        contents={["이미 가입한 내역이 있습니다.", "로그인 후 사용해주세요."]}
        links={[{title: "로그인하기", link: "/login"}]}
        onClickClose={() => {
          setModalPopup(false)
        }}
      /> : ""
      }
    </div>
  );
}
export default MemberSNSJoinView;