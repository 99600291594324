import {useEffect, useState} from "react";
import axios from "axios";
import ModalPopupComponent from "../components/ModalPopupComponent";
import {useNavigate} from "react-router-dom";
import { useDispatch } from 'react-redux';
import { userAccessTokenSet, userInfoSet } from "../redux/actions/userInfo";

const KakaoAuthView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getToken();
  }, []);
  const [modalPopup, setModalPopup] = useState(false);

  const getToken = async () => {
    const code = new URL(window.location.href).searchParams.get("code");
    let res = null;
    try {
      res = await axios.post("https://kauth.kakao.com/oauth/token", {
          grant_type: "authorization_code",
          client_id: process.env.REACT_APP_KAKAO_API_KEY,
          redirect_uri: process.env.REACT_APP_KAKAO_REDIRECT_URL,
          code: code
        },
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded;charset=utf-8"
          }
        });
    }
    catch(e) {
      console.log(e);
      return;
    }
    localStorage.setItem("kakao_token", res.data.access_token);
    getUserData(res.data.access_token)

  }

  const getUserData = async (token) => {
    const user = await axios.get(`https://kapi.kakao.com/v2/user/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
      }
    });
    const loginData = {
      socialToken: user.data.id,
      socialType: "kakao"
    }
    sessionStorage.setItem("socialInfo", JSON.stringify(loginData));
    const result = await axios.post(`${process.env.REACT_APP_API_URL}/member/login/social`, loginData);
    if(result.data.result !== "success") {
      sessionStorage.setItem("socialInfo", JSON.stringify(loginData));
      setModalPopup(true);
    }
    else {
      try{
      console.log("result after social login => ", result);
        dispatch(userAccessTokenSet(result.data.accessToken));
        dispatch(userInfoSet(result.data.loginMember))
      sessionStorage.removeItem("socialInfo");
        const wishlistResponse = await axios.get(`${process.env.REACT_APP_API_URL}/wishlist/product/list/seq`, {
        headers : {
          'Authorization': `Bearer ${result.data.accessToken}`
        }
      });
      const moduleWishlistSeqs = await axios.get(`${process.env.REACT_APP_API_URL}/wishlist/module/list/seq`, {
        headers : {
          'Authorization' : `Bearer ${result.data.accessToken}`
        }
      });
        localStorage.setItem('product_wishlist', JSON.stringify([...new Set(wishlistResponse.data)]));
        localStorage.setItem('module_wishlist', JSON.stringify(moduleWishlistSeqs.data));
        navigate('/');
      }
      catch (err) {
        alert('위시리스트 데이터를 불러오는 도중 에러가 발생했습니다.')
        console.error(err)
      }
      navigate('/');
    }
  }

  return (
    <div>
      {modalPopup?<ModalPopupComponent
        title="가입하지 않은 사용자입니다"
        contents={["가입하지 않은 사용자입니다", "회원가입 후 이용해주세요"]}
        links={[{title: "회원가입하기", link: "/sns-join"}]}
        onClickClose={() => {
          setModalPopup(false);
          navigate('/login');
        }}
      />:""
      }
    </div>
  );
}
export default KakaoAuthView;