import {
  USER_ACCESS_TOKEN_SET,
  USER_INFO_SET,
} from "../actions/userInfo";

const initialState = {
  accessToken: "",
  userType: ""
}

export const userInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_ACCESS_TOKEN_SET:
      return {
        ...state,
        accessToken: action.payload
      }
    case USER_INFO_SET:
      return {
        ...state,
        userInfo: action.payload
      }
    default:
      return state;
  }
}