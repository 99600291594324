import sait_module_loading from "../../images/sait-module-loading.png";
import "../../styles/module/ModuleGuideFinish.sass";
const ModuleGuideFinish = ({onStartModuleAssemble}) => {
  return (
    <div className="guide-finish-area-wrap">
      <div className="guide-finish-area">
        <div className="guide-finish">
          <img src={sait_module_loading} alt="sait_module_loading"/>
          <h1>준비 완료!<br/>모듈을 조합하여<br/>나만의 가구를 만들어보세요</h1>
          <p>당신의 생활 패턴에 맞는<br/>모듈형 가구를 만들어보세요</p>
        </div>
      </div>
      <button className="finish-guide" onClick={onStartModuleAssemble}>가구 만들기 시작</button>
    </div>
  );
}
export default ModuleGuideFinish;