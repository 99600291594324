import "./styles/reset.css";
import "./styles/App.sass";
import {Provider} from "react-redux";
import {store, persistor} from "./store";
import ViewContainer from "./views/ViewContainer";
import {PersistGate} from "redux-persist/integration/react";

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={null}>
        <ViewContainer/>
      </PersistGate>
    </Provider>
  );
}

export default App;
