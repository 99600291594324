import HeaderComponent from "../components/HeaderComponent";
import "../styles/MemberWishlistView.sass"
import {useEffect, useState} from "react";
import axios from "axios";
import wishlist_active from "../images/wishlist-active.png";
import wishlist_deactive from "../images/wishlist-deactive-outline.png";
import {productListSetSelectedProductSeq} from "../redux/actions/productList";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

import { useSelector } from "react-redux";

const MemberWishlistView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('product');
  const [productWishList, setProductWishList] = useState([]);
  const [moduleWishList, setModuleWishList] = useState([]);

  const accessToken = useSelector((state) => state.userInfo.accessToken);
  const loginInfo = useSelector((state) => state.userInfo.userInfo);

  useEffect(() => {
    if (loginInfo === null || loginInfo === undefined || loginInfo === '') return;
    const loginUser = loginInfo;
    if(activeTab === 'product') {
      axios.get(process.env.REACT_APP_API_URL + '/wishlist/product/list?memberSeq='+loginUser.memberSeq,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        }
      ).then((response) => {
        response.data.forEach((item) => {
          item.wishlist = true
        })
        setProductWishList(response.data);
      }).catch((err) => {
        console.log(err);
      })
    }
    if(activeTab === 'module') {
      axios.get(process.env.REACT_APP_API_URL + '/wishlist/module/list?memberSeq='+loginUser.memberSeq,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`
          }
        }
      ).then((response) => {
        response.data.forEach((item) => {
          item.wishlist = true
        })
        setModuleWishList(response.data);
      }).catch((err) => {
        console.log(err);
      })
    }
  }, [activeTab])

  const onClickProductWishlistBtn = (productSeq) => {
    if (loginInfo === null || loginInfo === undefined || loginInfo === '') return;
    const loginUser = loginInfo;
    setProductWishList(productWishList.map((item) => {
      if(item.productSeq === productSeq) {
        item.wishlist = !item.wishlist;
        const storedWishlist = JSON.parse(localStorage.getItem('product_wishlist')) || [];
        if(item.wishlist) {
          localStorage.setItem('product_wishlist', JSON.stringify([...storedWishlist, productSeq.toString()]));
          axios.post(process.env.REACT_APP_API_URL + '/wishlist/product', {
            memberSeq: loginUser.memberSeq,
            productSeq: productSeq
          },
            {
              headers: {
                'Authorization': `Bearer ${accessToken}`
              }
            }
            ).then((response) => {
              axios.get(process.env.REACT_APP_API_URL + '/wishlist/product/list?memberSeq=' + loginUser.memberSeq,
                {
                  headers: {
                    'Authorization': `Bearer ${accessToken}`
                  }
                }
              ).then((response) => {
                response.data.forEach((item) => {
                  item.wishlist = true
                })
                setProductWishList(response.data);
              }).catch((err) => {
                console.log(err);
              })
          }).catch((err) => {
            console.log(err);
          })
        } else {
          const updatedWishlist = storedWishlist.filter(seq => seq !== productSeq.toString());
          localStorage.setItem('product_wishlist', JSON.stringify(updatedWishlist));
          axios.delete(`${process.env.REACT_APP_API_URL}/wishlist/product`, {
            data: {
              memberSeq: loginUser.memberSeq,
              productSeq: productSeq,
            },
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }).then((response) => {
            axios.get(process.env.REACT_APP_API_URL + '/wishlist/product/list?memberSeq=' + loginUser.memberSeq,
              {
                headers: {
                  'Authorization': `Bearer ${accessToken}`
                }
              }
            ).then((response) => {
              response.data.forEach((item) => {
                item.wishlist = true
              })
              setProductWishList(response.data);
            }).catch((err) => {
              console.log(err);
            })
          }).catch((err) => {
            console.log(err);
          })
        }
      }
      return item;
    }))
  }

  const onClickProductItem = (productSeq) => {
    dispatch(productListSetSelectedProductSeq(productSeq));
    navigate('/product/detail');
  }
  const onModuleClick = (module) => {
  }

  const onClickModuleWishListBtn = async (moduleSeq) => {
    const loginUser = loginInfo;
    if(loginUser === null || loginUser === undefined || loginUser === '') return;
    const memberSeq = loginUser.memberSeq;
    setModuleWishList(moduleWishList.map((module) => {
      if(module.moduleSeq === moduleSeq) {
        module.wishlist = !module.wishlist;
        if(module.wishlist){
          localStorage.setItem('module_wishlist', JSON.stringify([...JSON.parse(localStorage.getItem('module_wishlist')), moduleSeq]));
          axios.post(process.env.REACT_APP_API_URL + '/wishlist/module', {
            memberSeq: memberSeq,
            moduleSeq: moduleSeq
          },
            {
              headers: {
            'Authorization': `Bearer ${accessToken}`
            }
          }).then((response) => {
            axios.get(process.env.REACT_APP_API_URL + '/wishlist/module/list?memberSeq=' + loginUser.memberSeq,
              {
                headers: {
                  'Authorization': `Bearer ${accessToken}`
                }
              }
            ).then((response) => {
              response.data.forEach((item) => {
                item.wishlist = true
              })
              setModuleWishList(response.data);
            }).catch((err) => {
              console.log(err);
            })
          }).catch((error) => {
            console.log(error);
          })
        }else {
          localStorage.setItem('module_wishlist', JSON.stringify(JSON.parse(localStorage.getItem('module_wishlist')).filter(seq => seq !== moduleSeq)));
          axios.delete(process.env.REACT_APP_API_URL + '/wishlist/module', {
            data: {
              memberSeq: memberSeq,
              moduleSeq: moduleSeq
            },
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          }).then((response) => {
            axios.get(process.env.REACT_APP_API_URL + '/wishlist/module/list?memberSeq=' + loginUser.memberSeq,
              {
                headers: {
                  'Authorization': `Bearer ${accessToken}`
                }
              }
            ).then((response) => {
              response.data.forEach((item) => {
                item.wishlist = true
              })
              setModuleWishList(response.data);
            }).catch((err) => {
              console.log(err);
            })
          }).catch((error) => {
            console.log(error);
          })
        }
      }
      return module;
    }))
   }

  return (
    <div className="wishlist-view-wrap">
      <HeaderComponent />
      <h1 className="wishlist-title">위시리스트</h1>
      <div className="wishlist-tab">
        <div className={"wishlist-tab-item "+(activeTab === "product"?"active":"")} onClick={() => setActiveTab("product")}>상품 위시리스트</div>
        <div className={"wishlist-tab-item "+(activeTab === "product"?"":"active")} onClick={() => setActiveTab("module")}>모듈 위시리스트</div>
      </div>
      {
        activeTab === 'product'?
        <div className="product-wishlist">
          {
            productWishList.map((item, index) => {
              return (
                <div className="product-wishlist-item" key={index}>
                  <div className="product-wishlist-item-img" onClick={() => onClickProductItem(item.productSeq)} style={{backgroundImage:`url('${process.env.REACT_APP_API_URL}/image/product/${item.thumbnail}')`}}>
                  </div>
                  <div className="product-wishlist-item-info">
                    <p className="product-furnitrue-type">{item.furnitureTypeName}</p>
                    <h2 className="product-name" onClick={() => onClickProductItem(item.productSeq)}>{item.name}</h2>
                    <p className="product-size" onClick={() => onClickProductItem(item.productSeq)}>{item.width}x{item.height}x{item.depth}cm</p>
                    <div className="product-changeable-list" onClick={() => onClickProductItem(item.productSeq)}>
                      {item.sizeChangeable?<div className="product-changeable-item size-changeable">사이즈</div>:""}
                      {item.colorChangeable?<div className="product-changeable-item color-changeable">컬러</div>:""}
                      {item.textureChangeable?<div className="product-changeable-item texture-changeable">재질</div>:""}
                    </div>
                    <p className="product-price" onClick={() => onClickProductItem(item.productSeq)}>
                      <span>{item.price.toLocaleString()}</span>
                      <span>원</span>
                    </p>
                    <div className="product-review-cnt">
                      <span>리뷰</span>
                      <span>{item.reviewCnt}</span>
                    </div>
                    <div className="wishlist-btn" onClick={() => onClickProductWishlistBtn(item.productSeq)}>
                      {
                        item.wishlist?
                        <img src={wishlist_active} alt="wishlist_active" width={24}/>:
                        <img src={wishlist_deactive} alt="wishlist_deactive" width={24}/>
                      }
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div> :
          <div className="module-wishlist">
          {
            moduleWishList.map((module, index) => {
              return (
                <div className="module-wishlist-item " key={index}>
                  <div className="module-wishlist-name" onClick={() => onModuleClick(module)}>
                    <h2>{module.title}</h2>
                    <p>{module.choiceCount}번 선택</p>
                  </div>
                  <div className="module-wishlist-image" onClick={() => onModuleClick(module)}>
                    <img src={`${process.env.REACT_APP_API_URL}/image/module/${module.imageFile}`} alt="module"/>
                  </div>
                  <div className="module-wishlist-price" onClick={() => onModuleClick(module)}>￦{module.price.toLocaleString()}</div>
                  <div className="wishlist-btn" onClick={() => onClickModuleWishListBtn(module.moduleSeq)}>
                    {module.wishlist?<img src={wishlist_active} alt="wishlist"/>:
                      <img src={wishlist_deactive} alt="wishlist"/>}
                  </div>
                </div>
              )
            })
          }
        </div>
      }
    </div>
  );
}
export default MemberWishlistView;