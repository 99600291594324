import chevron from "../images/chevron-right.svg"
import locker from "../images/locker.svg"
import "../styles/PasswordChangeComponent.sass"
import {useState} from "react";
import axios from 'axios';
import {Link} from "react-router-dom";
import { useSelector } from "react-redux";

const PasswordChangeComponent = ({open, onBack}) => {
  const [currentPasswordError, setCurrentPasswordError] = useState(false);
  const [currentPasswordNotMatch, setCurrentPasswordNotMatch] = useState(false);

  const [newPasswordError, setNewPasswordError] = useState(false);
  const [newPasswordValidError, setNewPasswordValidError] = useState(false);
  const [newPasswordWhitespaceError, setNewPasswordWhitespaceError] = useState(false)
  const [newPasswordConfirmNotMatchError, setNewPasswordConfirmNotMatchError] = useState(false);

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");

  const [passwordChangeFinished, setPasswordChangeFinished] = useState(false);

  const accessToken = useSelector((state) => state.userInfo.accessToken);
  const loginInfo = useSelector((state) => state.userInfo.userInfo);

  const onBlurCurrentPassword = () => {
    if(currentPassword === undefined || currentPassword === null || currentPassword === '') {
      setCurrentPasswordError(true);
    } else {
      setCurrentPasswordError(false);
    }
  }
  const onBlurNewPassword = () => {
    // 비밀번호 공백 체크 정규식
    const whitespaceReg = /^\s+|\s+$/g;
    // 비밀번호 유효성 체크 정규식
    const validReg = /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/;
    if(newPassword === undefined || newPassword === null || newPassword === '') {
      setNewPasswordError(true);
      return;
    } else {
      setNewPasswordError(false);
    }
    if(whitespaceReg.test(newPassword)) {
      setNewPasswordWhitespaceError(true);
      return;
    } else {
      setNewPasswordWhitespaceError(false);
    }
    if(!validReg.test(newPassword)) {
      setNewPasswordValidError(true);
    } else {
      setNewPasswordValidError(false);
    }
  }

  const onBlurNewPasswordConfirm = () => {
    if(newPassword !== newPasswordConfirm) {
      setNewPasswordConfirmNotMatchError(true);
    } else {
      setNewPasswordConfirmNotMatchError(false);
    }
  }


  const onClickChangePassword = () => {
    const data = {}
    data.memberSeq = loginInfo.memberSeq;
    data.currentPassword = currentPassword;
    data.newPassword = newPassword;
    axios.patch(`${process.env.REACT_APP_API_URL}/member/update/password`, data, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    }).then((res) => {
      if(res.data.code !== null && res.data.code !== undefined) {
        if(res.data.code === 5006) {
          setCurrentPasswordNotMatch(true);
        } else {
          setCurrentPasswordNotMatch(false);
        }
      }
      else {
        setPasswordChangeFinished(true);
        localStorage.removeItem("loginUser");
      }
    }).catch((err) => {
      console.log(err);
    });
  }

  return (
    <div className={"password-change-view-wrap " + (open ? "open" : "")}>
        <div className="password-change-view-header">
          <img src={chevron} alt="chevron" onClick={onBack}/>
          <span>비밀번호 재설정</span>
        </div>
        <div className="password-change-view">
          <h1>새로운 비밀번호를<br/>입력해주세요</h1>
          <div className="password-change-view-row">
            <p className="pwd-change-view-row-title">기존 비밀번호</p>
            <input type="password" placeholder="비밀번호 입력"
                   className={currentPasswordError || currentPasswordNotMatch ? "error" : ""}
                   value={currentPassword}
                   onChange={(e) => setCurrentPassword(e.target.value)}
                   onBlur={onBlurCurrentPassword}
            />
            {currentPasswordError ? <p className="err-msg">기존 비밀번호를 입력해주세요</p> : ""}
            {currentPasswordNotMatch ? <p className="err-msg">기존 비밀번호가 다릅니다</p> : ""}
          </div>
          <div className="password-change-view-row">
            <p className="pwd-change-view-row-title">새로운 비밀번호</p>
            <input type="password" placeholder="비밀번호 입력"
                   className={newPasswordError || newPasswordWhitespaceError || newPasswordValidError ? "error" : ""}
                   value={newPassword}
                   onChange={(e) => {
                     setNewPassword(e.target.value)
                   }}
                   onBlur={onBlurNewPassword}
            />
            {newPasswordError ? <p className="err-msg">새로운 비밀번호를 입력해주세요</p> : ""}
            {newPasswordWhitespaceError ? <p className="err-msg">올바른 비밀번호를 입력해주세요</p> : ""}
            {newPasswordValidError ? <p className="err-msg">비밀번호는 숫자 영문 조합 8자리 이상입니다</p> : ""}
          </div>
          <div className="password-change-view-row">
            <p className="pwd-change-view-row-title">비밀번호 확인</p>
            <input type="password" placeholder="비밀번호 입력"
                   className={newPasswordConfirmNotMatchError ? "error" : ""}
                   value={newPasswordConfirm}
                   onChange={(e) => {
                     setNewPasswordConfirm(e.target.value)
                   }}
                   onBlur={onBlurNewPasswordConfirm}
            />
            {newPasswordConfirmNotMatchError ? <p className="err-msg">비밀번호가 일치하지 않습니다</p> : ""}
          </div>
          <button className="password-change-btn" onClick={onClickChangePassword}>비밀번호 변경</button>
        </div>
        <div className={"password-change-finished-wrap "+(passwordChangeFinished?"show":"")}>
          <div className="password-change-finished">
            <img src={locker} alt="locker"/>
            <h1>고객님의 비밀번호가<br/>변경되었습니다</h1>
            <p>고객님의 비밀번호가 변경되었습니다<br/>변경된 비밀번호로 로그인해주세요</p>
            <Link to="/login" className="password-change-finished-login">로그인하기</Link>
            <Link to="/" className="password-change-finished-home">메인페이지</Link>
          </div>
        </div>
      </div>
  );
}
export default PasswordChangeComponent;