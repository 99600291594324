import * as THREE from "three";
import { create } from "zustand";
import { useShallow } from "zustand/react/shallow";

const useDragStore = create((set) => ({
  draw: false,
  available: false,
  enabled: false,
  position: new THREE.Vector3(),
  rotation: new THREE.Euler(),
  opacity: 0.5,
  setDragState: (state) => set(state),
  setDragAvailable: (b) => set({ available: b }),
  setDragEnabled: (b) => set({ enabled: b }),
  setDragPosition: (vector3) => set({ position: vector3 }),
}));

export function useDragState() {
  return useDragStore(
    useShallow((state) => ({
      available: state.available,
      enabled: state.enabled,
      position: state.position,
      opacity: state.opacity,
      setDragState: state.setDragState,
      setDragAvailable: state.setDragAvailable,
      setDragEnabled: state.setDragEnabled,
      setDragPosition: state.setDragPosition,
    }))
  );
}
