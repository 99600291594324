import "../../styles/module/FurnitureTypeSelectComponent.sass"
import {useEffect, useState} from "react";
import axios from "axios";
import arrow_dark from "../../images/arrow-dark.png";

const FurnitureTypeSelectComponent = ({onSelectFurnitureType}) => {
  const [furnitureTypeList, setFurnitureTypeList] = useState([]);
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/custom/types`).then((res) => {
      setFurnitureTypeList(res.data);
    }).catch((err) => {
      console.log(err);
    });
  }, []);
  return (
    <div className="furniture-type-select-comp-wrap">
      <h2>맞춤작업을 진행할<br/><b>가구 종류를 선택</b>해주세요.</h2>
      <div className="furniture-type-select-list">
      {
          furnitureTypeList.map((furnitureType) => {
            return (
              <div
                className={"furniture-type "+(furnitureType.available?"":"disabled")}
                key={furnitureType.customFurnitureTypeSeq}
                style={{backgroundImage:`url('${process.env.REACT_APP_API_URL}/image/module/${furnitureType.thumbnail}')`}}
                onClick={() => onSelectFurnitureType(furnitureType)}
              >
                <div className="cover"></div>
                <h2>{furnitureType.typeNameKor}</h2>
                <p>{furnitureType.typeNameEng}</p>
                {
                  furnitureType.available?
                    <div className="create-furniture">
                      <span>가구 만들기</span><img src={arrow_dark} alt="arrow-dark" width={28}/>
                    </div>:<div className="create-furniture">
                      <span>COMMING SOON</span>
                    </div>
                }
              </div>
            )
          })
        }
      </div>
    </div>
  );
}
export default FurnitureTypeSelectComponent;